
import { useEffect, useState } from 'react';
import '../.././App.css';

import axios from 'axios';
import AddFactor from './AddFactor';
import Notification from '../../components/Notification';

function TwoFactor({setActivePage}) {
    setActivePage("2FA");

    const [isAddFactorOpen, setIsAddFactorOpen] = useState(false);
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openNotify, setOpenNotify] = useState(false);
    const [editNotify, setEditNotify] = useState(false);

    useEffect(() => {

        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userData"))?._id}`
                );
                const userMeta = response?.data?.result?.userMeta;
                let twoFactorAuthData = userMeta?.twoFactorAuth || { status: "disabled", method: "", data: "" };

                twoFactorAuthData = {
                    ...twoFactorAuthData,
                    email: response?.data?.result?.user?.email || ''
                }
                setStatus(twoFactorAuthData);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, [isAddFactorOpen]);

    const disabledTwoFactor = async() =>{
        setIsLoading(true);
        const updateData = {
            twoFactorAuth: {
                status: "disabled",
                method: "",
                data: ""
            }
        }
        await axios.post(
            `${process.env.REACT_APP_API_URL}/user/${JSON.parse(localStorage.getItem("userData"))?._id}`,
            updateData
        );
        setStatus(prevState => ({
            ...prevState,
            status: 'disabled'
        }));

        setIsLoading(false);
    }

    return (
        <div className="general twoFactor">
            <div className="twoFactorContent">
                <div className="twoFactorRow">
                    <div className="billingText">
                        <p className='settingsMenuBlockTitle'>Enable 2FA</p>
                        <p className='twoFactorTopLeftDesc'>To enhance the security of your account, we strongly recommend setting up two-factor authentication (2FA).</p>
                    </div>
                    <div className="factorRowCheckbox">
                        <button className="addFactor_btn secondary-button button" onClick={(e) => { setIsAddFactorOpen(!isAddFactorOpen) }}>
                            { (status?.status === "enable") ? "Change 2FA" : "Add 2FA" }                              
                        </button>
                        {(status?.status === "enable")  && 
                            <button className="removeFactor_btn primary-button button" onClick={(e) => { disabledTwoFactor() }}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                    :
                                    "Disable 2FA"
                                }
                            </button>
                        }
                    </div>
                </div>
            </div>
            {isAddFactorOpen &&
                <AddFactor setIsAddFactorOpen={setIsAddFactorOpen} isAddFactorOpen={isAddFactorOpen} status={status} setStatus={setStatus} setOpenNotify={setOpenNotify} setEditNotify={setEditNotify}/>
            }
            {openNotify && 
                <Notification openNotify={openNotify} setOpenNotify={setOpenNotify} type={'success'} title={`2FA has been successfully added!`} text={`Your account is now more secure with two-factor authentication.`}/>
            }
            {editNotify && 
                <Notification openNotify={editNotify} setOpenNotify={setEditNotify} type={'success'} title={`2FA has been successfully edited!`} text={`Your two-factor authentication settings have been successfully updated.`}/>
            }
            
        </div>
    );
}

export default TwoFactor;
