import { useState, useEffect } from 'react';

import drag from '../../images/drag.svg'
import down from '../../images/icondropdown.svg'

function NavItemEditable(props) {
  const [isOpen, setisOpen] = useState(false);
  const [title, setTitle] = useState(props.name);
  const [url, setUrl] = useState(props.data.url_address || '');


  useEffect(() => {
    setTitle(props.name);
    setUrl(props.data.url_address || '');
  }, [props.name, props.data.url_address]);


  return (

    <div className="navItem" draggable>
      <div className="navEditableVisible" onClick={(e) => setisOpen(!isOpen)} >
        <div>
          <img src={drag} alt="Drag" />
          <p>{title}</p>
        </div>
        <img
          className={isOpen === true ? "downRotated" : ""}
          src={down}
          alt="Down"
        />
      </div>

      {isOpen === true ? (
        <div className="customLinkRow">
          <label className='fieldsLabel'>Title</label>
          <div className="navLinkRow">
            <input
              type="text"
              placeholder={props.name}
              value={title}
              className='inputFields'
              autocomplete="off"
              onChange={(e) => {
                setTitle(e.target.value);
                props.updateTitle(props.data.id, e.target.value)
                props.data.name = e.target.value;
              }}
            />
          </div>
          {props.data.type === "customLink" &&
            <>
              <p>Url</p>
              <div className="navLinkRow">
                <input
                  type="text"
                  placeholder={props.data.url_address !== '' ? props.data.url_address : 'Enter URL'}
                  value={url}
                  className='inputFields'
                  autocomplete="off"
                  onChange={(e) => {
                    setUrl(e.target.value);
                    props.updateUrl(props.data.id, e.target.value);
                    props.data.url = e.target.value;
                  }}
                />
              </div>

            </>}

          <div className='remove'>
            <button className='remove-navitem' onClick={() => props.removeNavItem(props.data)}>
              <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8333 5.00002V4.33335C11.8333 3.39993 11.8333 2.93322 11.6517 2.5767C11.4919 2.2631 11.2369 2.00813 10.9233 1.84834C10.5668 1.66669 10.1001 1.66669 9.16667 1.66669H7.83333C6.89991 1.66669 6.4332 1.66669 6.07668 1.84834C5.76308 2.00813 5.50811 2.2631 5.34832 2.5767C5.16667 2.93322 5.16667 3.39993 5.16667 4.33335V5.00002M6.83333 9.58335V13.75M10.1667 9.58335V13.75M1 5.00002H16M14.3333 5.00002V14.3334C14.3333 15.7335 14.3333 16.4336 14.0608 16.9683C13.8212 17.4387 13.4387 17.8212 12.9683 18.0609C12.4335 18.3334 11.7335 18.3334 10.3333 18.3334H6.66667C5.26654 18.3334 4.56647 18.3334 4.03169 18.0609C3.56129 17.8212 3.17883 17.4387 2.93915 16.9683C2.66667 16.4336 2.66667 15.7335 2.66667 14.3334V5.00002" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Delete 
            </button>
          </div>

        </div>
      ) : (
        ""
      )}
    </div>

  );
}

export default NavItemEditable;
