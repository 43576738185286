import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useParams } from "react-router";

const Password = (props) => {
    const { id } = useParams();

    props.setActivePage("Password");
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [box, setBox] = useState(false);
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setBox(e.target.value.length > 0);
    };
    const onConfirmPasswordChange = e => {
        setRePassword(e.target.value);
    };

    const [errors, setErrors] = useState({
        password: "",
        rePassword: ""
    });

    const validateInputs = () => {
        let valid = true;
        const newErrors = {};
        
        if (password.length == 0) {
            newErrors.password = "The field is not filled";
            valid = false;
        }

        if (rePassword.length == 0) {
            newErrors.rePassword = "The field is not filled";
            valid = false;
        }

        if(password.length > 0 && rePassword.length > 0 ) {
            if( password !== rePassword ) {
                newErrors.rePassword = "Passwords do not match";
                valid = false;
            } else {
                if( ! validatePassword(password) ) {
                    newErrors.rePassword = "Password doesn't meet criteria";
                    valid = false;
                } else {
                    newErrors.rePassword = '';
                }
            }
        }
        setErrors(newErrors);
        return valid;
    };
    const validatePassword = (password) => {
        // Regular expression to enforce the password requirements
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };
    const handleChangePassword = async () => {
        try {
            setIsLoading(true);

            // Validate inputs before making the API call
            const inputsValid = validateInputs();
            if (!inputsValid) {
                setIsLoading(false);
                return false;
            }

            const passwordData = { 
                newPassword: password,
                confirmPassword: rePassword,
                changeFor: "other",
            }
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/changepassword/${id}/`,    
                passwordData,
            );
            if(response?.data?.message === "Password successfully changed."){
                setBox(false);
                setPassword('');
                setRePassword('');
            }
            setIsLoading(false);
                
        } catch (error) {
            setIsLoading(false);
            console.log(error);
            return false;
        }
    }

    const isUppercase = /[A-Z]/.test(password);
    const isLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
    const isMinLength = password.length >= 8;

   

    const fulfilledRequirementsCount = [
        isUppercase,
        isLowercase,
        hasSpecialChar,
        hasNumber,
    ].filter(Boolean).length;
    useEffect(() => {
        if (password.length > 0) {
            if (password.length >= 8 && (hasNumber && hasSpecialChar && isLowercase && isUppercase)) {
                setBox(false);
            } else {
                setBox(true);
            }
        }
        if (password.length <= 0) {
            setBox(false)
        }
    }, [password, isUppercase, isLowercase, hasNumber, hasSpecialChar]);

    return (
        <>
            <div className="generalContent">
                <div className="generalRow">
                    <div className="formControltems">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields">
                                <label className="fieldsLabel">New password</label>
                                <input
                                    type="password"
                                    placeholder="Enter your new password"
                                    value={password}
                                    className={
                                        validatePassword === true
                                          ? "inputFields inputError"
                                          : "inputFields"
                                      }
                                    onChange={handlePasswordChange} />
                                    {errors.password && (
                                        <div className="validateError">
                                            {errors.password}
                                        </div>
                                    )}
                            </div>
                            {box && (
                                <div className="requirements-box">
                                    <p>Your password must contain:</p>
                                    <ul className="requirement-list">
                                        <li className={isMinLength ? "fulfilled" : ""}>
                                            <p>At least 8 characters</p>
                                        </li>
                                        <li
                                            className={
                                                fulfilledRequirementsCount >= 4 ? "fulfilled" : ""
                                            }
                                        >
                                            <p>And the Following:</p>
                                            <ul>
                                                <li className={isLowercase ? "fulfilled" : ""}>
                                                    <p>Lower case letters (a - z)</p>
                                                </li>
                                                <li className={isUppercase ? "fulfilled" : ""}>
                                                    <p>Upper case letters (A - Z)</p>
                                                </li>
                                                <li className={hasNumber ? "fulfilled" : ""}>
                                                    <p>Numbers (0 - 9)</p>
                                                </li>
                                                <li className={hasSpecialChar ? "fulfilled" : ""}>
                                                    <p>Special characters (e.g. !@#$%^&*)</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="formControlItems">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Confirm new password</label>
                                <input
                                    type="password"
                                    placeholder="Re-enter your new password"
                                    value={rePassword}
                                    className="inputFields"
                                    onChange={onConfirmPasswordChange} />
                            </div>
                            {errors.rePassword && (
                                <div className="validateError">
                                    {errors.rePassword}
                                </div>
                            )}
                        </div>
                    </div>

                </div>

                <button
                    onClick={handleChangePassword}
                    className="form-btn save-change primary-button button" type="button">
                    {isLoading ? (
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                    ) : (
                        "Save Changes"
                    )}
                </button>
            </div>
        </>
    );
};
export default Password;
