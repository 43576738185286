import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import close from "../../images/close.svg"


function DeletePageModal(props) {
    const { item, isOpen, onClose, refrence, selectedItems, setOpen, type, redirect, fetchData, originalData, setOpenNotify, setMultiDeteleNotify, setSelectedItems, setSelectedPageLenght } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    const [userPassword, setUserPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [errorMassage, setErrorMassage] = useState('');

    const history = useNavigate();

    const deletePage = async (itemIDs) => {
        try {
            setError(false);
            setValidatePassword(false);

            if (userPassword.length > 0) {
                setIsLoading(true);

                const updatePromises = itemIDs?.map(async (itemID) => {

                    const updateAPI = `${process.env.REACT_APP_API_URL}/pages/updatepage/${itemID}?admin=true`;

                    const response = await axios.post(
                        updateAPI,
                        {
                            status: "Deleted",
                            member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                            password: userPassword,
                            selectedItems:selectedItems?.length
                        });
                        if (response?.status === 200) {
                            if(selectedItems && selectedItems.length > 1){
                                setMultiDeteleNotify(true);
                            } else{
                                setOpenNotify(true); 
                            }
                            setSelectedPageLenght(selectedItems.length)
                            onClose()
                        }
                        setSelectedItems([])
                })

                await Promise.all(updatePromises);
                await fetchData();

                document.body.classList.remove('hidden-message');

                { redirect && history(`/${redirect}`) }

                setOpen(false);
            } else {
                setValidatePassword(true)
            }

            
            
            setIsLoading(false);
            setUserPassword("");
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                setError(true);
                setErrorMassage(error.response.data.error);
            }
        }
    }

    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            deletePage(item && item.length != 0 ? [item._id] : selectedItems)
        }
    }

    // Function to get display name from _id
    const getDisplayNameById = (id) => {
        const data = originalData.find(user => user._id === id);
        return data ? `${data.title}` : "";
    };

    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className='popupModal' ref={refrence}>
                        <div className='popupTopClose'>
                            <img src={close} alt="close-icon" onClick={onClose}></img>
                        </div>
                        <h3 className='popupModalTitle'>Delete {selectedItems.length === 1 ? 'page' : 'pages'}</h3>
                        {item && item.length != 0 ? (
                            <p className='popupModalContent'>Are you sure you want to delete this page? This action cannot be undone.</p>
                        ) : (
                            <p className='popupModalContent'>Are you sure you want to delete these pages? This action cannot be undone.</p>

                        )}
                        <div className='popupFields password-field'>
                            <label className='fieldsLabel'>Enter password</label>
                            <input type="password" name='password' placeholder='Enter your password' autoComplete='off' onChange={(e) => setUserPassword(e.target.value)} readOnly={readOnly} onFocus={() => setReadOnly(false)} onBlur={() => setReadOnly(true)} onKeyPress={(e)=> handleKeyPress(e)}/>
                            {validatePassword === true && (
                                <p className="validateError">The field is not filled</p>
                            )}
                            {error === true && (
                                <p className="validateError">{errorMassage}</p>
                            )}
                        </div>
                        <div className="popupModalBtn">
                            <button className="cancel secondary-button button" type="button" onClick={onClose}>Cancel</button>
                            <button className="savePicture primary-button button" type="button" onClick={() => deletePage(item && item.length != 0 ? [item._id] : selectedItems)}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                    : "Delete"}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default DeletePageModal;