import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import close from "../../images/close.svg"
import selectDown from "../../images/select_down.svg";

function DeleteCategoryModel(props) {
    const { isOpen, onClose, categoryIds, updateCategoriesData, categoriesData, allCategoriesData, fetchData, fetchAllCategories, setMultiDeteleNotify, setDeleteNotify, setSelectCatLenght, setSelectedCatIds } = props;

    const modelTitle = ( categoryIds.length > 1) ? 'Delete Categories' : 'Delete Category';
    // const modelDesc = ( categoryIds.length > 1) ? 'Are you sure you want to delete these categories? This action cannot be undone.' : 'Are you sure you want to delete category? This action cannot be undone.';
    
    const modelDesc = (categoryIds.length > 1) ? 
    `Are you sure you want to delete these categories? This action cannot be undone.` : 
    `Are you sure you want to delete the category? This action cannot be undone.`;

    const modelRef = useRef(null);
    const catDropdownRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectCategory, setSelectCategory] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      const checkIfClickedOutside = (e) => {
        if (selectCategory && catDropdownRef.current && !catDropdownRef.current.contains(e.target)) {
          setSelectCategory(false);
        }

        if( modelRef.current && !modelRef.current.contains(e.target) ) {
            onClose('cancel')
        }
      };
  
      document.addEventListener("mousedown", checkIfClickedOutside);
  
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [selectCategory]);

    if (!isOpen) {
        return null;
    }

    const deleteCategory = async () => {
        setIsLoading(true);

        const member_id = JSON.parse(localStorage.getItem("userData"))?._id;

        if( selectedCategoryId != '' ) {
          const catData = await updateCategoriesData(categoryIds, selectedCategoryId, 'move');

          const newCategoryData = {
            newslatter_count: catData.newslatter_count,
            page_count: catData.page_count,
            publication_count: catData.publication_count,
            newslatter_ids: catData.newslatter_ids,
            page_ids: catData.page_ids,
            publication_ids: catData.publication_ids,
            member_id:member_id
          }
          await axios.post(
              `${process.env.REACT_APP_API_URL}/categories/updatecategory/${selectedCategoryId}`,
              newCategoryData,
          );
        }
        await Promise.all(           
            categoryIds?.map(async (categoryId) => {
              await axios({
                  method: 'delete',
                  url: `${process.env.REACT_APP_API_URL}/categories/remove/${categoryId}`,
                  data: {
                    member_id:member_id,
                    moveToCatId: selectedCategoryId
                  }
              });
          })
        );

        if(categoryIds.length <= 1){
          setDeleteNotify(true);
        }
        if(categoryIds.length > 1){
          setMultiDeteleNotify(true);
        }
        setSelectCatLenght(categoryIds.length)
        
        setSelectedCatIds([]);
        fetchData();
        fetchAllCategories();
        
        updateCategoriesData(categoryIds, '', 'delete');
        
        setIsLoading(false);
        onClose();
    }

    const handleSearch = (e) => {
        setSelectedCategory('')
        setSearchText(e.target.value);
    };

    const filteredCategories = allCategoriesData.filter((category) =>
        category.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            {isOpen && (
                <div className='popupModal' ref={modelRef}>
                    <div className='popupTopClose'>
                        <img src={close} alt="close-icon" onClick={onClose}></img>
                    </div>
                    <h3 className='popupModalTitle'>{modelTitle}</h3>
                    <p className='popupModalContent'>{modelDesc}</p>

                    { allCategoriesData.length > categoryIds.length && 
                        <div className='movetocat-wrapp'>
                            <label className='fieldsLabel'>Move to (Optional)</label>
                          <div className={`contentDrop`} ref={catDropdownRef}>
                            <div>
                              <div className="select planTypeSelect">
                                <div
                                  className="selectMain"
                                  onClick={(e) => { setSelectCategory(!selectCategory); }}
                                  // onFocus={(e) => { setSelectCategory(true); }}
                                >
                                  <p>{`${selectedCategory ? selectedCategory : "Select Category"}`}</p>
                                </div>
                                {selectCategory &&  (
                                  <div className="selectListOption selectPlanOption" >
                                    <div className="selectOption" key="000" onClick={(e) => { setSelectedCategory('Select Category'); setSelectCategory(false); }}>
                                      <p>Select Category</p>
                                    </div>
                                    {
                                      filteredCategories?.map((item) => {
                                        return (
                                          !categoryIds.includes(item._id) && <div
                                            className="selectOption"
                                            key={item._id}
                                            onClick={(e) => { setSelectedCategoryId(item._id); setSelectedCategory(item.name); setSelectCategory(false); }}
                                          >
                                            <p>{item.name}</p>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                    }

                    <div className="popupModalBtn">
                        <button className="cancel secondary-button button" type="button" onClick={() => { onClose('cancel'); }} >Cancel</button>
                        <button className="savePicture primary-button button" type="button" onClick={deleteCategory} >
                        {isLoading ?
                          <div className="settingPage-loader">
                              <div className="ring-loader"></div>
                          </div>
                        :
                        <>
                            Delete
                        </>}
                        </button>
                    </div>

                </div>
            )}
        </>
    )
}

export default DeleteCategoryModel