import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import axios from "axios";
import "../css/team.css";
import Select, { components } from "react-select";
import { timezoneData } from "./Component";
import ImageEditor from "../../components/ImageEditor/ImageEditor";
import Loader from "../../components/Loader/Loader";
import {dataURLtoFile} from '../../utils/functions'
import iconEmail from "../../images/icon-mail.svg"
import selectDown from "../../images/select_down.svg";
import 'react-phone-input-2/lib/style.css'
import avatar from "../../images/profile-user.svg";
import coverImg from "../../images/picture.svg";
import avatarProfile from "../../images/cover_image.svg";

import TheamLight from '../../images/White.png';
import TheamDark from '../../images/Dark.png';
import featured from "../../images/featured.svg";
import UploadMediaPopup from "../../../src/Pages/Users/UploadMediaPopup"
import Notification from "../../components/Notification";
const General = ({ setActivePage, userData, setUserData, setUpdateUser }) => {

    setActivePage("General");

    const { id } = useParams();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [profileUrl, setProfileUrl] = useState("");
    const [email, setEmail] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");

    const [countryValidate, setCountryValidate] = useState(false);

    const fileInputRef = useRef(null);
    const profileFileInputRef = useRef(null);
    const timeZoneRef = useRef();
    const [featureImage, setFeatureImage] = useState(null);
    const [saveFeatureImage, setSaveFeatureImage] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [oldZoom, setOldZoom] = useState(1);
    const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [newUploadedImage, setNewUploadedImage] = useState(null);
    const [featuredImageModal, setFeaturedImageModal] = useState(false);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [oldImage, setOldImage] = useState(null);
    const [image, setImage] = useState(null);
    const modalRef = useRef(null);

    const profileModalRef = useRef(null);
    const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
    const [profileIsDragging, setProfileIsDragging] = useState(false);
    const [saveProfileImage, setSaveProfileImage] = useState(null);
    const [profileCoverModal, setProfileCoverModal] = useState(false);
    const [loadingProfileImg, setloadingProfileImg] = useState(false);
    const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
    const [replcaeProfileImageZoom, setReplcaeProfileImageZoom] = useState(false);
    const [oldProfileZoom, setOldProfileZoom] = useState(1);
    const [profileZoom, setProfileZoom] = useState(1);
    const [oldProfileImage, setOldProfileImage] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [profilePosition, setProfilePosition] = useState({ x: 0.5, y: 0.5 });
    const [newProfileUploadedImage, setNewProfileUploadedImage] = useState(null);
    const [coverPictureDragging, setCoverPictureDragging] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [profileCropedImageId, setProfileCropedImageId] = useState('');
    const [profileImageId, setProfileImageId] = useState('');
    const [coverCropedImageId, setCoverCropedImageId] = useState('');
    const [coverImageId, setCoverImageId] = useState('');
    const [uploadedCoverImageFile, setUploadedCoverImageFile] = useState(null);
    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [selectedProfileImage, setSelectedProfileImage] = useState('');
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);
    const [selectedProfileImageData, setSelectedProfileImageData] = useState('');
    const [selectedCoverImageData, setSelectedCoverImageData] = useState('');
    const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
    const [theme, setTheme] = useState("dark");
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [uploadedProfileImageFile, setUploadedProfileImageFile] = useState(null);
    const [openNotify, setOpenNotify] = useState(false)
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        displayName: "",
        jobTitle: "",
        profileUrl: "",
        email: "",
        coverfile: "",
        countryCode: "",
        file: "",
        timezone: "",
    });

    const [activeTimezone, setActiveTimezone] = useState("");
    const [timeZoneTypeOpen, setTimeZonTypeOpen] = useState(false);
    useEffect(() => {
        setFirstName(userData?.firstName);
        setLastName(userData?.lastName);
        setDisplayName(userData?.displayName || '');
        setJobTitle(userData?.job_title || '');
        setEmail(userData?.email || '');
        setSelectedCountry(userData?.country || '');
        const userTimezone = userData?.timeZone || '';
        let formattedTimezone = userTimezone;
        if (formattedTimezone != '') {
            for (const region in timezoneData) {
                const foundZone = timezoneData[region].find(zone => zone.includes(userTimezone));
                if (foundZone) {
                    formattedTimezone = foundZone;
                    break;
                }
            }
        }
        setActiveTimezone(`${formattedTimezone}`);
        setProfileUrl(userData?.profileSlug || '');
    }, [userData]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (
            (!modalRef.current.contains(event.target) &&
              featuredImageModal === false) ||
            event.target.className === "overlayModal"
          ) {
            setFeaturedImageModal(false);
            setZoom(oldZoom);
            setOldImage(newUploadedImage);
          }
        };
    
        if (featuredImageModal) {
          window.addEventListener("click", handleOutsideClick);
        }
    
        return () => {
          window.removeEventListener("click", handleOutsideClick);
        };
      }, [featuredImageModal]);

      useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (
            timeZoneTypeOpen &&
            timeZoneRef.current &&
            !timeZoneRef.current.contains(e.target)
          ) {
            setTimeZonTypeOpen(false);
          }
        };
    
        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [timeZoneTypeOpen]);

      useEffect(() => {
        const handleOutsideClick = (event) => {
          if (
            (!profileModalRef.current.contains(event.target) &&
              profileCoverModal === false) ||
            event.target.className === "profileOverlayModal"
          ) {
            setProfileCoverModal(false);
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.remove("profile-uploaded");
            setProfileZoom(oldProfileZoom);
            setOldProfileImage(newProfileUploadedImage);
          }
        };
    
        if (profileCoverModal) {
          window.addEventListener("click", handleOutsideClick);
        }
    
        return () => {
          window.removeEventListener("click", handleOutsideClick);
        };
      }, [profileCoverModal]);

    const validateInputs = () => {
        let valid = true;
        const newErrors = {};

        if (!firstName?.trim()) {
            newErrors.firstName = "Please enter your first name";
            valid = false;
        }
        if (!lastName?.trim()) {
            newErrors.lastName = "Please enter your last name";
            valid = false;
        }
        if (!jobTitle?.trim()) {
            newErrors.jobTitle = "Please enter your job title";
            valid = false;
        }
        if (!displayName?.trim()) {
            newErrors.displayName = "Please enter your display name";
            valid = false;
        }
        if (!profileUrl?.trim()) {
            newErrors.profileUrl = "Please choose a profile URL";
            valid = false;
        } else {
            const restrictedUrls = ["contact","contactus", "home", "page", "about-us", "about", "aboutus", "product", "products", "profile-assistant", "profile", "dashboard", "admin", "pricing", "faq", "faqs", "contact-us", "terms", "privacy", "privacy-policy", "refund-policy", "refund", "cookies-policy", "cookies", "login", "register", "forget-password", "reset-password", "password", "pages", "search"];
            if (restrictedUrls?.includes(profileUrl?.trim().toLowerCase())) {
                newErrors.profileUrl = "This URL is reserved. Please choose a different profile URL.";
                valid = false;
            }
        }
        if (!email?.trim()) {
            newErrors.email = "Please enter your email";
            valid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex?.test(email)) {
                newErrors.email = "Please enter valid email";
                valid = false;
            }
        }
        if (!selectedCountry?.trim()) {
            newErrors.countryCode = "Please select a country or region";
            valid = false;
        }
        if (!activeTimezone?.trim()) {
            newErrors.timezone = "Please select a timezone";
            valid = false;
        }
        if (!saveFeatureImage?.trim()) {
            newErrors.coverfile = "Please upload a cover picture";
            valid = false;
        }
        if (!saveProfileImage?.trim()) {
            newErrors.file = "Please upload a profile picture";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };
    const [isLoading, setIsLoading] = useState(false);
    const toConvertUppercase = (string) => {
        const words = string.split(" ");
    
        const capitalizedWords = words.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedString = capitalizedWords.join(" ");
        return capitalizedString;
    };

    const handleFormSubmit = async () => {
        
        try {
            if (validateInputs()) {
                // Create a new user object

                setIsLoading(true);

            let profileCropedImage = profileCropedImageId;
            let profileImage = profileImageId;
            let coverCropedImage = coverCropedImageId;
            let coverImage = coverImageId;

            const featureImageData = await getFeatureImageData()

            profileCropedImage = featureImageData?.profileCropedImage || profileCropedImage;
            profileImage = featureImageData?.profileImage || profileImage;
            coverCropedImage = featureImageData?.coverCropedImage || coverCropedImage;
            coverImage = featureImageData?.coverImage || coverImage;
                const updateData = {
                    firstName: firstName,
                    lastName: lastName,
                    displayName: displayName,
                    job_title: jobTitle,
                    email: email,
                    profileSlug: profileUrl,
                    timeZone: activeTimezone,
                    country: selectedCountry,
                    role: 'customer',
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                    theme: theme,
                    profileMedia: {
                        selectedImage: profileImage,
                        cropedImage: profileCropedImage,
                        profilePosition: profilePosition,
                        profileZoom: profileZoom
                    },
                    coverMedia: {
                    selectedImage: coverImage,
                    cropedImage: coverCropedImage,
                    profilePosition: position,
                    profileZoom: zoom
                    }
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/updateuser/${id}`,
                    updateData
                );
                if (response.status === 200) {
                    setUpdateUser(true)
                    const updatedUserData = { ...userData, ...updateData };
                    setUserData(updatedUserData);
                    setOpenNotify(true);
                }
            }
            setIsLoading(false);
        } catch (error) {
            const newErrors = {};

            if (error.response?.data) {
                const errors = error.response.data.errors;

                errors.forEach((err) => {
                    if (err.emailError) {
                        newErrors.email = err.message;
                    }

                    if (err.profileError) {
                        newErrors.profileUrl = err.message;
                    }
                });
            }

            setErrors(newErrors);
            setIsLoading(false);
        }
    };

    const handleCountryChange = (countryCode) => {
        setSelectedCountry(countryCode);
    };
    const handleTimezoneChange = (selectedOption) => {
        setActiveTimezone(selectedOption.value);
    };

    const options = Object.entries(timezoneData).map((region) => ({
        label: region[0],
        options: region[1].map((timezone) => ({
            value: timezone,
            label: timezone,
        })),
    }));
    

    function handleDragOver(e) {
        e.preventDefault();
        if (!profileIsDragging) {
            setProfileIsDragging(true);
        }
    }
    const handleProfileDragLeave = (e) => {
        e.preventDefault();
        setProfileIsDragging(false);
    };

    const handleProfileClick = () => {
        setProfileCoverModal(!profileCoverModal);
    };

    const handleProfileImageLoad = () => {
        const img = new Image();
        img.src = image;
        img.onload = () => { };
    };

    const handlefeatureImageDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleClick = () => {
        setFeaturedImageModal(!featuredImageModal);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
    };

    const handleImageLoad = () => {
        const img = new Image();
        img.src = featureImage;
        img.onload = () => { };
    };

    const handleZoom = (zoom) => {
        setZoom(zoom);
    };

    const handlePosition = (newPosition) => {
        setPosition(newPosition);
    };

    const handleReplaceImg = () => {
        setShowUploadMediaPopup(true);
        setReplcaeImageZoom(true);
        setUploadMediaPopupFor('cover-picture');
        setProfileCoverModal(false);
        setFeaturedImageModal(false)
    };

    const handleMouseDown = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleMouseUp = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleTouchStart = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleTouchEnd = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleCancel = () => {
        if (!saveFeatureImage) {
            var divElement = document.getElementById("newpageImageUpload");
            featureImage && divElement.classList.remove("imageUploaded");
        } else {
            var divElement = document.getElementById("newpageImageUpload");
            featureImage && divElement.classList.add("imageUploaded");
        }
        setOldImage(newUploadedImage);
        setFeaturedImageModal(false);
        setZoom(oldZoom);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            setUploadedImage(null);
        }
    };

    const handleSavePicture = async () => {
        let click = document.getElementById("clickFunction");
        click.click();
        setFeatureImage(featureImage);
        setNewUploadedImage(oldImage);
        setSaveFeatureImage(featureImage);
        setFeaturedImageModal(false);
        setOldZoom(zoom);
    };

    const handleProfileZoom = (profileZoom) => {
        setProfileZoom(profileZoom);
    };

    const handleProfilePosition = (newProfilePosition) => {
        setProfilePosition(newProfilePosition);
    };

    const handleReplaceProfileImg = () => {
        setShowUploadMediaPopup(true);
        setUploadMediaPopupFor('profile-picture');
        setProfileCoverModal(false)
        setReplcaeProfileImageZoom(true);
    };

    const handleSaveProfilePicture = async () => {
        let click = document.getElementById("clickFunction");
        click.click();
        setImage(image);
        setNewProfileUploadedImage(oldProfileImage);
        setSaveProfileImage(image);
        setProfileCoverModal(false);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-uploaded");
        setOldProfileZoom(profileZoom);
    };

    const handleProfileCancel = () => {
        if (saveProfileImage !== null) {
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.add("profile-uploaded");
        } else {
            var divElement = document.getElementById("profile-picturebox");
            divElement.classList.remove("profile-uploaded");
        }
        setOldProfileImage(newProfileUploadedImage);
        setProfileCoverModal(false);
        setProfileZoom(oldProfileZoom);

        if (profileFileInputRef.current) {
            profileFileInputRef.current.value = null;
            setUploadedProfileImage(null);
        }
    };

    const handlefeatureImageDrop = (e) => {
        var divElement = document.getElementById("newpageImageUpload");

        divElement.style.height = divElement.clientHeight + "px";

        divElement.classList.remove("imageUploaded");
        setloadingFeatureImg(true);

        var TempReplcaeImageZoom = replcaeImageZoom;

        if (featureImage) {
            setReplcaeImageZoom(true);
            TempReplcaeImageZoom = true;
        }

        if (TempReplcaeImageZoom === true) {
            setOldZoom(1);
            setZoom(1);
        } else {
            setZoom(oldZoom);
        }
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                if (file) {
                    setUploadedImage(file);
                    TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
                    setOldImage(reader.result);
                }
            };
        };
        if (file) {
            setTimeout(() => {
                reader.readAsDataURL(file);
                setCoverPictureDragging(false);
                setUploadedImage(file);
                setloadingFeatureImg(false);
                var divElement = document.getElementById("newpageImageUpload");
                divElement.classList.add("imageUploaded");
                divElement.classList.add("imageUploaded-wrap");
                setFeaturedImageModal(true);
                setOldImage(file);
                setReplcaeImageZoom(false);
                divElement.style.height = "auto";
            }, 4000);
        } else {
            setloadingFeatureImg(false);
            divElement.style.height = "auto";
        }
        setIsDragging(false);
    };

    const handlefeatureImageFileUpload = (e) => {
        var divElement = document.getElementById("newpageImageUpload");
        divElement.style.height = divElement.clientHeight + "px";
        divElement.classList.remove("imageUploaded");
        setloadingFeatureImg(true);
        setDisableSaveButton(true);
        if (replcaeImageZoom === true) {
            setOldZoom(1);
            setZoom(1);
        } else {
            setZoom(oldZoom);
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                replcaeImageZoom !== true && setFeatureImage(reader.result);
                setOldImage(reader.result);
            };
        };
        if (file) {
            reader.readAsDataURL(file);
            setUploadedImage(null);

            setTimeout(() => {
                reader.readAsDataURL(file);
                setCoverPictureDragging(false);
                setloadingFeatureImg(false);
                setDisableSaveButton(false);
                setUploadedImage(file);
                var divElement = document.getElementById("newpageImageUpload");
                divElement.classList.add("imageUploaded");
                divElement.classList.add("imageUploaded-wrap");
                setFeaturedImageModal(true);
                setReplcaeImageZoom(false);
                divElement.style.height = "auto";
            }, 4000);
        } else {
            setloadingFeatureImg(false);
            setDisableSaveButton(false);
            divElement.style.height = "auto";
        }
    };

    function handleDrop(e) {
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.remove("profile-uploaded");
        setloadingProfileImg(true);
        setDisableSaveButton(true);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-border");
        var TempReplcaeProfileImageZoom = replcaeProfileImageZoom;

        if (image) {
            setReplcaeProfileImageZoom(true);

            TempReplcaeProfileImageZoom = true;
        }

        if (TempReplcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }

        if (TempReplcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }

        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            TempReplcaeProfileImageZoom !== true && setImage(reader.result);
            setOldProfileImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
            setUploadedProfileImage(null);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setloadingProfileImg(false);
                setDisableSaveButton(false);
                setUploadedProfileImage(file);
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.add("profile-uploaded");
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.remove("profile-border");
                setProfileCoverModal(true);
                setReplcaeProfileImageZoom(false);
            }, 4000);
        } else {
            setloadingProfileImg(false);
            setDisableSaveButton(false);
        }
        setProfileIsDragging(false);
    }

    function handleFileUpload(e) {
        setloadingProfileImg(true);
        setDisableSaveButton(true);
        var divElement = document.getElementById("profile-picturebox");
        divElement.classList.add("profile-border");
        if (replcaeProfileImageZoom === true) {
            setOldProfileZoom(1);
            setProfileZoom(1);
        } else {
            setProfileZoom(oldProfileZoom);
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            replcaeProfileImageZoom !== true && setImage(reader.result);
            setOldProfileImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            setUploadedProfileImage(null);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setloadingProfileImg(false);
                setDisableSaveButton(false);
                setUploadedProfileImage(file);
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.add("profile-uploaded");
                var divElement = document.getElementById("profile-picturebox");
                divElement.classList.remove("profile-border");
                setProfileCoverModal(true);
                setReplcaeProfileImageZoom(false);
            }, 4000);
        } else {
            setloadingProfileImg(false);
            setDisableSaveButton(false);
        }
    }
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={selectDown} alt="select-down" />
            </components.DropdownIndicator>
        );
    };
    useEffect(() => {
        if(selectedProfileImage !== '') {
          setBlobData();
        }
      }, [selectedProfileImage]);

    const setBlobData = async () => {
        setSelectMediaLoading(true);

        if (selectedProfileImage?.url) {
            try {
                const imageData = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                    selectedProfileImage,
                    { responseType: 'blob' }
                );
                const imageUrl = URL.createObjectURL(imageData?.data);

                if (uploadMediaPopupFor === 'profile-picture') {
                    // setOldProfileZoom(1);
                    setProfileZoom(1);
                    setOldProfileImage(imageUrl);
                    if (image === null) {
                        setImage(imageUrl);
                    }

                    setUploadedProfileImage(imageUrl)
                    setUploadedProfileImageFile(null)

                    setloadingProfileImg(false);
                    setProfileCoverModal(true);
                    setReplcaeProfileImageZoom(false);
                    setSelectedProfileImageData(selectedProfileImage);
                }

                if (uploadMediaPopupFor === 'cover-picture') {
                    // setOldZoom(1);
                    setZoom(1);

                    setUploadedImage(imageUrl);
                    setUploadedCoverImageFile(null);
                    setOldImage(imageUrl);

                    if (featureImage === null) {
                        setFeatureImage(imageUrl);
                    }

                    setloadingFeatureImg(false);
                    setFeaturedImageModal(true);
                    setReplcaeImageZoom(false);
                    setSelectedCoverImageData(selectedProfileImage);
                }


                setSelectMediaLoading(false);
                setShowUploadMediaPopup(false);
                setUploadMediaPopupFor('');
            } catch (error) {
                setSelectMediaLoading(false);
                console.log({ error: error, message: error.message })
            }
        }
        setSelectMediaLoading(false);
    }

    useEffect(() => {
        fetchData();
      }, []);
    
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/${id}`
          );
          const userData = response?.data?.result?.user;
          const userMeta = response?.data?.result?.userMeta;
          const userMediaData = response?.data?.result?.userMediaData;
    
          const firstName = toConvertUppercase(userData?.firstName);
          const lastName = toConvertUppercase(userData?.lastName);
    
          if (userData) {
            setFirstName(firstName);
            setLastName(lastName);
            setEmail(userData.email);
          }
    
          if (userMeta) {
            setJobTitle(userMeta.job_title);
            setDisplayName(userMeta.displayName);
            setProfileUrl(userMeta.profileSlug);
            setSelectedCountry(userMeta.country);        
            setTheme(userMeta?.theme || 'dark');
            const userTimezone = userMeta.timeZone;
            let formattedTimezone = userTimezone;
            for (const region in timezoneData) {
              const foundZone = timezoneData[region].find(zone => zone.includes(userTimezone));
              if (foundZone) {
                formattedTimezone = foundZone;
                break;
              }
            }
            setActiveTimezone(`${formattedTimezone}`);
    
            if(userMeta?.profileMedia?.cropedImage) {
    
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                userMediaData.profileMeida.cropedMedia,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
    
              // var imageData; 
              // const imageUrl = URL.createObjectURL(imageData?.data);
              
              setImage(imageUrl);
              setOldProfileImage(imageUrl);
              setProfileCropedImageId(userMeta?.profileMedia?.cropedImage)
            }
    
            if(userMeta?.profileMedia?.selectedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                userMediaData.profileMeida.selectedMedia,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
              // setImage(imageUrl);
              setOldProfileImage(imageUrl);
              setNewProfileUploadedImage(imageUrl);
              setSaveProfileImage(imageUrl);
              setProfileImageId(userMeta.profileMedia.selectedImage);
            }
    
            if(userMeta?.profileMedia?.profileZoom) {
              setProfileZoom(userMeta.profileMedia.profileZoom)
              setOldProfileZoom(userMeta.profileMedia.profileZoom)
            }
    
            if(userMeta?.profileMedia?.profilePosition) {
              setProfilePosition(userMeta.profileMedia.profilePosition)
            }
    
            if(userMeta?.coverMedia?.cropedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                userMediaData.coverMedia.cropedMedia,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
    
              setFeatureImage(imageUrl);
              setSaveFeatureImage(imageUrl);
              setCoverCropedImageId(userMeta.coverMedia.cropedImage);          
            }
    
            if(userMeta?.coverMedia?.selectedImage) {
              const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                userMediaData.coverMedia.selectedMedia,
                { responseType: 'blob' }
              );
    
              const imageUrl = URL.createObjectURL(imageData.data);
    
              setUploadedImage(imageUrl);
              setUploadedCoverImageFile(null);
              setNewUploadedImage(imageUrl);
              setOldImage(imageUrl);
    
              setCoverImageId(userMeta.coverMedia.selectedImage);
            }
    
            if(userMeta?.coverMedia?.profileZoom) {
              setZoom(userMeta.coverMedia.profileZoom)
              setOldZoom(userMeta.coverMedia.profileZoom)
            }
    
            if(userMeta?.coverMedia?.profilePosition) {
              setPosition(userMeta.coverMedia.profilePosition)
            }
    
          }
        } catch (error) {
          console.log(error, "error");
        }
      };
    const colourStyles = {
        option: (styles, { isFocused, isSelected, isDisabled }) => {
            return {
                ...styles,
                backgroundColor: isSelected
                    ? "#007AFF"
                    : isFocused
                        ? "#f9f9f9"
                        : "transparent",
                fontSize: "14px",
                cursor: "pointer",
            };
        },
        input: (provided, state) => ({
            ...provided,
            height: "40px",
            padding: 0,
            margin: 0,
            borderRadius: "8px",
            fontSize: "14px",
            cursor: "pointer",
        }),
        noOptionsMessage: (provided, state) => ({
            ...provided,
            fontSize: "14px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "14px",
            color: "#101828",
        }),
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "8px",
            width: "100%",
        }),
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none !important",
            borderRadius: "8px",
            marginRTop: "2px",
        }),
        container: (provided, state) => ({
            ...provided,
            paddingLeft: "0px",
        }),
        placeholder: (styles, { isFocused }) => {
            return {
                ...styles,
                marginBottom: isFocused ? "0px" : "0px",
                fontSize: "14px",
                marginLeft: "0",
                color: "#667085",
            };
        },
        indicatorSeparator: (state) => ({
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            borderRadius: "8px",
            outline: "none",
            marginRight: "5px",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            paddingLeft: "15px",
            paddingRight: "15px",
        }),
    };
    
    const getFeatureImageData = async () => {
        let profileCropedImage = profileCropedImageId;
        let profileImage = profileImageId;
        let coverCropedImage = coverCropedImageId;
        let coverImage = coverImageId;
    
        if (uploadedProfileImageFile !== null) {
          const imageFormData = new FormData();
              imageFormData.append('files', uploadedProfileImageFile);
    
          try {
            const res = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
              imageFormData, {
              headers: {
              'Content-Type': 'multipart/form-data'
              }
            });
    
            profileImage = res.data.result[0]._id;
    
            const profileImageFile = await dataURLtoFile(image, "profile-picture.jpg");
            const formData = new FormData();
            formData.append('files', profileImageFile);
    
            const uploadedMedia = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
              formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }
            );
    
            profileCropedImage = uploadedMedia?.data?.result[0]?._id || profileCropedImage;
    
          } catch (error) {
                  console.log(error);
              }
        } else if ((profileImageId !== '' || selectedProfileImageData?._id !== "") && oldProfileImage !== saveProfileImage) {
          const profileImageFile = await dataURLtoFile(image, "profile-picture.jpg");
    
          const formData = new FormData();
          formData.append('files', profileImageFile);
    
          const uploadedMedia = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
            formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
          );
    
          profileCropedImage = uploadedMedia?.data?.result[0]?._id || profileCropedImage;
          profileImage = selectedProfileImageData?._id || profileImage;
        }
    
        if (uploadedCoverImageFile !== null) {
          const imageFormData = new FormData();
              imageFormData.append('files', uploadedCoverImageFile); // Append each file individually
    
          try {
    
            const res = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
              imageFormData, {
              headers: {
              'Content-Type': 'multipart/form-data'
              }
            });
            coverCropedImage = res.data.result[0]._id;
    
            const coverImageFile = await dataURLtoFile(featureImage, "cover-picture.jpg");
    
            const formData = new FormData();
            formData.append('files', coverImageFile);
    
            const uploadedMedia = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
              formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }
            );
    
            coverCropedImage = uploadedMedia?.data?.result[0]?._id || coverCropedImage;
    
          } catch (error) {
            console.log(error);
            }
        } else if((coverCropedImageId !== '' || selectedCoverImageData?._id) && oldImage !== saveFeatureImage ) {
          const coverImageFile = await dataURLtoFile(featureImage, "cover-picture.jpg");
    
            const formData = new FormData();
            formData.append('files', coverImageFile);
    
            const uploadedMedia = await axios.post(
              `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
              formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              }
            );
    
            coverCropedImage = uploadedMedia?.data?.result[0]?._id || coverCropedImage;
            coverImage = selectedCoverImageData?._id || coverImage;
        }
        
        const result = { profileCropedImage, profileImage, coverCropedImage, coverImage }
    
            return result;
    }
    return (
        <>
            <div className="generalContent">
                <div className="generalRow">
                    <div className="formControltems">
                        <div className="formControlInputs">
                            <div className="formControlFields">
                                <label className="fieldsLabel">First name</label>
                                <input
                                    type="text"
                                    value={firstName}
                                    className="inputFields"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    autocomplete="off"
                                />
                                {errors.firstName && (
                                    <div className="error-message">
                                        {errors.firstName}
                                    </div>
                                )}
                            </div>
                            <div className="formControlFields">
                                <label className="fieldsLabel">Last name</label>
                                <input
                                    type="text"
                                    value={lastName}
                                    className="inputFields"
                                    onChange={(e) => setLastName(e.target.value)}
                                    autocomplete="off"
                                />
                                {errors.lastName && (
                                    <div className="error-message">
                                        {errors.lastName}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="formControltems">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Display name</label>
                                <input
                                    type="text"
                                    value={displayName}
                                    className="inputFields"
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    autocomplete="off"
                                />
                                {errors.displayName && (
                                    <div className="error-message">
                                        {errors.displayName}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="formControltems">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Job title</label>
                                <input
                                    type="text"
                                    value={jobTitle}
                                    className="inputFields"
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    autocomplete="off"
                                />
                                {errors.jobTitle && (
                                    <div className="error-message">
                                        {errors.jobTitle}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="formControltems">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields profileUrlFields">
                                <label className="fieldsLabel">Profile URL</label>
                                <div className="inputFieldIcon">
                                    <div className="urlFields">
                                        x.com/
                                    </div>
                                    <input
                                        type="text"
                                        className="inputFields"
                                        value={profileUrl}
                                        onChange={(e) => setProfileUrl(e.target.value)}
                                        autocomplete="off"
                                    />
                                </div>
                                {errors.profileUrl && (
                                    <div className="error-message">
                                        {errors.profileUrl}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="formControltems">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Email</label>
                                <div className="inputFieldsIcon">
                                    <img src={iconEmail} />
                                    <input
                                        type="text"
                                        className="inputFields"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autocomplete="off"
                                    />
                                    {errors.email && (
                                        <div className="error-message">
                                            {errors.email}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formControltems generalform-row">
                        <div className="formControlInputs formControlFull">
                            <div className="formControlFields">
                                <label className="fieldsLabel">Country or Region</label>
                                <ReactFlagsSelect
                                    selected={selectedCountry}
                                    searchable
                                    searchPlaceholder="Search countries"
                                    onSelect={(code) => handleCountryChange(code)}
                                    className="select-country"
                                />
                                {errors.countryCode && (
                                    <div className="error-message">
                                        {errors.countryCode}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="formControlInputs formControlFull" ref={timeZoneRef}>
                            <div className="formControlFields">
                                <label className="fieldsLabel">Timezone</label>
                                <div className="newPageRightRow">
                                    <div className={`contentDrop`}>
                                        <div>
                                            <div className="select planTypeSelect timeZoneSelect">
                                                <div className="selectMain" onClick={(e) => { setTimeZonTypeOpen(!timeZoneTypeOpen) }}>
                                                <p>{activeTimezone}</p>
                                                </div>
                                                {timeZoneTypeOpen === true && (
                                                <div className="selectListOption">
                                                    {
                                                    options?.map((item, index) => {
                                                        return (
                                                        <>
                                                            <p className="timeZoneLabel">{item?.label.toUpperCase()}</p>
                                                            {item?.options?.map((optionItem, subIndex) => {
                                                            return (
                                                                <div
                                                                className="selectOption"
                                                                key={`option-${index}`}
                                                                onClick={() => { setActiveTimezone(optionItem?.value); setTimeZonTypeOpen(false) }}
                                                                >
                                                                <div key={`subOption-${index}-${subIndex}`}>{optionItem?.label}</div>
                                                                </div>
                                                            )
                                                            })}
                                                        </>
                                                        )
                                                    })
                                                    }
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.timezone && (
                                    <div className="error-message">
                                        {errors.timezone}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="generalRow rowChangeImage">
                    <div className="generalRowDesc">
                        <p className="rowTitle">Profile picture</p>
                        <p className="rowdiscription">Click on image for change profile picture.</p>
                    </div>
                    <div className="generalBlockItems">
                        <div className="avatarBlock" onDragOver={handleDragOver} onDragLeave={handleProfileDragLeave} onDrop={handleDrop} >
                            <div className="avatarUpload">
                            {saveProfileImage ? (
                                <div id="profile-picturebox" className="registerProfile-block" onClick={handleProfileClick} >
                                {loadingProfileImg === true && profileCoverModal === false ? (
                                    <div id="profileImg-loader" className="registerProfileImg-loader" >
                                    <Loader />
                                    </div>
                                ) : (
                                    <>
                                    <div onClick={handleProfileClick}>
                                        <img src={image} className="newpage_ProfileImg" alt="Uploaded" onLoad={handleProfileImageLoad} />
                                    </div>
                                    </>
                                )}
                                </div>
                            ) : (
                                <div id="profile-picturebox" className="registerProfile-block" onClick={() => {setShowUploadMediaPopup(true); setUploadMediaPopupFor('profile-picture');}} >
                                {loadingProfileImg === true && profileCoverModal === false ? (
                                    <div id="profileImg-loader" className="registerProfileImg-loader" >
                                    <Loader />
                                    </div>
                                ) : (
                                    <>
                                    <img className="avatarUpload-img" src={avatar} alt="avatar-Images" />
                                    </>
                                )}
                                </div>
                            )}
                            <input type="file" ref={profileFileInputRef} id="upload-img" onChange={handleFileUpload} accept="image/*" hidden />
                            </div>
                            <div
                            className="newPageRightRow"
                            onClick={() => {
                                if (saveProfileImage) {
                                handleProfileClick();
                                } else {
                                setShowUploadMediaPopup(true);
                                setUploadMediaPopupFor('profile-picture');
                                }
                            }}
                            >
                            <div className="fileUploadProfile-box">
                                <p className="newPageImageUploadText">
                                <span className="newPageImageText">Profile picture</span> <br />
                                Min 195x195px, PNG or JPEG <br />
                                <span className="btnUpload button secondary-button">
                                    {saveProfileImage ? 'Replace' : 'Upload'}
                                </span>
                                </p>
                            </div>
                            </div>
                        </div>
                        {errors.file && (
                            <div className="error-message">
                                {errors.file}
                            </div>
                        )}
                    </div>
                </div>

                <div className="generalRow rowChangeCover">
                    <div className="generalRowDesc">
                        <p className="coverimgTitle">Cover picture</p>
                        <p className="coverimgDes">Click on image for change cover picture.</p>
                    </div>

                    <div className="generalBlockItems">
                        <div className="newPageRightRow" onDragOver={handlefeatureImageDragOver} onDragLeave={handleDragLeave} onDrop={handlefeatureImageDrop}>
                            {saveFeatureImage ? (
                                <div id="newpageImageUpload" className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} onClick={handleClick} >
                                    {loadingFeatureImg === true && featuredImageModal === false ? (
                                        <div className="featureImg-loader">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div className="cover_image">
                                            <div onClick={handleClick}>
                                                <img className="newpage_CoverImg" src={featureImage} onLoad={handleImageLoad} alt="Featured-Images" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div
                                    id="newpageImageUpload"
                                    className={
                                        isDragging
                                            ? "draging newpageImageUpload generalImageAdmin"
                                            : "newpageImageUpload generalImageAdmin"
                                    }
                                    onClick={() => { setShowUploadMediaPopup(true); setUploadMediaPopupFor('cover-picture'); setUploadMediaPopupTab('upload'); }}
                                >
                                    {loadingFeatureImg === true && featuredImageModal === false ? (
                                        // {(loadingFeatureImg === true && replaceImgTimeLoading === false) ? (
                                        <div className="featureImg-loader">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div className="featureImgMiddleContent">
                                            <img className="fileUploadCover_Img" src={featured} alt="Featured-Images" />
                                            <p className="newPageImageUploadText">
                                                <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('cover-picture'); setUploadMediaPopupTab('media'); }}>Open Media Library</span>
                                                <br />
                                                JPG, PNG (max. 128MB)
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                id="feature-img"
                                onChange={handlefeatureImageFileUpload}
                                accept="image/*"
                                hidden
                            />
                            {errors.coverfile && (
                                <div className="error-message">
                                    {errors.coverfile}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="themeRow">
                    <div className="generalRowDesc">
                        <p className="rowTitle">Theme style</p>
                        <p className="fieldsDesc">Choose between light or dark.</p>
                    </div>

                    <div className="generalBlockItems themeLayout">
                        <div className="theme">
                            <div className={`theamLight ${theme === 'light' && 'active'}`}>
                                {featureImage === null &&
                                    <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                                }
                                <img src={featureImage} alt='featureImage' className="coverimg coverimg-light" />
                                <img src={TheamLight} alt="TheamLight" onClick={() => setTheme('light')} className="themetype" />
                            </div>

                            <div className={`theamDark ${theme === 'dark' && 'active'}`}>
                                {featureImage === null &&
                                    <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                                }
                                <img src={featureImage} alt='featureImage' className="coverimg coverimg-dark" />
                                <img src={TheamDark} alt="TheamDark" onClick={() => setTheme('dark')} className="themetype" />
                            </div>

                        </div>
                    </div>
                </div>

                <button
                    onClick={handleFormSubmit}
                    className={`form-btn save-change primary-button button ${isLoading ? 'btn_loading' : ''}`}
                    type='submit'
                    style={{ pointerEvents: disableSaveButton ? 'none' : 'auto' }}
                >
                    {isLoading ?
                        <div className="settingPage-loader">
                            <div className="ring-loader"></div>
                        </div>
                        : "Save Changes"}
                </button>
            </div>

            {featuredImageModal && (
                <>
                    <div className="imagCropModal">
                        <div className="featuredImageModal featurboxModal" ref={modalRef}>
                            <div className="modal-content">
                                {uploadedImage === null ? (
                                    <div
                                        className="newPageRightRow"
                                        onDragOver={handlefeatureImageDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handlefeatureImageDrop}
                                    >
                                        {((saveFeatureImage && saveFeatureImage === oldImage) ||
                                            saveFeatureImage !== featureImage) &&
                                            (saveFeatureImage ? (
                                                <div id="newpageImageUpload" className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} >
                                                    {loadingFeatureImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="featureImg"
                                                                        image={featureImage}
                                                                        oldImage={oldImage}
                                                                        setOldImage={setOldImage}
                                                                        setFeatureImage={setFeatureImage}
                                                                        setUploadedImage={setUploadedImage}
                                                                        replaceImage={handlefeatureImageFileUpload}
                                                                        zoom={zoom}
                                                                        position={position}
                                                                        setZoom={handleZoom}
                                                                        setPosition={handlePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                    onClick={() => document.getElementById("feature-img").click()}
                                                >
                                                    {loadingFeatureImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatar} alt="avatar-Images" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        {featureImage &&
                                            saveFeatureImage === featureImage &&
                                            (featureImage ? (
                                                <div id="newpageImageUpload" className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} >
                                                    {loadingFeatureImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="featureImg"
                                                                        image={featureImage}
                                                                        oldImage={oldImage}
                                                                        setOldImage={setOldImage}
                                                                        setFeatureImage={setFeatureImage}
                                                                        setUploadedImage={setUploadedImage}
                                                                        replaceImage={handlefeatureImageFileUpload}
                                                                        zoom={zoom}
                                                                        position={position}
                                                                        setZoom={handleZoom}
                                                                        setPosition={handlePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload generalImageAdmin" : "newpageImageUpload generalImageAdmin"}
                                                    onClick={() => document.getElementById("feature-img").click()}
                                                >
                                                    {loadingFeatureImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatar} alt="avatar-Images" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            id="feature-img"
                                            onChange={handlefeatureImageFileUpload}
                                            accept="image/*"
                                            hidden
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="crop-container">
                                            {featureImage && oldImage && (
                                                <ImageEditor
                                                    type="featureImg"
                                                    image={featureImage}
                                                    oldImage={oldImage}
                                                    setFeatureImage={setFeatureImage}
                                                    setUploadedImage={setUploadedImage}
                                                    replaceImage={handlefeatureImageFileUpload}
                                                    zoom={zoom}
                                                    position={position}
                                                    setZoom={handleZoom}
                                                    setPosition={handlePosition}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="replaceImageRange">
                                    <div className="replaceImgBtn">
                                        <button className="uploadImage secondary-button button" type="button" onClick={() => handleReplaceImg()} >
                                            <span>Replace Image</span>
                                        </button>
                                    </div>
                                    <div className="imgRange">
                                        <img className="coverImgLeft" src={coverImg} alt="cover-Images" />
                                        <input
                                            id="zoom-range"
                                            className="zoom-range"
                                            type="range"
                                            onMouseDown={handleMouseDown}
                                            onMouseUp={handleMouseUp}
                                            onTouchStart={handleTouchStart}
                                            onTouchEnd={handleTouchEnd}
                                            value={zoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aspect={384 / 200}
                                            aria-labelledby="Zoom"
                                            style={{ background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100}%, #B7BCC2 0%)`, }}
                                            onChange={(e) => { handleZoom(e.target.value); }}
                                        />
                                        <img className="coverImgRight" src={coverImg} alt="cover-Images" />
                                    </div>
                                </div>
                                <div className="featuredImageModalbtn">
                                    <button className="cancel secondary-button button" type="button" onClick={() => handleCancel()} > Cancel </button>
                                    <button className="savePicture primary-button button" type="button" onClick={() => handleSavePicture()} > Save Picture </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {featuredImageModal && <div className="overlayModal"> </div>}
                </>
            )}

            {profileCoverModal && (
                <>
                    <div className="profile-img-model imagCropModal">
                        <div className="featuredImageModal featurboxModal" ref={profileModalRef} >
                            <div className="modal-content">
                                {uploadedProfileImage === null ? (
                                    <div
                                        className="newPageRightRow"
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleProfileDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        {((saveProfileImage &&
                                            saveProfileImage === oldProfileImage) ||
                                            saveProfileImage !== image) &&
                                            (saveProfileImage ? (
                                                <div id="newpageImageUpload" className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="profileImg"
                                                                        image={image}
                                                                        oldImage={oldProfileImage}
                                                                        setOldImage={setOldProfileImage}
                                                                        setFeatureImage={setImage}
                                                                        setUploadedImage={setUploadedProfileImage}
                                                                        replaceImage={handleFileUpload}
                                                                        zoom={profileZoom}
                                                                        position={profilePosition}
                                                                        setZoom={handleProfileZoom}
                                                                        setPosition={handleProfilePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                    onClick={() => document.getElementById("upload-img").click()}
                                                >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatar} alt="avatar-Images" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}

                                        {image &&
                                            saveProfileImage === image &&
                                            (image ? (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"} >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <div className="crop-container">
                                                                    <ImageEditor
                                                                        type="profileImg"
                                                                        image={image}
                                                                        oldImage={oldProfileImage}
                                                                        setOldImage={setOldProfileImage}
                                                                        setFeatureImage={setImage}
                                                                        setUploadedImage={setUploadedProfileImage}
                                                                        replaceImage={handleFileUpload}
                                                                        zoom={profileZoom}
                                                                        position={profilePosition}
                                                                        setZoom={handleProfileZoom}
                                                                        setPosition={handleProfilePosition}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    id="newpageImageUpload"
                                                    className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                    onClick={() => document.getElementById("upload-img").click()}
                                                >
                                                    {loadingProfileImg === true ? (
                                                        <div className="featureImg-loader">
                                                            <Loader />
                                                        </div>
                                                    ) : (
                                                        <div className="featureImgMiddleContent">
                                                            <img className="profile-cover" src={avatarProfile} alt="avatarProfile" />
                                                            <p className="uploadimgs-text">
                                                                Drag and drop an image or click to upload
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        <input
                                            type="file"
                                            ref={profileFileInputRef}
                                            id="upload-img"
                                            onChange={handleFileUpload}
                                            accept="image/*"
                                            hidden
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="crop-container">
                                            {image && oldProfileImage && (
                                                <ImageEditor
                                                    image={image}
                                                    oldImage={oldProfileImage}
                                                    setOldImage={setOldProfileImage}
                                                    setFeatureImage={setImage}
                                                    setUploadedImage={setUploadedProfileImage}
                                                    replaceImage={handleFileUpload}
                                                    zoom={profileZoom}
                                                    position={profilePosition}
                                                    setZoom={handleProfileZoom}
                                                    setPosition={handleProfilePosition}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="replaceImageRange">
                                    <div className="replaceImgBtn">
                                        <button className="uploadImage secondary-button button" type="button" onClick={() => handleReplaceProfileImg()} >
                                            <span>Replace Image</span>
                                        </button>
                                    </div>
                                    <div className="imgRange">
                                        <img className="coverImgLeft" src={coverImg} alt="cover-Images" />
                                        <input
                                            id="zoom-range"
                                            className="zoom-range"
                                            type="range"
                                            onMouseDown={handleMouseDown}
                                            onMouseUp={handleMouseUp}
                                            onTouchStart={handleTouchStart}
                                            onTouchEnd={handleTouchEnd}
                                            value={profileZoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            aspect={384 / 200}
                                            aria-labelledby="Zoom"
                                            style={{ background: `linear-gradient(to right, #344054 ${((profileZoom - 1) / 2) * 100}%, #B7BCC2 0%)`, }}
                                            onChange={(e) => { handleProfileZoom(e.target.value); }}
                                        />
                                        <img className="coverImgRight" src={coverImg} alt="cover-Images" />
                                    </div>
                                </div>
                                <div className="featuredImageModalbtn">
                                    <button className="cancel button secondary-button" type="button" onClick={() => handleProfileCancel()} > Cancel </button>
                                    <button className="savePicture button primary-button" type="button" onClick={() => handleSaveProfilePicture()} > Save Picture </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {profileCoverModal && <div className="profileOverlayModal"> </div>}
                </>
            )}
            {showUploadMediaPopup && (
                <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedProfileImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
            )}
            {openNotify && 
                <Notification openNotify={openNotify} setOpenNotify={setOpenNotify} type={'success'} title={`Your settings has been successfully updated!`}/>
            }

        </>
    );
};
export default General;
