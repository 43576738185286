import React from "react";
import { Link } from "react-router-dom";
import './css/Footer.css';


function Footer(props) {
    return (
        <footer className={`main-footer stickyBar ${props?.menuActive ? 'fullStickyBar' : ''}`}>
            <div className='container footerContent'>
                <div className="footerLeft">
                    <p>© {new Date().getFullYear()} Persone. All rights reserved.</p>
                </div>
                <ul>
                    <li><Link>Terms of Service</Link></li>
                    <li><Link>Privacy Policy</Link></li>
                </ul>
            </div>
        </footer>
    );

}
export default Footer;
