import noResults from '../../images/noResults.svg'

function NoResultsCategories(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No categories found</p>
                <p className='noResDesc'>It looks like you haven’t created any categories yet. Organize your content into categories to keep everything structured and easy to manage.</p>
            </div>
        </div>
    );
}

export default NoResultsCategories;