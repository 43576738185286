import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

import DeleteUser from "./DeleteUser";
import AddNewDropdown from './AddNewDropdown';

import Home from "../../images/icon-home.svg";
import iconDivider from "../../images/chevron-right-light.svg";

import avatar from "../../images/profile-user.svg";
import phone from "../../images/phone-icon.svg";
import mail from "../../images/mail-icon.svg";
import dots from "../../images/dots-icon.svg";
import messenger from "../../images/fb-messenger-icon.svg";
import Delete from "../../images/delete.svg";

import '../../components/css/Profile.css'
import { isUserAdmin } from "../../utils/functions";


const Profile = ({ userData, isContentChange, showConfirmationPopup, activePage }) => {
    const { id } = useParams();  
    const [isOpen, setOpen] = useState(false);
    const [isOpenPopup, setOpenPopup] = useState(false);
    const [addTabActive, setAddTabActive] = useState(false)
    const [userInfoActive, setUserInfoActive] = useState(false)

    const ref = useRef(null);
    const tableActionRef = useRef();
    const addRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }

            if (tableActionRef.current && !tableActionRef.current.contains(event.target)) {
                setOpenPopup(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const togglePopup = () => {
        setOpen(!isOpen);
    };

    const onClosePopup = () => {
        setOpenPopup(false)
    }

    useEffect (()=>{
        const checkIfClickedOutside = (e) => {
            
            if (addRef && addRef.current && !addRef.current.contains(e.target)) {
                setAddTabActive(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    },[addTabActive])

    const capitalize = (str) => {
        if( str ) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
         return str;
    };

    return (
        <>
            <Helmet>
                <title>{`${capitalize(userData.firstName)} ${capitalize(userData.lastName)} | Admin Portal | Persone`}</title>
            </Helmet>
            <div className="breadcrumbs">
                <Link to={'/'} ><img src={Home} className="home-icon" alt="home-icon" /></Link>
                <img className="divider" src={iconDivider} alt="dropdown" />
                <Link to={`/users`} ><span className="">Users</span></Link>
                <img className="divider" src={iconDivider} alt="dropdown" />
                <Link to={`/users/${id}`}><span>{userData.firstName} {userData.lastName}</span></Link>
                <img className="divider" src={iconDivider} alt="dropdown" />
                <Link to={ 
                    activePage === "General" ? `/users/${id}/general` : 
                    activePage === "Password" ? `/users/${id}/password` :
                    activePage === "Subscription" ? `/users/${id}/subscription` : 
                    activePage === "Navigation menu" ? `/users/${id}/menu` :
                    activePage === "Social networks" ? `/users/${id}/social` :
                    activePage === "Pages" ? `/users/${id}/pages` :  
                    activePage === "Publications" ? `/users/${id}/publications` : 
                    activePage === "Newsletters" ? `/users/${id}/newsletters` : 
                    activePage === "Categories" ? `/users/${id}/categories` :  
                    activePage === "Media Library" ? `/users/${id}/media-gallery` :
                    activePage === "Subscriber" ? `/users/${id}/subscriber` : 
                    activePage === "Assistant History" ? `/users/${id}/assistant-history` :
                    activePage === "Support History" ? `/users/${id}/support-history` :'' } >
                    <span>{activePage}</span>
                </Link>
            </div>
            <section className="profile-section">
                <div className="profile-card profile-data">
                    <div className="profile-item">
                        <div className="profile-item-card">
                            <div className="profile-image">
                                <img src={userData?.profileMedia ? userData?.profileMedia : avatar} alt="profile-img" />
                            </div>
                            <div className="profile-content">
                                <h2 className="profile-title">
                                    {userData.firstName} {userData.lastName}
                                </h2>
                                <p className="profile-text">{userData.email}</p>
                                <span className='profile-btn profile-btn-pro'>{`${(userData?.planDetail?.plan === "free_plan" || userData?.planDetail?.plan === "basic_plan") ? "Free" : (userData?.planDetail?.plan === "unlimited_plan") ? "Unlimited" : "Personal Assistant"}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className="profile-item profile-social-item">
                        <div className="profile-social-content">
                            <div className="social-data">
                                <img src={phone} alt="phone-img" />
                                <p className="social-title">Phone Number</p>
                                <Link to={`tel:${userData?.profileAssistant?.phone || ''}`}>{userData?.profileAssistant?.phone || ''}</Link>
                            </div>
                            <div className="social-data">
                                <img src={mail} alt="mail-img" />
                                <p className="social-title">Email Address</p>
                                <Link to={`mailto:${userData?.profileAssistant?.email || ''}`}>{userData?.profileAssistant?.email || ''}</Link>
                            </div>
                            <div className="social-data">
                                <img src={messenger} alt="messenger-img" />
                                <p className="social-title">Facebook Messenger</p>
                                <Link to={userData?.profileAssistant?.facebookMessenger || '#'} target="_blank">Link</Link>
                            </div>
                        </div>
                    </div>

                    <div className="profile-item">
                        <div className="profile-buttons">
                            <div ref={addRef}>
                                <Link to={'#'} className='form-btn primary-button button' onClick={(e) => { setAddTabActive(!addTabActive); setUserInfoActive(false) }}>
                                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 8.1V12.9M4.6 10.5H9.4M13 10.5C13 13.8137 10.3137 16.5 7 16.5C3.68629 16.5 1 13.8137 1 10.5C1 7.18629 3.68629 4.5 7 4.5C10.3137 4.5 13 7.18629 13 10.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Create
                                </Link>
                                    {
                                        (addTabActive === true) ? <AddNewDropdown setClose={setAddTabActive} isContentChange={isContentChange} showConfirmationPopup={showConfirmationPopup}/> : ''
                                    }
                            </div>
                            <Link to={`https://jourmal-next-front.vercel.app/${userData.profileSlug}`} target="_blank" className='profile-link form-btn button'>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_40_7160)"><path d="M3.10101 11.3391C3.45587 10.503 4.28437 9.91666 5.24984 9.91666H8.74984C9.7153 9.91666 10.5438 10.503 10.8987 11.3391M9.33317 5.54166C9.33317 6.83032 8.2885 7.87499 6.99984 7.87499C5.71117 7.87499 4.6665 6.83032 4.6665 5.54166C4.6665 4.25299 5.71117 3.20832 6.99984 3.20832C8.2885 3.20832 9.33317 4.25299 9.33317 5.54166ZM12.8332 6.99999C12.8332 10.2217 10.2215 12.8333 6.99984 12.8333C3.77818 12.8333 1.1665 10.2217 1.1665 6.99999C1.1665 3.77833 3.77818 1.16666 6.99984 1.16666C10.2215 1.16666 12.8332 3.77833 12.8332 6.99999Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></g>
                                    <defs><clipPath id="clip0_40_7160"> <rect width="14" height="14" fill="white" /></clipPath> </defs>
                                </svg>
                                Profile
                            </Link>
                            {isUserAdmin() && (
                                <div className="action-wrapp" ref={ref}>
                                    <div className="icon-dots secondary-button button" onClick={togglePopup} >
                                        <img src={dots} alt="dots-icon" />
                                    </div>
                                    {isOpen && (
                                        <div className="popup-container">
                                            <div className="popup-delete" onClick={() => { setOpenPopup(true); setOpen(false); }} >
                                                <span>
                                                    <img src={Delete} alt="Delete-icon" />
                                                    Delete
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </section>

            <DeleteUser
                refrence={tableActionRef}
                isOpen={isOpenPopup}
                userData={userData}
                togglePopup={togglePopup}
                setOpen={setOpen}
                onClose={onClosePopup}
                navigate="users"
            />
        </>
    );
};

export default Profile;
