
import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultsAssistantHistory(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No assistant history found</p>
                <p className='noResDesc'>It looks like there’s no assistant history to display at the moment. Once assistant interactions are logged, they’ll appear here for your reference.</p>
            </div>
        </div>
    );
}

export default NoResultsAssistantHistory;
