import React from 'react'
import { FaFacebook } from 'react-icons/fa';
const HeroSection = (props) => {
  return (
    <section id='hero' className='hero-section homepreview'>
         <div className='hero-wrap'>
                <div className='hero-col home-col-left'>
                    <div className={`hero-content alignment-center heroContent`}>
                        <h2 className='hero-title text-white' style={{marginBottom: "0"}}>
                            {props?.heroData.displayName}
                        </h2>
                        <h3 className="hero-position text-white">{props?.heroData.jobTitle}</h3>
                        <p className="hero-paragraph text-white">
                            {props?.heroData?.summary}
                        </p>

                        {
                            props?.heroData.socialNeworks && props?.heroData.socialNeworks.length > 0 &&
                                <ul className="social-icons-cover">
                                    {
                                        props?.heroData.socialNeworks.map((val, index) => {
                                            return (
                                                <li key={index} className='social-linkedin'>
                                                    <a href={val.url} target="_blank" rel="noreferrer">
                                                        <FaFacebook/>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                        }

                    </div>
                </div>
              
                <div className="hero-col home-col-right">
                    <div className="hero-image">
                    <img
                        src={props?.heroData?.feature_imageUrl}
                        alt={props?.heroData.displayName}
                        fill={true}
                        quality={100}
                        priority
                    />
                    <div className="overlay"></div>
                    </div>
                </div>
            </div>
    </section>
  )
}
export default HeroSection