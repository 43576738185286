import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultPages(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No pages yet</p>
                <p className='noResDesc'>It looks like you haven’t started any pages. Begin creating your informational pages to showcase who you are and what you do.</p>
            </div>
        </div>
    );
}

export default NoResultPages;
