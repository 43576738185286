import { useState, useRef, useEffect } from "react";
import moment from 'moment';

import NoResultsMedia from "./NoResultsMedia";
import EditMediaPopup from "./EditMediaPopup";
import { formatFileSize, formattedDate } from "../../utils/functions"
import Loader from "../../components/Loader/Loader";

import down from "../../images/down-arrow.svg"
import top from "../../images/arrow_top.svg"
import more from "../../images/more-1.svg"


import filePdfIcon from "../../images/file-pdf-icon.svg"
import fileDocIcon from "../../images/file-doc-icon.svg"
import fileDocxIcon from "../../images/file-docx-icon.svg"
import filePptIcon from "../../images/file-ppt-icon.svg"
import filePptxIcon from "../../images/file-pptx-icon.svg"
import filePptmIcon from "../../images/file-pptm-icon.svg"
import fileXlsIcon from "../../images/file-xls-icon.svg"
import fileXlsxIcon from "../../images/file-xlsx-icon.svg"
import fileXlsmIcon from "../../images/file-xlsm-icon.svg"
import fileVideoIcon from "../../images/file-video-icon.svg"
import fileAudioIcon from "../../images/file-audio-icon.svg"
import { useParams } from "react-router";
import axios from "axios";
import { UpgradePopUp } from "../../components/modal/UpgradePopUp";


const ListLayout = ({ selectedItems, setSelectedItems, userMedia, setMediaItem, uploadNewmedia, setUploadNewmedia, isLoading, paymentMethods, setSaveNotify }) => {
    const ref = useRef();
    const [selectAll, setSelectAll] = useState(false);
    const [titleSort, setTitleSort] = useState(false);
    const [dateSort, setDateSort] = useState(false);
    const [sizeSort, setSizeSort] = useState(false);
    const [openPopupIndex, setOpenPopupIndex] = useState(null);

    const [deleteMeida, setDeleteMedia] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [editedItemId, setEditedItemId] = useState(null);
    const [fileList, setFileList] = useState({ '^image/': 'Images' });
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [errorMessages, setErrorMessages] = useState([])
    const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
    const { id } = useParams()
    const popupRef = useRef();
    const modelRef = useRef();
    const mediaIconSet = {
        'application/pdf': filePdfIcon,
        'application/doc': fileDocIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileDocxIcon,
        'application/vnd.ms-powerpoint': filePptIcon,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': filePptxIcon,
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': filePptmIcon,
        'application/vnd.ms-excel': fileXlsIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsxIcon,
        'application/vnd.ms-excel.sheet.macroenabled.12': fileXlsmIcon,
        'video/mp4': fileVideoIcon,
        'video/mpeg': fileVideoIcon,
        'video/ogg': fileVideoIcon,
        'video/webm': fileVideoIcon,
        'video/3gpp': fileVideoIcon,
        'video/3gpp2': fileVideoIcon,
        'video/x-msvideo': fileVideoIcon,
        'video/quicktime': fileVideoIcon,
        'audio/aac': fileAudioIcon,
        'audio/midi': fileAudioIcon,
        'audio/mpeg': fileAudioIcon,
        'audio/ogg': fileAudioIcon,
        'audio/wav': fileAudioIcon,
        'audio/webm': fileAudioIcon,
        'audio/3gpp': fileAudioIcon,
    }

    useEffect(() => {

        if (paymentMethods?.plan !== 'free_plan') {
            setFileList({
                '^image/': 'Images',
                '^video/': 'Videos',
                'application/pdf': 'PDF',
                'application/doc': 'DOC',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
                'application/vnd.ms-powerpoint': 'PPT',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
                'application/vnd.ms-excel': 'XLS',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            })
        } else {
            setFileList({
                '^image/': 'Images',
            })
        }


    }, [paymentMethods]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
            if (modelRef.current && !modelRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = userMedia.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    function sortByName() {
        var result;
        if (titleSort === true) {
            result = [...userMedia].sort((a, b) => a.name.localeCompare(b.name))
        } else {
            result = [...userMedia].sort((a, b) => -1 * a.name.localeCompare(b.name))
        }
        setMediaItem(result);
    }

    function sortBySize() {
        var result;
        if (sizeSort === true) {
            result = [...userMedia].sort((a, b) => a.size - b.size);
        } else {
            result = [...userMedia].sort((a, b) => b.size - a.size);
        }
        setMediaItem(result);
    }

    function sortByDate() {
        var result = [];
        if (dateSort === false) {
            result = [...userMedia].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.createdAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');

                return daysA - daysB;
            })
        } else {
            result = [...userMedia].sort(function (a, b) {
                var now = moment();

                var dateA = moment(a.createdAt).format('ll');
                var daysA = moment(dateA).diff(now, 'Days');

                var dateB = moment(b.createdAt).format('ll');
                var daysB = moment(dateB).diff(now, 'Days');
                return daysB - daysA;
            })
        }
        setMediaItem(result)
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter((_id) => _id !== itemId);
                setSelectAll(updatedSelectedItems.length === userMedia.length);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(updatedSelectedItems.length === userMedia.length);
                return updatedSelectedItems;
            }
        });
    };

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    const handleEditClick = (itemId) => {
        setShowPopup(true)
        setEditedItemId(itemId);
    };
    const maxSizeInBytes = 128 * 1024 * 1024; // 128MB

    const allowFIleType = {
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    }

    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();
        let newErrorMessages = [];
        setErrorMessages([]);
        setShowUpgradeBtn(false)
        setIsImageDragging(false);
        const files = e.dataTransfer.files;

        let uploadedMediaId = 1;

        for (const file of files) {

            const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));


            if (!isAllowedFileType) {
                // console.log(`File type ${file.type} is not allowed.`);
                const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                newErrorMessages.push(errorMessage);

                if (Object.keys(allowFIleType).some((pattern) => new RegExp(pattern).test(file.type))) {
                    setShowUpgradeBtn(true);
                }
                continue; // Skip this file
            }

            if (file.size > maxSizeInBytes) {
                console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                continue; // Skip this file
            }

            setErrorMessages(newErrorMessages)

            const formData = new FormData();
            formData.append('files', file); // Append each file individually

            const uploadMedia = {
                _id: uploadedMediaId,
                name: file.name,
                description: file.name,
                alt: file.name,
                type: file.type,
                size: file.size,
                object_id: "",
                key: "",
                url: "",
                createdAt: "",
                isLoading: true
            }

            uploadedMediaId = uploadedMediaId + 1;

            setMediaItem((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                // Update the uploadMedia object with the response data
                setMediaItem((prevUserMedia) =>
                    prevUserMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                    )
                );
            } catch (error) {
                console.log(error);
            }
        }

        setIsImageDragging(false);
        setErrorMessages(newErrorMessages);
    };

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };
    return (
        <>
            <div className={`${isImageDragging ? "draging table-data-wrapper table-pagination-wrapper pagesTableItem" : "table-data-wrapper table-pagination-wrapper pagesTableItem"}`} onDragOver={handleFileDragOver}
                onDragLeave={handleFileDragLeave}
                onDrop={handleGalleryImageDrop}>
                <div className="table-data table-five-column">
                    {isLoading ?
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        :
                        <>
                            <div className="table-head">
                                <div>
                                    <input type='checkbox' checked={selectAll} onChange={handleSelectAll} />
                                    <p className='pagesTopTitle' onClick={e => { setTitleSort(!titleSort); sortByName(); }}>
                                        File
                                    </p>
                                    <img src={(titleSort === false) ? down : top} alt="Down" onClick={e => { setTitleSort(!titleSort); sortByName(); }} />
                                </div>
                                <div>Type</div>
                                <div onClick={e => { setSizeSort(!sizeSort); sortBySize(); }}>
                                    Size  <img src={(sizeSort === false) ? down : top} alt="Down" />
                                </div>
                                <div onClick={() => {
                                    setDateSort(!dateSort)
                                    sortByDate()
                                }}>
                                    Date  <img src={(dateSort === false) ? down : top} alt="Down" />
                                </div>
                                <div>Actions</div>
                            </div>

                            {(userMedia && userMedia.length > 0) ?
                                <>
                                    {userMedia?.map((media, index) => (
                                        <>
                                            <div className="table-item " key={index}>
                                                {media?.isLoading ?
                                                    <div className="uploadPicture listLoader">
                                                        <Loader />
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='pagesTableCheck'>
                                                            <input type="checkbox" checked={selectedItems.includes(media._id)} onChange={() => handleCheckboxChange(media._id)} />
                                                            <div className='picture-main'>
                                                                <div className={`file-picture ${(media.type).includes('image') ? 'type-image' : 'type-doc'}`}>
                                                                    <img src={mediaIconSet[media.type] ? mediaIconSet[media.type] : media.url} alt={media.name} onClick={() => handleEditClick(media._id)} className='item' />
                                                                </div>
                                                                <div className='file-data-wrapper' >
                                                                    <div className='file-name' onClick={() => handleEditClick(media._id)}>
                                                                        {media.name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='pagesTableFormat'>{(media.object_id).split('.')[1].toUpperCase()}</div>
                                                        <div className='pagesTableFormat'>{formatFileSize(media.size)}</div>
                                                        <div className='table-date lastUpdated'>{formattedDate(media.createdAt)}</div>
                                                        <div className='table-action' onClick={() => togglePopup(media._id)} ref={ref} >
                                                            <img src={more} alt="More" />
                                                        </div>
                                                        {(media._id === openPopupIndex) &&
                                                            <div className='table-data-dropdown'>
                                                                <ul>
                                                                    <li>
                                                                        <span>
                                                                            <a className="table-edit-action" href="#" onClick={(e) => { e.preventDefault(); handleEditClick(media._id); setOpenPopupIndex(null); }}> Edit </a>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span>
                                                                            <a href="#" className="table-edit-action" onClick={(e) => { e.preventDefault(); setDeleteConfirmation(true); setDeleteMedia(media._id) }}> Delete </a>
                                                                        </span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </>
                                    ))}
                                    {showPopup &&
                                        <EditMediaPopup setShowPopup={setShowPopup} item={userMedia.find(item => item._id === editedItemId)} userMedia={userMedia} setUserMedia={setMediaItem} setSaveNotify={setSaveNotify} />
                                    }
                                </> :
                                (<NoResultsMedia type="media-gallery" name="Media Library" onclickhandle={() => setUploadNewmedia(!uploadNewmedia)} />)
                            }
                        </>
                    }
                    {errorMessages && errorMessages.length > 0 && (
                        <div className="errorMessages">
                            {errorMessages.map((errorMessage, index) => (
                                <>
                                    <p key={index} className="error-message" >{errorMessage}</p>
                                    {/* {showUpgradeBtn && <p className='upgrade-message' >Upgrade Now</p>}  */}
                                </>
                            ))}
                        </div>
                    )}
                    {showUpgradeBtn &&
                        <UpgradePopUp setShowUpgradeBtn={setShowUpgradeBtn} refrence={popupRef} setErrorMessages={setErrorMessages} />
                    }
                </div>
            </div>
        </>
    )
}

export default ListLayout;