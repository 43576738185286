import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';

import ReverseHistory from './ReverseHistory';
import NoResultsSupportHistory from './NoResultsSupportHistory';

import { formattedDate } from "../../utils/functions";

import avatar from "../../images/profile-user.svg";
import moreIcon from "../../images/more.svg";
import reverse from "../../images/reverse-left.png";
import search from '../../images/search.svg';
import { DataTableList } from "../../components/DataTable/DataTableList";

const SupportHistory = (props) => {
    props.setActivePage("Support History");
    const ref = useRef();
    const popupRef = useRef();

    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [readFields, setReadFields] = useState(true);
    const [originalData, setOriginalData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [columnWidths, setColumnWidths] = useState([]);

    const member_id = JSON.parse(localStorage.getItem("userData"))?._id;

    useEffect(() => {
        fetchData();
    }, [currentPage, searchInput]);

    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        setIsLoading(false)
        try {
            
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/gethistory/${member_id}?type=support&page=${currentPage}&search=${search}`,
            )

            setOriginalData(response.data.result);
            setTotalPage(response?.data?.totalPages || 1)
            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }

    const onClose = (e) => {
        setOpen(null);
        setOpenPopupitem([]);
        document.body.classList.remove('hidden-message');
    };

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const handleReverseActionEvent = (item) => {
        if (openPopupitem?.length != 0 || selectedRows.length != 0) {
            setOpen(true);
        }
        document.body.classList.add('hidden-message');
    };

    console.log({selectedRows})
    useEffect(() => {
            const handleClickOutside = (event) => {
                if (openPopupitem &&  (event.target.closest('.table-action') === null && event.target.closest('.table-data-dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                    togglePopup(null) // Close the dropdown
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [openPopupitem,togglePopup]);

    useEffect(() => {
            if (originalData.length > 0) {
                calculateColumnWidths();
            }
        }, [originalData]);

    const calculateColumnWidths = () => {
        const tempWidths = originalData?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                let maxContentLength = 0;

                if (key === 'title') {
                    maxContentLength = Math.max(dataValue.length + 5);
                } else if(key === 'userName'){
                    maxContentLength = Math.max(dataValue.length + 2.5);
                } else {
                    maxContentLength = Math.max(dataValue.length, title.length);
                }
                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});
        if(tempWidths !== undefined){
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                return acc;
            }, {});

            setColumnWidths(widthMap); // Set the calculated column widths
        }
    
    };

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(originalData.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    const columns = [
        {
            name: 'Action',
            selector: (row) => row.title,
            cell: (row) =>{
                return <div className="user-content">
                            <div className="table-link support-title">
                                <p> {row.title}: </p>
                                <span>{row.subTitle}</span>
                            </div>
                        </div>
            },
            sortable: true,
            minWidth: columnWidths['title'] && parseInt(columnWidths['title']) > 300 ? '300px' : columnWidths['title'],
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
        },
        {
            name: 'User',
            selector: (row) => row?.userName, 
            width: columnWidths['userName'] || '150px',
            cell: (row) => {
                return <div className="user-status">
                            <Link className="user-link" to={`/users/${row.user_id}`}>
                                <img src={row?.profilePicture || avatar} />
                                <span className="user-name">{row.userName}</span>
                            </Link>
                        </div>
            },
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Date',
            sortable: true,
            selector: (row) => formattedDate(row.createdAt),
            paddingLeft: 0,
            width: '130px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px",width: '130px', fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => {
                return (
                    <>
                        <div className="table-action" ref={ref} >
                            <img onClick={() => togglePopup(row)} src={moreIcon} alt="More" />
                        </div>
                        {openPopupitem && openPopupitem._id == row._id && (
                            <div className="table-data-dropdown" ref={popupRef}>
                                <ul>
                                    <li>
                                        <span onClick={() => handleReverseActionEvent(row)} >
                                            <img src={reverse} alt="reverse-icon" />
                                            Reverse
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
            },
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    return (
        <>
            <div className='pagesSearch twoItemWrap'>
                <div className="pageSearchInput">
                    <input type="text" name="pages_search" className="inputFields" id="pages_search" placeholder={'Search action...'} value={searchInput} onChange={e => setSearchInput(e.target.value)} readOnly={readFields} onFocus={ () => setReadFields(false) } onBlur={ () => setReadFields(true) } autocomplete="off" />
                    <img src={search} alt="Search" />
                </div>
                <div className="actions-buttons">
                    <div className="deleteIcon">
                        <img
                            onClick={() => handleReverseActionEvent([])}
                            className="deleteIconImage"
                            src={reverse}
                            alt="Clear Input"
                        />
                    </div>
                </div>
            </div>
            <div className="pagesTable dataTable-list">
                <DataTableList
                    columns={columns}
                    data={originalData}
                    onSelectedRowsChange={({ selectedRows }) => {
                        const selectedIds = selectedRows.map((row) => row._id);
                        setSelectedRows(selectedIds);
                        setIsAllSelected(selectedIds.length === originalData.length);
                    }}
                    progressPending={isLoading}
                    progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                    onChangePage={setCurrentPage}
                    paginationTotalRows={totalPage}
                    currentPage={currentPage}
                    customStyles={customStyles}
                    selectableRowsComponent="input"
                    selectAllRows={isAllSelected}
                    clearSelectedRows={toggleSelect}
                    selectableRows={true}
                    onSelectAllChange={handleMasterCheckboxChange}
                    noDataComponent={ <NoResultsSupportHistory />}
                />
            </div>
            <ReverseHistory
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedRows}
                onClose={onClose}
                setOpen={setOpen}
                fetchData={fetchData}
                originalData={originalData}
                setToggleSelect={setToggleSelect}
                toggleSelect={toggleSelect}
                setSelectedRows={setSelectedRows}
                setOpenPopupitem={setOpenPopupitem}
            />
        </>
    );
};
export default SupportHistory;
