import { useEffect, useRef, useState } from 'react';
import close from '../../images/close.svg'
import facebook from '../../images/social/Fb-i.svg'
import twitter from '../../images/social/X-icon.svg'
import linkedin from '../../images/social/Linkdin-i.svg'
import whatsapp from '../../images/social/whatsApp-i.svg'
const PreviewSuccessPopUp = ({ setShowPreviewPopup, showPreviewPopup, previewpage, tempData, userMetaData, title, description,dbPageTitle="" }) => {
    const popupRef = useRef();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const inputUrl = `https://jourmal-next-front.vercel.app/${userMetaData?.profileSlug}/${tempData?.url_address}`;

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showPreviewPopup && setShowPreviewPopup && popupRef?.current && !popupRef?.current.contains(e.target)) {
                setShowPreviewPopup(false)
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showPreviewPopup]);

    useEffect(() => {
        
        const metaTags = [
          { property: "og:title", content: dbPageTitle || "Title" },
          { property: "og:description", content: description || "Description" },
          { property: "og:image", content: tempData?.pageImage },
          { property: "og:url", content: inputUrl },
        ];
    
        metaTags.forEach(({ property, content }) => {
          let metaTag = document.querySelector(`meta[property='${property}']`);
          if (!metaTag) {
            metaTag = document.createElement("meta");
            metaTag.setAttribute("property", property);
            document.head.appendChild(metaTag);
          }
          metaTag.setAttribute("content", content);
        });
      }, [inputUrl, dbPageTitle, description, tempData?.pageImage]);


    const handleCopy = () => {
        if (navigator?.clipboard?.writeText) {
            navigator.clipboard.writeText(inputUrl)
                .then(() => {
                    showTooltip();
                })
                .catch((err) => console.error("Failed to copy text to clipboard:", err));
        } else {
            const tempInput = document.createElement("input");
            tempInput.value = inputUrl;
            document.body.appendChild(tempInput);
            tempInput.select();
            tempInput.setSelectionRange(0, 99999);
            document.execCommand("copy");
            document.body.removeChild(tempInput);

            showTooltip();
        }
    }

    const showTooltip = () => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 2000);
    };

    const handleCancel = () => {
        setShowPreviewPopup(false)
    }

    const handleFacebookShare = () => {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inputUrl)}`;
        window.open(facebookShareUrl, '_blank');
    }

    const handleTwitterShare = () => {
        const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(inputUrl)}`;
        window.open(twitterShareUrl, "_blank");
    };

    const handleLinkedInShare = () => {
        const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(inputUrl)}`;
        window.open(linkedInShareUrl, "_blank");
    };

    const handleWhatsAppShare = () => {
        const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(inputUrl)}`;
        window.open(whatsappShareUrl, "_blank");
    };
    return (
        <>
            <div className="popupModalOverlay"></div>
            <div className={`popupModal upgradepopup previewModal ${previewpage === "Publication" ? "publicationSuccessModal" : previewpage === "Page" ? "pageSuccessModal" : ""}`} ref={popupRef}>
                <div className="popupTop popupTopClose closePlan previewClose">
                    <img src={close} onClick={() => { handleCancel() }} alt="Icon" />
                </div>
                {
                    tempData?.pageImage && 
                    <div className="previewImage">
                        <img src={tempData?.pageImage} alt="Preview Image" />
                    </div>
                }
                <p className="preview-title">{title}</p>
                <p
                    className="preview-description"
                    dangerouslySetInnerHTML={{
                        __html: description.replace(
                            /navigation menu/g,
                            '<b>navigation menu</b>'
                        ),
                    }}
                ></p>
                {previewpage !== "Newsletter" &&
                    <div className="preview-linkContent">
                        {
                            previewpage === "Page" ? 
                            <label>Page link</label>:
                            <label>Share link</label>
                        }
                        <div className="preview-linkBox">
                            <input type="text" name="pagelink" value={inputUrl} />
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCopy} >
                                <g clip-path="url(#clip0_5706_684)">
                                    <path d="M4.16675 12.4974C3.39018 12.4974 3.00189 12.4974 2.69561 12.3705C2.28723 12.2014 1.96277 11.8769 1.79362 11.4685C1.66675 11.1622 1.66675 10.774 1.66675 9.9974V4.33073C1.66675 3.39731 1.66675 2.9306 1.8484 2.57408C2.00819 2.26047 2.26316 2.00551 2.57676 1.84572C2.93328 1.66406 3.39999 1.66406 4.33341 1.66406H10.0001C10.7767 1.66406 11.1649 1.66406 11.4712 1.79093C11.8796 1.96009 12.2041 2.28454 12.3732 2.69292C12.5001 2.99921 12.5001 3.38749 12.5001 4.16406M10.1667 18.3307H15.6667C16.6002 18.3307 17.0669 18.3307 17.4234 18.1491C17.737 17.9893 17.992 17.7343 18.1518 17.4207C18.3334 17.0642 18.3334 16.5975 18.3334 15.6641V10.1641C18.3334 9.23064 18.3334 8.76393 18.1518 8.40741C17.992 8.09381 17.737 7.83884 17.4234 7.67905C17.0669 7.4974 16.6002 7.4974 15.6667 7.4974H10.1667C9.23333 7.4974 8.76662 7.4974 8.4101 7.67905C8.09649 7.83884 7.84153 8.09381 7.68174 8.40741C7.50008 8.76393 7.50008 9.23064 7.50008 10.1641V15.6641C7.50008 16.5975 7.50008 17.0642 7.68174 17.4207C7.84153 17.7343 8.09649 17.9893 8.4101 18.1491C8.76662 18.3307 9.23333 18.3307 10.1667 18.3307Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5706_684">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {tooltipVisible && <div className="text-tooltip">URL Copied!</div>}
                        </div>
                    </div>
                }
                {
                    previewpage === "Publication" &&

                    <div className="preview-linkContent preview-share">

                        <label>Share on</label>

                        <div className='shareIcons'>
                            <img src={facebook} alt="Icon" onClick={handleFacebookShare} />
                            <img src={twitter} alt="Icon" onClick={handleTwitterShare} />
                            <img src={linkedin} alt="Icon" onClick={handleLinkedInShare} />
                            <img src={whatsapp} alt="Icon" onClick={handleWhatsAppShare} />
                        </div>
                    </div>
                }
                <div className="welcomeModalbtn previewPopupButton" id="deleteMedia">
                    {previewpage === "Page" &&
                        <>
                            <button className="cancel secondary-button button" type="button" onClick={handleCancel}>Cancel</button>
                            <button className="savePicture addSubscriberWidth primary-button button" type="button" onClick={() => {
                                window.open(`https://reactadminportal.codeptsolutions.com/users/${userMetaData?._id}/menu`);
                            }}>Open navigation menu</button>
                        </>
                    }
                    {previewpage === "Publication" &&
                        <>
                            <button className="cancel secondary-button button" type="button" onClick={() => {
                                window.open(`${inputUrl}`);
                            }}>Open publication</button>
                            <button className="savePicture addSubscriberWidth primary-button button" type="button" onClick={handleCancel}>Finish</button>
                        </>
                    }
                    {previewpage === "Newsletter" &&
                        <button className={`cancel preview-cancel closeNewsletter button`} onClick={handleCancel}>Close</button>
                    }
                </div>
            </div>
        </>
    )
}

export default PreviewSuccessPopUp;