import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import ReactPlayer from 'react-player/lazy'

import dateFormatter from "../../utils/dateFormatter"
import { formatFileSize, getImageDimensions } from '../../utils/functions';

import copyLink from '../../images/icon-copy.svg'
import deleteIcon from '../../images/delete.svg'
import linkExternal from '../../images/icon-link-external.svg'
import close from '../../images/close.svg'

import filePdfIcon from "../../images/file-pdf-icon.svg"
import fileDocIcon from "../../images/file-doc-icon.svg"
import fileDocxIcon from "../../images/file-docx-icon.svg"
import filePptIcon from "../../images/file-ppt-icon.svg"
import filePptxIcon from "../../images/file-pptx-icon.svg"
import filePptmIcon from "../../images/file-pptm-icon.svg"
import fileXlsIcon from "../../images/file-xls-icon.svg"
import fileXlsxIcon from "../../images/file-xlsx-icon.svg"
import fileXlsmIcon from "../../images/file-xlsm-icon.svg"
import fileVideoIcon from "../../images/file-video-icon.svg"
import fileAudioIcon from "../../images/file-audio-icon.svg"


const EditMediaPopup = ({ setShowPopup, item, userMedia, setUserMedia, setSaveNotify }) => {

    const [name, setName] = useState(item.name);
    const [description, setDescription] = useState(item.description);
    const [alt, setAlt] = useState(item.alt);
    const [dimensions, setDimensions] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isDelLoading, setDelIsLoading] = useState(false);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [tooltipShow, setTooltipShow] = useState(false);

    const usePopupRef = useRef();

    const mediaIconSet = {
        'application/pdf': filePdfIcon,
        'application/doc': fileDocIcon,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': fileDocxIcon,
        'application/vnd.ms-powerpoint': filePptIcon,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': filePptxIcon,
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': filePptmIcon,
        'application/vnd.ms-excel': fileXlsIcon,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': fileXlsxIcon,
        'application/vnd.ms-excel.sheet.macroenabled.12': fileXlsmIcon,
        'video/mp4': fileVideoIcon,
        'video/mpeg': fileVideoIcon,
        'video/ogg': fileVideoIcon,
        'video/webm': fileVideoIcon,
        'video/3gpp': fileVideoIcon,
        'video/3gpp2': fileVideoIcon,
        'video/x-msvideo': fileVideoIcon,
        'video/quicktime': fileVideoIcon,
        'audio/aac': fileAudioIcon,
        'audio/midi': fileAudioIcon,
        'audio/mpeg': fileAudioIcon,
        'audio/ogg': fileAudioIcon,
        'audio/wav': fileAudioIcon,
        'audio/webm': fileAudioIcon,
        'audio/3gpp': fileAudioIcon,
    }

    const itemPreviewUrl = mediaIconSet[item.type] ? mediaIconSet[item.type] : item.url;


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (usePopupRef.current && !usePopupRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(deleteConfirmation) {
            const checkIfClickedOutside = (e) => {
            if (e.target != null && deleteConfirmation && (e.target.classList.contains('mediaModalOverlay'))) {
                setDeleteConfirmation(false);
            }
            };
            document.addEventListener("mousedown", checkIfClickedOutside);
        
            return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
            };
        }
    }, [deleteConfirmation]);
    
    useEffect(() => {
        if( (item.type).includes('image') ) {
            getImageDimensions(item.url)
            .then(dimensions => {
                setDimensions(dimensions);
            })
            .catch(error => {
                console.error('Error getting image dimensions:', error);
            });
        }        
    }, [item]);

    const handleVideoReady = () => {
        setIsVideoReady(true);
    };

    const handleSaveButton = async () => {
        try{
            setIsLoading(true);

            const mediaData = { name, description, alt };

            await axios.post(
                `${process.env.REACT_APP_API_URL}/user/updateMedia/${item._id}`,
                mediaData
            );

            const updatedData = userMedia.map(media =>
                media._id === item._id ? { ...media, name, description, alt } : media
            );

            setUserMedia(updatedData);

            setIsLoading(false);
            setShowPopup(false)
            setSaveNotify(true);

        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }

    const handleDeleteButton = async () => {
        try{
            setDelIsLoading(true);            
            await axios.delete( `${process.env.REACT_APP_API_URL}/user/deleteMedia/${item._id}`);

            const updatedData = userMedia.filter(media => media._id !== item._id);
            setUserMedia(updatedData);

            setDelIsLoading(false);
            setDeleteConfirmation(false);
            setShowPopup(false);
        } catch (error) {
            setDelIsLoading(false);
            console.log(error)
        }
    }

    const clickCopyUrl = () => {
        navigator.clipboard.writeText(item.url);
        setTooltipShow(true); 
        setTimeout(() => {
            setTooltipShow(false); 
        }, 2000);
    };

    return (
        <div className="popupContainer modalPopup">
            <div className="popupOverlay"></div>
            <div className="popupBlock">
                <div className="popupBlock-wrapper">
                    <div className="mediaGallery" ref={usePopupRef}> 
                        <div className="file">
                            <div className="modalHeader">
                                <p className='filename'>{item.name}</p>
                            </div>
                            <div className="modalContent">
                                <div className="contentrow">
                                    <div className="modaldate">
                                        <samp className="title">Date:</samp>
                                        <span className='subtext'>{dateFormatter(item.date, 'date', '', false, true)}</span>
                                    </div>
                                    <div className="modaltype">
                                        <samp className="title">Type:</samp>
                                        <span className='subtext'>{(item.object_id).split('.')[1].toUpperCase()}</span>
                                    </div>
                                    <div className="modalsize">
                                        <samp className="title">Size:</samp>
                                        <span className='subtext'>{formatFileSize(item.size)}</span>
                                    </div>
                                    {dimensions &&                                    
                                        <div className="modaldimensions">
                                            <samp className="title">Dimensions:</samp>
                                            <span className='subtext'>{dimensions}</span>
                                        </div>
                                    }
                                </div>
                                <form className='modalContentform'>
                                    <div className="formContent">
                                        <label for="fileName">File Name:</label>
                                        <input type="text" className='modalInput' placeholder="File Name" value={name} onChange={(e) => {setName(e.target.value)}} />
                                    </div>
                                    <div className="formContent fromDesc">
                                        <label for="description">Caption:</label>
                                        <textarea placeholder='Write a short description of the image...' className='modalInput' value={description} 
                                        onChange={e => {
                                                if (e.target.value.length > 300) {
                                                    e.target.value.substring(0, 300)
                                                } else {
                                                    setDescription(e.target.value)
                                                }
                                            }
                                        }
                                        />
                                    </div>
                                    <div className="formContent">
                                        <label for="fileName">Alt</label>
                                        <input type="text" placeholder="Enter alt text for accessibility"  className='modalInput' value={alt} onChange={(e) => {setAlt(e.target.value)}} />
                                    </div>
                                    <div className="formContent">
                                        <label for="url">URL:</label>
                                        <div className="formURL">
                                            <input type="url" placeholder={item.url} value={item.url} readOnly className='modalInput' />
                                            {tooltipShow && <p className="tooltip-text">URL Copied!</p>}
                                            <div className="formUrlImg">
                                                <Link to="#">
                                                    <img src={copyLink} alt="copyLink" onClick={clickCopyUrl}/>
                                                </Link>
                                                <Link to={item.url} target="_blank">
                                                    <img src={linkExternal} alt="linkExternal" />
                                                </Link>
                                            </div>
                                        </div> 
                            
                                    </div>
                                    <div className="modalDelete" onClick={() => {setDeleteConfirmation(true)}}>
                                        <img src={deleteIcon} alt="deleteIcon" />
                                        <p className='formContentbtn'>Delete</p>
                                    </div>
                                </form>
                            </div>
                            <div className="modalActions desktop-view ">
                                <input type="reset" value="Cancel" className="cancelButton editMediaBtn secondary-button button" onClick={() => setShowPopup(false)} />                        
                                <button className={`settingsBlue primary-button button saveButton editMediaBtn ${isLoading ? 'btn_loading' : 'editMediaBtn'}`} onClick={handleSaveButton}>
                                    {isLoading ?
                                        <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                        </div>
                                        : "Save"
                                    }
                                    </button>
                            </div>
                        </div>
                        <div className="preview">
                            <div className="modalHeader">
                                <p className='previewname'>Preview</p>
                            </div>
                            { (item.type).includes('video') ? 
                                <div className='video-box-group'>
                                    {! isVideoReady &&
                                        <div className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                                    }
                                    <div className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                                        <ReactPlayer
                                            className='react-player'
                                            url={item.url}
                                            width='100%'
                                            height='100%'
                                            controls
                                            onReady={handleVideoReady}
                                        />
                                    </div>                                    
                                </div>
                                :
                                <div className={`modalImg ${(item.type).includes('image') ? 'type-image' : 'type-doc'}`}>
                                    <img src={itemPreviewUrl} alt="" />
                                </div>
                            }
                        </div>
                        <div className="popupTop popupClose">
                            <img src={close} onClick={() => setShowPopup(false)} alt="Icon" />
                        </div>
                        <div className="modalActions mobile-view">
                            <input type="reset" value="Cancel" className="cancelButton secondary-button button" onClick={() => setShowPopup(false)} />
                            <button className={`settingsBlue primary-button button saveButton editMediaBtn ${isLoading ? 'btn_loading' : ''}`} onClick={handleSaveButton}>
                            {isLoading ?
                                <div className="settingPage-loader">
                                <div className="ring-loader"></div>
                                </div>
                                : "Save"
                            }
                            </button>
                        </div>

                        { deleteConfirmation && 
                            <>
                                <div className="welcomeOverlay mediaModalOverlay"></div>
                                <div className='welcomeModal mediaDeleteModal'>
                                    <div className='popupTopClose'>
                                        <img src={close} alt="close-icon" onClick={() => setDeleteConfirmation(false)} ></img>
                                    </div>
                                    <h3 className='popupModalTitle'>Delete Media</h3>
                                    <p className='popupModalContent'>Are you sure you want to delete this media? This action cannot be undone.</p>

                                    <div className="popupModalBtn">
                                        <button className="cancel secondary-button button" type="button" onClick={() => setDeleteConfirmation(false)}>Cancel</button>
                                        <button className="savePicture primary-button button" type="button" onClick={() => {handleDeleteButton()}} >
                                            {isDelLoading ?
                                                <div className="settingPage-loader">
                                                <div className="ring-loader"></div>
                                                </div>
                                                : "Delete"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
  };

  export default EditMediaPopup;
