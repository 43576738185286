import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultSubscriber() {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No subscribers yet</p>
                <p className='noResDesc'>It looks like you don’t have any subscribers yet. Start attracting them by offering valuable content and engaging with your audience.</p>
            </div>
        </div>
    );
}

export default NoResultSubscriber;