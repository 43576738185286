import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultsUsers(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No users found</p>
                <p className='noResDesc'>It looks like there are no users to display right now. Once users are added, you’ll see them listed here for easy management.</p>
            </div>
        </div>
    );
}

export default NoResultsUsers;
