import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";

function AddNewDropdown(props) {
    const { id } = useParams();
    const handleClick = (e, url) =>{
        if(props?.isContentChange === true){
            e.preventDefault();
            props?.showConfirmationPopup(url)
        }
        props.setClose(false)
    }
    return (
        <div className='dropdown addDropdown page-dropdown'>
            <ul>
                <li>
                    <Link to={`/users/${id}/pages/page/`} onClick={(e)=> handleClick(e,`/users/${id}/pages/page/`)}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Page</p>
                    </Link>
                </li>
                <li>
                    <Link to={`/users/${id}/publications/publication/`} onClick={(e)=> handleClick(e,`/users/${id}/publications/publication/`)}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Publication</p>
                    </Link>
                </li>
                <li>
                    <Link to={`/users/${id}/newsletters/newsletter/`} onClick={(e)=> handleClick(e,`/users/${id}/newsletters/newsletter/`)}>
                        {/* <img src={note_icon} alt="Icon" /> */}
                        <p>New Newsletter</p>
                    </Link>
                </li>

            </ul>
        </div>
    );
}

export default AddNewDropdown;
