import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import '../error/Error404.css';

const Error404 = (props) => {
    // props.setActivePage( "Error" );
    let navigate = useNavigate();

    return (
        <>
            <section className={`dashboard error-page ${props?.name}`}>
                <div className='erorBlock'>
                    <span>404 error</span>
                    <h1>We lost this page</h1>
                    <p>We searched high and low, but couldn’t find what you’re looking for.</p>
                    <p className='errorSubtitle'>Let’s find a better place for you to go.</p>
                    <div className='erorButton'>
                        <button onClick={() => navigate(-1)} className='erorButton_left'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                            Go back</button>
                        <Link to={'/'}><button className='erorButton_right'>Take me home</button></Link>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Error404;
