import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

import DeleteTeamMember from "./DeleteTeamMember";
import UserRoleActionDropdown from "../../components/userRoleActionDropdown";
import NoResultsTeam from "./NoResultsTeam";
import AddNewTeamMember from "./AddNewTeamMember";

import { formattedDate, getCountryName, getCountryWithFlag, isUserAdmin } from "../../utils/functions";

import avatar from "../../images/profile-user.svg";
import moreIcon from "../../images/more.svg";
import penIcon from "../../images/pen-img.svg";
import Delete from "../../images/delete.svg";
import search from "../../images/search.svg";
import iconDropDown from "../../images/icondropdown.svg";
import plus from '../../images/plus.svg'
import { DataTableList } from "../../components/DataTable/DataTableList";

const TeamMemberTable = () => {
    const ref = useRef();
    const userroleRef = useRef();
    const usercountryRef = useRef();
    const tableActionRef = useRef();

    const [selectAll, setSelectAll] = useState(false);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [isAddNewPopupOpen, setAddNewPopupOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userMemberList, setUserMemberList] = useState([]);
    const [originalData, setOriginalData] = useState(userMemberList);
    const [userRoleSelect, setUserRoleSelect] = useState(false)
    const [selectedUserRole, setSelectedUserRole] = useState([])

    const userRoleList = ['admin', 'assistant', 'support'];

    const [userCountrySelect, setUserCountrySelect] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [selectedCountryCode, setSelectedCountryCode] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [readFields, setReadFields] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [toggleSelect, setToggleSelect] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [columnWidths, setColumnWidths] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getcountries?type=team`);

                if (response.status == 200) {
                    setCountryList(response.data.result || [])
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData(); // Call the async function immediately
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const search = searchInput.toLowerCase();
            const countries = selectedCountryCode.length > 0 ? selectedCountryCode.join(',') : '';
            const roles = selectedUserRole.length > 0 ? selectedUserRole.join(',') : '';

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getteam/?page=${currentPage}&search=${search}&countries=${countries}&roles=${roles}`);

            if (response.status == 200) {
                const teamMemberArray = response.data.team.map(item => ({
                    ...item,
                    countryName: getCountryName(item.country),
                }));

                setTotalPage(response.data.totalPages || 1);

                setUserMemberList(teamMemberArray);
                setOriginalData(teamMemberArray)
                setIsLoading(false)
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Call the async function immediately
    }, [currentPage, searchInput, selectedCountryCode, selectedUserRole]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (userroleRef.current && !userroleRef.current.contains(event.target)) {
                setUserRoleSelect(false);
            }

            if (usercountryRef.current && !usercountryRef.current.contains(event.target)) {
                setUserCountrySelect(false)
            }

            if (tableActionRef.current && !tableActionRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (originalData !== undefined) {
            // Initialize result arrays
            let uniqueRoles = [];
            let uniqueCountries = [];

            // Iterate over originalData to extract unique roles and countries
            originalData.forEach(element => {
                if (!uniqueRoles.includes(element.role)) {
                    uniqueRoles.push(element.role);
                }
                if (!uniqueCountries.includes(element.country)) {
                    uniqueCountries.push(element.country);
                }
            });

            // Set state values
            setUserMemberList(originalData);
        } else {
            // Handle case when originalData is undefined
            setUserRoleSelect(false);
            setUserMemberList([]);
        }
    }, [originalData]);


    const handleActionEvent = (e) => {
        togglePopup(null);
    };

     useEffect(() => {
        if (originalData.length > 0) {
            calculateColumnWidths();
        }
    }, [originalData]);

    const calculateColumnWidths = () => {
        const tempWidths = originalData?.reduce((acc, row) => {
            Object.keys(row).forEach((key) => {
                const dataValue = row[key].toString();
                const title = row[key];

                let maxContentLength = 0;
                if (key === 'country') {
                    const countryFlagContent = getCountryName(row?.country);
                    maxContentLength = Math.max(countryFlagContent.length + 1); // Add extra padding for flag content
                } else if(key === 'role') {
                    if(row?.role === 'support' || row?.role === 'admin'){
                        maxContentLength =  Math.max(dataValue.length + 3);
                    } else{
                        maxContentLength = Math.max(dataValue.length + 1.5);
                    }
                } else {
                    maxContentLength = Math.max(dataValue.length, title.length);
                }
                if (!acc[key] || maxContentLength > acc[key]) {
                    acc[key] = maxContentLength;
                }
            });
            return acc;
        }, {});
        if(tempWidths !== undefined){
            const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                
                    acc[key] = `${tempWidths[key] * 10 + 40}px`;
                return acc;
            }, {});

            setColumnWidths(widthMap); // Set the calculated column widths
        }
    
    };

    const onClose = (e) => {
        setOpen(false);
        setAddNewPopupOpen(false);
        document.body.classList.remove('hidden-message');
    };

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const handleDeleteActionEvent = (item) => {
        if (item?.length != 0 || selectedRows?.length != 0) {
            setOpen(true);
        }
        document.body.classList.add('hidden-message');
    };

    

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (openPopupitem &&  (event.target.closest('.table-action') === null && event.target.closest('.table-data-dropdown') === null) && !document.body.classList.contains('hidden-message')) {
                togglePopup(null) // Close the dropdown
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openPopupitem,togglePopup]);

    const columns = [
        {
            name: 'Full Name',
            selector: (row) => row.displayName,
            cell: (row) => {
                return <div className="user-content teamTable">
                            <img src={(row?.profileMedia !== "" && row?.profileMedia !== undefined) ? row?.profileMedia : avatar} />
                            <div className="user-brief">
                                <span className="user-name">
                                    <Link to={`/team/${row._id}`}>{row.displayName}</Link>
                                </span>
                                <p className="user-mail">
                                    {row.email}
                                </p>
                            </div>
                        </div>
            },
            sortable: true,
            minWidth: columnWidths['displayName'] && parseInt(columnWidths['displayName']) > 300 ? '300px' : columnWidths['displayName'],
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
        },
        {
            name: 'Role',
            selector: (row) => row?.role,
            width: columnWidths['role'] || '150px',
            cell: (row) => {
                return <div className={`table-role ${row.role}`}>
                    <span className="plan-btn">{row.role}</span>
                </div>
            },
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Country',
            sortable: true,
            selector: (row) => row.country,
            cell: (row) => {
                return <div className="table-country">
                    <span className="country-flag">{getCountryWithFlag(row.country)}</span>
                </div>
            },
            paddingLeft: 0,
            width: columnWidths['country'] || '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
        },
        {
            name: 'Date',
            sortable: true,
            selector: (row) => formattedDate(row.createdAt),
            cell: (row) => {
                return <div className="subscription-date">
                    <span>{formattedDate(row.createdAt)}</span>
                </div>
            },
            paddingLeft: 0,
            width: '130px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", width: '130px', fontSize: "14px" },
        },
        {
            name: 'Actions',
            cell: (row) => {
                return (
                    <>
                        <div className="table-action" onClick={() => togglePopup(row)} ref={ref} >
                            <img src={moreIcon} alt="More" />
                        </div>
                        {openPopupitem && openPopupitem._id == row._id && (
                            <div className="table-data-dropdown" ref={tableActionRef}>
                                <ul>
                                    <li>
                                        <span>
                                            <Link className="table-edit-action" to={`/team/${row._id}`} onClick={handleActionEvent} >
                                                <img src={penIcon} alt="pen-icon" />
                                                {isUserAdmin() ? 'Edit' : 'View'}
                                            </Link>
                                        </span>
                                    </li>
                                    <li>
                                        {isUserAdmin() && (
                                            <span onClick={() => handleDeleteActionEvent(row)} >
                                                <img src={Delete} alt="pen-icon" />
                                                Delete
                                            </span>
                                        )}

                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )
            },
            justifyContent: 'flex-end',
            allowOverflow: true,
            button: true,
            minWidth: "55px",
            paddingRight: '16px',
            width: '80px',
            style: { textAlign: 'right', minWidth: '80px', paddingLeft: 0, paddingRight: "16px", paddingTop: "15px", paddingBottom: "15px" }, // Aligning to the right
            // headerStyle: { textAlign: 'right' },
        },
    ];

    const handleMasterCheckboxChange = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(originalData.map((row) => row._id)); // Select all rows
        }
        setIsAllSelected(!isAllSelected); // Toggle master checkbox
    };

    

    return (
        <>
            <div className="pageSearchInput mobile-team-searchbar">
                <input
                    type="text"
                    name="pages_search"
                    id="pages_search"
                    className="inputFields"
                    placeholder={"Search team member..."}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    autocomplete="off"
                    readOnly={readFields}
                    onFocus={() => setReadFields(false)}
                    onBlur={() => setReadFields(true)}
                />
                <img src={search} alt="Search" />
            </div>
            <div className="pagesSearch pageTeam">
                <div className="pageSearchTop">
                    <div className="pageSearchInput desktop-searchbar">
                        <input
                            type="text"
                            name="pages_search"
                            id="pages_search"
                            className="inputFields"
                            placeholder={"Search team member..."}
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            autocomplete="off"
                            readOnly={readFields}
                            onFocus={() => setReadFields(false)}
                            onBlur={() => setReadFields(true)}
                        />
                        <img src={search} alt="Search" />
                    </div>

                    <div ref={usercountryRef} className="select-country dropdown-wrapp">
                        <button className="selectFields" onClick={(e) => setUserCountrySelect(!userCountrySelect)}>
                            All Countries{" "}
                            {selectedCountryCode.length > 0 ? `(${selectedCountryCode.length})` : ""}
                        </button>
                        {
                            userCountrySelect === true &&
                            <div className="dropdown addDropdown countryDropdown">
                                <ul>
                                    {countryList && countryList.length > 0 &&
                                        countryList.map((country) => (
                                            <li key={country} onClick={() => {
                                                const result = selectedCountryCode.includes(country)
                                                    ? selectedCountryCode.filter(
                                                        (selectcountry) =>
                                                            selectcountry !== country
                                                    )
                                                    : [...selectedCountryCode, country];
                                                setSelectedCountryCode(result);
                                                setCurrentPage(1);
                                            }}>
                                                <input className="countrySelect" type="checkbox" checked={selectedCountryCode.includes(country)} />
                                                <span className="country-flag">{getCountryWithFlag(country)}</span>
                                            </li>

                                        ))
                                    }
                                </ul>
                            </div>

                        }
                    </div>

                    <div ref={userroleRef} className="userrole-dropdown dropdown-wrapp">
                        <button className="selectFields" onClick={(e) => setUserRoleSelect(!userRoleSelect)}>
                            All Roles{" "}
                            {selectedUserRole.length > 0 ? `(${selectedUserRole.length})` : ""}
                            <img src={iconDropDown} alt="User Roles" />
                        </button>
                        {
                            (userRoleSelect === true) ? <UserRoleActionDropdown userRoles={userRoleList} selected={selectedUserRole} changeRole={setSelectedUserRole} setCurrentPage={setCurrentPage} /> : ''
                        }
                    </div>
                </div>
                {isUserAdmin() && (
                    <div className="actions-buttons">
                        {selectedRows?.length > 0 && 
                            <div className="deleteIcon">
                                <img
                                    onClick={() => handleDeleteActionEvent([])}
                                    className="deleteIconImage"
                                    src={Delete}
                                    alt="Clear Input"
                                    />
                            </div>
                        }
                        <div className="add-new-member">
                            <button className="form-btn new-member-btn primary-button button"
                                onClick={() => setAddNewPopupOpen(!isAddNewPopupOpen)}>
                                <img src={plus} alt="plus" />
                                New Team Member
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="pagesTable dataTable-list">
                <DataTableList
                    columns={columns}
                    data={originalData}
                    onSelectedRowsChange={({ selectedRows }) => {
                        const selectedIds = selectedRows.map((row) => row._id);
                        setSelectedRows(selectedIds);
                        setIsAllSelected(selectedIds.length === originalData.length);
                    }}
                    progressPending={isLoading}
                    progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                    onChangePage={setCurrentPage}
                    paginationTotalRows={totalPage}
                    currentPage={currentPage}
                    customStyles={customStyles}
                    selectableRowsComponent="input"
                    selectAllRows={isAllSelected}
                    clearSelectedRows={toggleSelect}
                    selectableRows={true}
                    onSelectAllChange={handleMasterCheckboxChange}
                    noDataComponent={<NoResultsTeam/>}
                />
            </div>
            <DeleteTeamMember
                refrence={tableActionRef}
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedRows}
                setOpen={setOpen}
                setUserMemberList={setUserMemberList}
                setSelectAll={setSelectAll}
                setSelectedItems={setSelectedRows}
                userMemberList={userMemberList}
                togglePopup={togglePopup}
                onClose={onClose}
                setToggleSelect={setToggleSelect}
                toggleSelect={toggleSelect}
                setOpenPopupitem={setOpenPopupitem}
                fetchData={fetchData}
            />

            <AddNewTeamMember
                isOpen={isAddNewPopupOpen}
                onClose={onClose}
                setUserMemberList={setUserMemberList}
                setOriginalData={setOriginalData}
                fetchData={fetchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </>
    );
};
export default TeamMemberTable;
