import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import './previewPage.css'
import logo from "../../../images/register-logo.svg"
import send from "../../../images/send.svg";
import sendDark from "../../../images/sendDark.svg";
import Error404 from "../../error/Error404";

export const PreviewNewsletter = (props) => {
  props.setActivePage("Preview Newsletter");
  const { id,  newsletterid } = useParams();
  const [tempData, setTempData] = useState({});
  const [featureImage, setFeatureImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [newsletterId, setNewsletterId] = useState("");
  const [authorId, setAuthorId] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [isFetchDataLoading, setIsFetchDataLoading] = useState(false);
  const handleBack = () => {
      window.close(); 
  };
  
  const fetchData = async () => {
    try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/newsLetter/getPreviewNewsletter/${newsletterid}?userId=${id}`
    );
    if(response?.status === 200){
      setIsFetchDataLoading(false)
      setTempData(response?.data?.result?.newsLetterFind);
      setFeatureImage(response?.data?.result?.featureImageData?.cropedMedia);
      setAuthorId(response?.data?.result?.user_id);
    }      
  } catch (error) {
    setNewsletterId(error?.response?.status)
    console.error("Error fetching newsletter data:", error);
    setIsFetchDataLoading(false)
  }
};

useEffect(() => {
  fetchData();
}, []);

const handleSend = async () => {
  if ((tempData?.tempData?.subject !== "") && (tempData?.tempData?.description !== "") && (tempData?.tempData?.categories?.length > 0) && (tempData?.status !== 'Send')) {
    const updatedData = { ...tempData?.tempData, status: "Send" };
    setIsLoading(true)
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/newsLetter/update/${newsletterid}`,
      updatedData
    )
    if (res?.status === 200) {
      fetchData();
      setIsLoading(false);

      window.location.href = `https://reactadminportal.codeptsolutions.com/users/${id}/newsletters/newsletter/${res?.data?.result?._id}?status=sent`;
    }
  }
  else {
    if(tempData?.status !== 'Send'){
      const errors = [];

      if (!tempData?.tempData?.subject) {
        errors.push("Title is required.");
      }
      if (!tempData?.tempData?.categories || tempData.tempData?.categories.length === 0) {
        errors.push("Category is required.");
      }
      if (!tempData?.tempData?.description) {
        errors.push("Description is required.");
      }
      if (errors.length > 0) {
        setErrorMessage(errors);
        setIsErrorMessage(true);
        return;
      }
    }
  }
}
  return (
    <>
      { isFetchDataLoading ?
        <div className="loader-container preview-loader">
          <div className="loader"></div>
        </div> 
        :   
        <>
          <div className="preview-newsletter">
            {
              (newsletterId !== 500 | (id === authorId)) ? 
              <>
                <div className="preview-newsletter__header">
                  <p>You're currently viewing a preview of your newsletter.</p>
                  <div className={`newpageRightButtons newpageRightButtonsPreview ${tempData?.status !== 'Draft' ? 'newpageRightButtonsSend' : ""}`} onClick={() => {
                    handleSend()
                  }}>
                    {
                      isLoading ? 
                      <div className="updatebutton-loader updatepreviewbutton-loader">
                        <div className="ring-loader"></div>
                      </div> : 
                      <button>{tempData?.status === 'Draft' ? <img src={send} alt="Send" /> : <img src={sendDark} alt="Send" />}Sent</button>
                    }
                  </div>
                  <div className="preview-edit">
                    <button onClick={() => { handleBack()}}>
                      Back to edit 
                    </button>
                  </div>
                </div>
                <table width="100%" bgcolor="#f4f4f4" style={{ margin: "0 auto", minWidth: "300px", fontSize: "16px", padding: "10px 0" }}>
                  <tbody>
                    <tr>
                      <td>
                        <table bgcolor="#ffffff" align="center" style={{ backgroundColor: "#ffffff", margin: "0 auto", maxWidth: "644px", width: "100%", marginTop: "77px" }}>
                          <tbody>
                            <tr>
                              <td style={{ width: "100%", maxWidth: "640px", margin: "0 auto", boxSizing: "border-box", }}>
                                <table style={{ margin: "0 auto", width: "100%", borderCollapse: "collapse", backgroundColor: "#ffffff", padding: "20px 0", boxSizing: "border-box", }}>
                                  <tbody>
                                    {
                                      featureImage &&
                                      <tr>
                                        <td style={{ padding: "0", textAlign: "center" }}>
                                          <img src={featureImage} alt="Feature Image" style={{ display: "block", width: "100%", maxWidth: "640px", height: "auto", border: "none", outline: "none", margin: "0 auto", paddingBottom: "10px", boxSizing: "border-box", }} />
                                        </td>
                                      </tr>
                                    }
                                    <tr>
                                      <td style={{paddingTop: "15px"}}>
                                        <h1 style={{ fontWeight: "bold", textAlign: "left", fontSize: "24px", lineHeight: "1.4", color: "#101828", margin: "0 0 20px 0", padding: "0 20px", }}>
                                          {tempData?.tempData?.subject}
                                        </h1>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="newsletter-content">
                                          {parse(tempData?.tempData?.postContent ? tempData?.tempData?.postContent : "")}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <table width="100%" style={{ borderTop: "1px solid #e9edf2" }}>
                                          <tbody>
                                            <tr>
                                              <td style={{ padding: "20px 10px 0", textAlign: "center", }}>
                                                <a href="http://www.persone.me">
                                                  <img className="footer-logo" width="20" height="20" src={logo} alt="Logo" style={{ display: "block", width: "20px", height: "auto", maxWidth: "100%", border: "none", outline: "none", paddingBottom: "10px", }}
                                                  />
                                                </a>
                                                <p style={{ fontFamily: "Arial, sans-serif", color: "#09090b", fontSize: "13px", lineHeight: "20px", margin: "0", }}>
                                                  © {new Date().getFullYear()} Persone.
                                                  All Rights Reserved <br />
                                                  David Alroy 5, Jerusalem, Israel.
                                                  <br />
                                                  View our{" "}
                                                  <a href="http://www.persone.comprivacy" target="_blank" rel="noopener noreferrer" style={{ color: "#09090b", textDecoration: "underline", }}>
                                                    Privacy Policy
                                                  </a>
                                                  .
                                                </p>
                                                {/* <p style={{ fontFamily: "Arial, sans-serif", color: "#4e575c", fontSize: "13px", lineHeight: "20px", margin: "0", }}>
                                                  You are receiving this email because you have subscribed to {displayName}.
                                                </p> */}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
              :
              <>
                <Error404 />
              </>
            }
          </div>
        </>
      }
    </>
  );
};

