import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Delete from "../../images/delete.svg";
import iconDropDown from "../../images/icondropdown.svg";
import search from "../../images/search.svg";
import plus from '../../images/plus.svg'
import fileImageIcon from '../../images/upload.svg'
import DeleteMediaModal from "./DeleteMediaModal";
import MediaUploadInput from "../../components/MediaUploadinput";
import GridLayout from "./GridLayout";
import ListLayout from "./ListLayout";
import { UpgradePopUp } from "../../components/modal/UpgradePopUp";
import Notification from "../../components/Notification";
import close from "../../images/close.svg"


const MediaGallery = (props) => {
    const userfileRef = useRef();
    const dateRef = useRef();
    const pulicationFileInputRef = useRef(null);
    const popupRef = useRef();
    const modelRef = useRef(null);
    const mediaRef = useRef()
    props.setActivePage("Media Library");

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userFileTypeSelect, setMediaTypeSelect] = useState(false);
    const [selectedMediaFileType, setSelectedMediaFileType] = useState([]);
    const [fileTypeList, setMediaFileTypeList] = useState([]);

    const excludedItemIds = [];

    const { id } = useParams();

    const [mediaItem, setMediaItem] = useState([]);
    const [readFields, setReadFields] = useState(true);

    const [selectedMeidaType, setSelectedMeidaType] = useState([]);
    const [mediaTypeSelection, setMediaTypeSelection] = useState(false);
    const [dateSelect, setDateSelect] = useState(false);
    const [selectedDateList, setSelectedDateList] = useState([]);

    const [dateList, setDateList] = useState([]);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [isDelLoading, setDelIsLoading] = useState(false);

    const [currentLayout, setCurrentLayout] = useState('grid');
    const [uploadNewmedia, setUploadNewmedia] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [validateImage, setValidateImage] = useState(false);
    const [validateFileImage, setValidateFileImage] = useState(false);
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [loadingFiles, setloadingFiles] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const [originalData, setOriginalData] = useState(mediaItem);

    const [fileList, setFileList] = useState({ '^image/': 'Images' });
    const [errorMessages, setErrorMessages] = useState([])
    const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
    const [openNotify, setOpenNotify] = useState(false);
    const [deleteNotify, setDeleteNotify] = useState(false)
    const [saveNotify, setSaveNotify] = useState(false);
    const [uploaditem, setUploadItem] = useState([])
    const [deteledItem, setDeletedItem] = useState([])


    const allowFIleType = {
        '^image/': 'Images',
        '^video/': 'Videos',
        'application/pdf': 'PDF',
        'application/doc': 'DOC',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
        'application/vnd.ms-powerpoint': 'PPT',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
        'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
    }


    const maxSizeInBytes = 128 * 1024 * 1024; // 128MB

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        fetchData(searchInput, selectedMeidaType.join(','), selectedDateList.join(','));
    }, [searchInput, selectedMeidaType, selectedDateList])

    const fetchData = async (search = '', type = '', date = '') => {
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/getMedia/${id}?search=${search}&type=${type}&date=${date}`,
            )
            setMediaItem(response.data.result)
            setDateList(response.data.monthYearList)

            setIsLoading(false)

        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }

    const handleDeleteButton = async () => {
        try {
            setDelIsLoading(true);
            setDeletedItem(selectedItems.length);
            await Promise.all(
                selectedItems?.map(async (selectedItem) => {
                    await axios.delete(`${process.env.REACT_APP_API_URL}/user/deleteMedia/${selectedItem}`);
                })
            );
            setSelectedItems([])
            const updatedUserMedia = mediaItem.filter(media => !selectedItems.includes(media._id));

            setDeleteNotify(true);
            setMediaItem(updatedUserMedia);
            setDelIsLoading(false);
            setDeleteConfirmation(false);
        } catch (error) {
            setDelIsLoading(false);
            console.log(error)
        }
    }


    useEffect(() => {
        var result = [];
        if (originalData !== undefined) {
            originalData.forEach(element => {
                if (result.indexOf(element.fileType) === -1) {
                    result.push(element.fileType);
                }
            });
            setMediaFileTypeList(result)
        } else {
            setMediaTypeSelect(false);
            setMediaFileTypeList([])
            setMediaItem(originalData);
        }
    }, [originalData])
    

    useEffect(() => {
        var result = [];
        var input = searchInput.toLowerCase();
        originalData.forEach((element) => {
            var fileName = element.fileName.toLowerCase();

            if (fileName.includes(input)) {
                result.push(element);
            }
        });

        if (result.length !== 0) {
            setMediaItem(result);
        } else if (input === "") {
            setMediaItem(originalData);
        } else {
            setMediaItem([]);
        }
    }, [searchInput]);

    useEffect(() => {
        var result = [];
        originalData.forEach((element) => {
            var pageType = element.fileType;
            if (selectedMediaFileType.includes(pageType)) {
                result.push(element);
            }
        });

        if (result.length !== 0) {
            setMediaItem(result);
        } else if (selectedMediaFileType.length === 0) {
            setMediaItem(originalData);
        } else {
            setMediaItem([]);
        }
    }, [selectedMediaFileType]);
    useEffect(() => {

        if (props?.billingDetails?.subscriptions?.plan !== 'free_plan') {
            setFileList({
                '^image/': 'Images',
                '^video/': 'Videos',
                'application/pdf': 'PDF',
                'application/doc': 'DOC',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
                'application/vnd.ms-powerpoint': 'PPT',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
                'application/vnd.ms-powerpoint.presentation.macroenabled.12': 'PPTM',
                'application/vnd.ms-excel': 'XLS',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
                'application/vnd.ms-excel.sheet.macroenabled.12': 'XLSM'
            })
        } else {
            setFileList({
                '^image/': 'Images',
            })
        }


    }, [props.billingDetails]);
    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = mediaItem.map((item) => item._id).filter((itemId) => !excludedItemIds.includes(itemId));
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const deleteMedia = (itemID) => {
        const idsToRemove = Array.isArray(itemID) ? itemID : [itemID];
        const updatedUsers = mediaItem.filter(
            (page) => !idsToRemove.includes(page._id)
        );
        setMediaItem(updatedUsers);
        togglePopup(null);
        setOpen(false);
        setSelectAll(false);
    };

    const onClose = (e) => {
        setOpen(false);
    };

    const handleDeleteMeida = () => {
        if (selectedItems && selectedItems.length > 0) {
            setDeleteConfirmation(true);
        }
    }

    const handleFileDragOver = (e) => {
        e.preventDefault();
        if (!isDragging) {
            setIsImageDragging(true);
        }
    }

    const handleFileDragLeave = (e) => {
        e.preventDefault();
        setIsImageDragging(false);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
            if (modelRef.current && !modelRef.current.contains(event.target)) {
                setShowUpgradeBtn(false);
                setErrorMessages([])
            }
            if(mediaRef.current && !mediaRef.current.contains(event.target)){
                setMediaTypeSelection(false)
            }
            if(dateRef.current && !dateRef.current.contains(event.target)){
                setDateSelect(false)
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleGalleryImageDrop = async (e) => {
        e.preventDefault();
        let newErrorMessages = [];
        setErrorMessages([]);
        const files = e.dataTransfer.files;

        let uploadedMediaId = 1;
        setShowUpgradeBtn(false);

        for (const file of files) {

            const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));

            // if (props?.billingDetails?.subscriptions?.plan === 'free_plan' && file.type)  {
            //     setPlanError(true);
            //     continue;
            // }

            if (!isAllowedFileType) {
                // console.log(`File type ${file.type} is not allowed.`);
                const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                newErrorMessages.push(errorMessage);

                if (Object.keys(allowFIleType).some((pattern) => new RegExp(pattern).test(file.type))) {
                    setShowUpgradeBtn(true);
                }
                continue; // Skip this file
            }

            if (file.size > maxSizeInBytes) {
                console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                continue; // Skip this file
            }
            setErrorMessages(newErrorMessages)

            const formData = new FormData();
            formData.append('files', file); // Append each file individually

            const uploadMedia = {
                _id: uploadedMediaId,
                name: file.name,
                description: file.name,
                alt: file.name,
                type: file.type,
                size: file.size,
                object_id: "",
                key: "",
                url: "",
                createdAt: "",
                isLoading: true
            }

            uploadedMediaId = uploadedMediaId + 1;

            setMediaItem((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                // Update the uploadMedia object with the response data
                setMediaItem((prevUserMedia) =>
                    prevUserMedia.map((media) =>
                        media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                    )
                );
            } catch (error) {
                console.log(error);
            }
        }
        setOpenNotify(true);
        setIsImageDragging(false);
        setErrorMessages(newErrorMessages);
    };

    const handlePublicationImageGalleryUpload = async (e) => {
        const files = e.target.files;
        setUploadItem(files.length)
        let newErrorMessages = [];
        setErrorMessages([]);
        if (files) {
            let uploadedMediaId = 1;
            setShowUpgradeBtn(false);
            for (const file of files) {

                const isAllowedFileType = Object.keys(fileList).some((pattern) => new RegExp(pattern).test(file.type));

                if (!isAllowedFileType) {
                    console.log(`File type ${file.type} is not allowed.`);
                    const errorMessage = `File type ${file.type} is not allowed. Failed to upload file ${file.name}.`;
                    newErrorMessages.push(errorMessage);

                    if (Object.keys(allowFIleType).some((pattern) => new RegExp(pattern).test(file.type))) {
                        setShowUpgradeBtn(true);
                    }
                    continue; // Skip this file
                }

                if (file.size > maxSizeInBytes) {
                    console.log(`File ${file.name} exceeds the maximum size of 128MB.`);
                    // const errorMessage = `File ${file.name} exceeds the maximum size of 128MB.`;
                    // newErrorMessages.push(errorMessage);
                    continue; // Skip this file
                }

                const formData = new FormData();
                formData.append('files', file); // Append each file individually

                const uploadMedia = {
                    _id: uploadedMediaId,
                    name: file.name,
                    description: '',
                    alt: '',
                    type: file.type,
                    size: file.size,
                    object_id: "",
                    key: "",
                    url: "",
                    createdAt: "",
                    isLoading: true
                }

                uploadedMediaId = uploadedMediaId + 1;

                setMediaItem((prevUserMedia) => [uploadMedia, ...prevUserMedia]);

                try {
                    const res = await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                        formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    );
                    // Update the uploadMedia object with the response data
                    setMediaItem((prevUserMedia) =>
                        prevUserMedia.map((media) =>
                            media._id === uploadMedia._id ? { ...media, ...res.data.result[0], isLoading: false } : media
                        )
                    );
                    setOpenNotify(true);                    
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            setloadingFiles(false);
            setValidateFileImage(true);
        }
        setErrorMessages(newErrorMessages);
    }


    return (
        <>
            <section className="dashboard mediaGallery">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pageMedia">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search"}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields}
                                    onFocus={() => setReadFields(false)}
                                    onBlur={() => setReadFields(true)}
                                />
                                <img src={search} alt="Search" />
                            </div>

                            <div ref={userfileRef} className="select-file dropdown-wrapp">
                                <button className="selectFields" onClick={(e) => setMediaTypeSelection(!mediaTypeSelection)}>
                                    All Files {" "}
                                    {selectedMeidaType.length > 0 ? `(${selectedMeidaType.length})` : ""}
                                    <img src={iconDropDown} alt="iconDropDown" className='selectFieldsIcon' />
                                </button>
                                {
                                    mediaTypeSelection === true &&
                                    <div className="dropdown addDropdown countryDropdown" ref={mediaRef}>
                                        <ul>
                                            {Object.entries(fileList).map(([key, value]) => (
                                                <li className="list-search" key={key} onClick={() => {
                                                    const result = selectedMeidaType.includes(key)
                                                        ? selectedMeidaType.filter(selectfile => selectfile !== key)
                                                        : [...selectedMeidaType, key];
                                                    setSelectedMeidaType(result);
                                                }}>
                                                    <input
                                                        className="fileSelect"
                                                        type="checkbox"
                                                        checked={selectedMeidaType.includes(key)}
                                                        onChange={() => {
                                                            const result = selectedMeidaType.includes(key)
                                                                ? selectedMeidaType.filter(selectfile => selectfile !== key)
                                                                : [...selectedMeidaType, key];
                                                            setSelectedMeidaType(result);
                                                        }}
                                                    />
                                                    <span className="file-name">{value}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className="mediadate-dropdown dropdown-wrapp">
                                <button className="selectFields" onClick={(e) => setDateSelect(!dateSelect)}>
                                    All Dates{" "}
                                    {selectedDateList.length > 0 ? `(${selectedDateList.length})` : ""}
                                    <img src={iconDropDown} alt="iconDropDown" />
                                </button>
                                {
                                    dateSelect === true &&
                                    <div className="dropdown addDropdown dateDropdown" ref={dateRef}>
                                        <ul>
                                            {dateList && dateList.length > 0 &&
                                                dateList.map((date, index) => (
                                                    <li className="list-search" key={index} onClick={() => {
                                                        const dates = selectedDateList.includes(date)
                                                            ? selectedDateList.filter(
                                                                (selectdate) =>
                                                                    selectdate !== date
                                                            )
                                                            : [...selectedDateList, date];
                                                        setSelectedDateList(dates);
                                                    }}>
                                                        <input className="dateSelect" type="checkbox" checked={selectedDateList.includes(date)} />
                                                        <span className="date-name">{date}</span>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>

                        </div>

                        <div className="pagesSearchIcon">
                            <div className="deleteIcon">
                                <div className='deleteIcon' onClick={handleDeleteMeida}>
                                    <button className='deleteTooltip'>Delete</button>
                                    <img className='deleteIconImage' src={Delete} alt="Clear Input" />
                                </div>
                            </div>
                            <div className={`pagesSearchlistIcon ${currentLayout === 'list' ? 'active' : ''}`} onClick={() => setCurrentLayout('list')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M18 9.99935L8 9.99935M18 4.99935L8 4.99935M18 14.9993L8 14.9993M4.66667 9.99935C4.66667 10.4596 4.29357 10.8327 3.83333 10.8327C3.3731 10.8327 3 10.4596 3 9.99935C3 9.53911 
                                    3.3731 9.16602 3.83333 9.16602C4.29357 9.16602 4.66667 9.53911 4.66667 9.99935ZM4.66667 4.99935C4.66667 5.45959 4.29357 5.83268 3.83333 5.83268C3.3731 
                                    5.83268 3 5.45959 3 4.99935C3 4.53911 3.3731 4.16602 3.83333 4.16602C4.29357 4.16602 4.66667 4.53911 4.66667 4.99935ZM4.66667 14.9993C4.66667 15.4596 4.29357 15.8327 3.83333 
                                    15.8327C3.3731 15.8327 3 15.4596 3 14.9993C3 14.5391 3.3731 14.166 3.83333 14.166C4.29357 14.166 4.66667 14.5391 4.66667 14.9993Z"
                                        stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className={`pagesSearchgridIcon ${currentLayout === 'grid' ? 'active' : ''}`} onClick={() => setCurrentLayout('grid')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M7.5 2.5H4.33333C3.86662 2.5 3.63327 2.5 3.45501 2.59083C3.29821 2.67072 3.17072 2.79821 3.09083 2.95501C3 3.13327 3 3.36662 3 3.83333V7C3 7.46671 3 7.70007 3.09083 7.87833C3.17072 8.03513 3.29821 8.16261 3.45501 8.24251C3.63327 8.33333 3.86662 8.33333 4.33333 8.33333H7.5C7.96671 8.33333 8.20007 8.33333 8.37833 8.24251C8.53513 8.16261 8.66261 8.03513 8.74251 7.87833C8.83333 7.70007 8.83333 7.46671 8.83333 7V3.83333C8.83333 3.36662 8.83333 3.13327 8.74251 2.95501C8.66261 2.79821 8.53513 2.67072 8.37833 2.59083C8.20007 2.5 7.96671 2.5 7.5 2.5Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.6667 2.5H13.5C13.0333 2.5 12.7999 2.5 12.6217 2.59083C12.4649 2.67072 12.3374 2.79821 12.2575 2.95501C12.1667 3.13327 12.1667 3.36662 12.1667 3.83333V7C12.1667 7.46671 12.1667 7.70007 12.2575 7.87833C12.3374 8.03513 12.4649 8.16261 12.6217 8.24251C12.7999 8.33333 13.0333 8.33333 13.5 8.33333H16.6667C17.1334 8.33333 17.3667 8.33333 17.545 8.24251C17.7018 8.16261 17.8293 8.03513 17.9092 7.87833C18 7.70007 18 7.46671 18 7V3.83333C18 3.36662 18 3.13327 17.9092 2.95501C17.8293 2.79821 17.7018 2.67072 17.545 2.59083C17.3667 2.5 17.1334 2.5 16.6667 2.5Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16.6667 11.6667H13.5C13.0333 11.6667 12.7999 11.6667 12.6217 11.7575C12.4649 11.8374 12.3374 11.9649 12.2575 12.1217C12.1667 12.2999 12.1667 12.5333 12.1667 13V16.1667C12.1667 16.6334 12.1667 16.8667 12.2575 17.045C12.3374 17.2018 12.4649 17.3293 12.6217 17.4092C12.7999 17.5 13.0333 17.5 13.5 17.5H16.6667C17.1334 17.5 17.3667 17.5 17.545 17.4092C17.7018 17.3293 17.8293 17.2018 17.9092 17.045C18 16.8667 18 16.6334 18 16.1667V13C18 12.5333 18 12.2999 17.9092 12.1217C17.8293 11.9649 17.7018 11.8374 17.545 11.7575C17.3667 11.6667 17.1334 11.6667 16.6667 11.6667Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.5 11.6667H4.33333C3.86662 11.6667 3.63327 11.6667 3.45501 11.7575C3.29821 11.8374 3.17072 11.9649 3.09083 12.1217C3 12.2999 3 12.5333 3 13V16.1667C3 16.6334 3 16.8667 3.09083 17.045C3.17072 17.2018 3.29821 17.3293 3.45501 17.4092C3.63327 17.5 3.86662 17.5 4.33333 17.5H7.5C7.96671 17.5 8.20007 17.5 8.37833 17.4092C8.53513 17.3293 8.66261 17.2018 8.74251 17.045C8.83333 16.8667 8.83333 16.6334 8.83333 16.1667V13C8.83333 12.5333 8.83333 12.2999 8.74251 12.1217C8.66261 11.9649 8.53513 11.8374 8.37833 11.7575C8.20007 11.6667 7.96671 11.6667 7.5 11.6667Z" stroke="#425466" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="pagesSearchbtn">
                                <button className="pagesbtn primary-button button" onClick={() => setUploadNewmedia(!uploadNewmedia)}>
                                    <img src={plus} alt="plus" className='plusIcon' />
                                    <span className='pagesbtnTitel'>New file</span>
                                </button>
                            </div>

                        </div>
                    </div>

                    {uploadNewmedia === true &&
                        <div>
                            <div className={`newpageLeftRow textBlock ulpoadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                                <div className={isImageDragging ? `draging fileUploadBox` : `fileUploadBox`}
                                    onDragOver={handleFileDragOver}
                                    onDragLeave={handleFileDragLeave}
                                    onDrop={handleGalleryImageDrop}
                                >
                                    <input
                                        type="file"
                                        ref={pulicationFileInputRef}
                                        id="pulication-file"
                                        multiple
                                        onChange={handlePublicationImageGalleryUpload}
                                        accept=".jpg, .png, .pdf"
                                        hidden
                                    />
                                    <div className="uploadFileBlock" onClick={() => document.getElementById("pulication-file").click()} >
                                        <div className="featureImgMiddleContent">
                                            <img className="fileUploadCover_Img" src={fileImageIcon} alt="FeaturedImage" />
                                            <p className="newPageImageUploadText">
                                                <span>Click to upload</span> or drag and drop{" "}
                                                <br />
                                                {(props?.billingDetails?.subscriptions?.plan === 'free_plan') ?
                                                <>
                                                    <b>Images: </b> JPG, PNG
                                                    <br />
                                                    (max. 128MB)
                                                </>
                                                :
                                                <>
                                                    <b>Images: </b> JPG, PNG, PDF
                                                    <br />
                                                    <b>Videos: </b> MP4, MOV, AVI, WMV, AVCHD, WebM, FLV
                                                    <br />
                                                    <b>Files: </b> PDF, DOCX, DOC, PPTX, PPT, PPTM, XLS, XLSX, XLSM
                                                    <br />
                                                    (max. 128MB)
                                                </>
                                            }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {validateImage === true && 
                                <p className="validateError">Some selected files have invalid type or size. Please choose valid images (jpg, png) or PDF files within 128MB.</p>
                            }
                            {validateFileImage === true &&
                                <p className="validateError">The field is not filled</p>
                            } */}
                            {errorMessages && errorMessages.length > 0 && (
                                <div className="errorMessages">
                                    {errorMessages?.map((errorMessage, index) => (
                                        <>
                                            <p key={index} className="error-message" >{errorMessage}</p>
                                        </>
                                    ))}
                                </div>
                            )}
                        </div>
                    }

                    <div>
                        {currentLayout === "grid" ?
                            (<GridLayout mediaItem={mediaItem} setMediaItem={setMediaItem} selectedItems={selectedItems} setSelectedItems={setSelectedItems} setUploadNewmedia={setUploadNewmedia} uploadNewmedia={uploadNewmedia} isLoading={isLoading} paymentMethods={props?.billingDetails?.subscriptions} setSaveNotify={setSaveNotify} />
                            ) : (
                                <ListLayout selectedItems={selectedItems} setSelectedItems={setSelectedItems} userMedia={mediaItem} setMediaItem={setMediaItem} setUploadNewmedia={setUploadNewmedia} uploadNewmedia={uploadNewmedia} isLoading={isLoading} paymentMethods={props?.billingDetails?.subscriptions} setSaveNotify={setSaveNotify} />
                            )}
                    </div>

                    {deleteConfirmation &&
                        <>
                            <div className="welcomeOverlay mediaModalOverlay media-dashboad-overlay"></div>
                            <div className='welcomeModal mediaDeleteModal'>
                                <div className='popupTopClose'>
                                    <img src={close} alt="close-icon" onClick={() => setDeleteConfirmation(false)} ></img>
                                </div>
                                <h3 className='popupModalTitle'>Delete Media</h3>
                                <p className='popupModalContent'>Are you sure you want to delete media? This action cannot be undone.</p>
                                <div className="popupModalBtn">
                                    <button className="cancel secondary-button button" type="button" onClick={() => setDeleteConfirmation(false)}>Cancel</button>
                                    <button className="savePicture primary-button button" type="button" onClick={() => { handleDeleteButton() }} >
                                        {isDelLoading ?
                                            <div className="settingPage-loader">
                                                <div className="ring-loader"></div>
                                            </div>
                                            : "Delete"
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </section>


            {showUpgradeBtn && 
                <UpgradePopUp setShowUpgradeBtn={setShowUpgradeBtn} refrence={popupRef} errorMessages={errorMessages} name="media"/>
            }
            <DeleteMediaModal
                isOpen={isOpen}
                item={openPopupitem}
                selectedItems={selectedItems}
                deleteMedia={deleteMedia}
                onClose={onClose}
            />
            { openNotify && 
                <Notification title={`${uploaditem} file has been successfully uploaded!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { deleteNotify && 
                <Notification title={`${deteledItem} files has been successfully deleted!`} setOpenNotify={setDeleteNotify} type={"success"} openNotify={deleteNotify} />
            }
            { saveNotify && 
                <Notification title={`The file has been successfully saved!`} setOpenNotify={setSaveNotify} type={"success"} openNotify={saveNotify} />
            }
        </>
    );
}
export default MediaGallery;