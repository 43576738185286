
import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';


const LogoSliderNodeView = (props) => {
    const { node } = props;
    const isFullDisplay = node.attrs.layout || "default";
    const images = node.attrs.imageUrls || false;
    const remainImg = Math.floor(20 / images.length);

    return (
        <NodeViewWrapper data-type="logoSlider" data-layout={node.attrs.layout} data-column={node.attrs.imageColumn} data-column-mobile={node.attrs.imageColumnMobile} data-slider={node.attrs.imageSlider}>
            <div className={`logoslider-wapper image-width-${isFullDisplay}`} >
            <div className='swiper-slider'>
                    <div className='swiper-slider-container'>
                        <div className='swiper-slider-list'>
                            <div className='swiper-items'>
                                {Array.from({ length: remainImg }).map((_, i) => (
                                    images.map((image, index) => (
                                        <div className='swiper-slide-item' key={index}>
                                            <img src={image} />
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                        <div className='swiper-slider-list'>
                            <div className='swiper-items'>
                                {Array.from({ length: remainImg }).map((_, i) => (
                                    images.map((image, index) => (
                                        <div className='swiper-slide-item' key={index}>
                                            <img src={image} />
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </NodeViewWrapper>
    );
};


export const LogoSliderBlock = Node.create({
    name: 'logoSliderBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            imageUrls: {
                default: [],
            },
            caption: {
                default: '',
            },
            layout: {
                default: 'default',
            },
            imageKeys: {
                default: []
            },
            imageId: {
                default: []
            },
            imageAlt: {
                default: []
            },
            imageName: {
                default: []
            }
            ,
            imageColumn: {
                default: 3
            },
            imageColumnMobile: {
                default: 2
            },
            imageSlider: {
                default: false
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
                getAttrs: (dom) => {
                    const images = Array.from(dom.querySelectorAll('img'));
                    const imageUrls = images.map(img => img.getAttribute('src'));
                    const imageKeys = images.map(img => img.getAttribute('data-key') || '');
                    const imageId = images.map(img => img.getAttribute('data-id') || '');
                    const imageAlt = images.map(img => img.getAttribute('data-alt') || '');
                    const imageName = images.map(img => img.getAttribute('data-name') || '');

                    return {
                        imageUrls: imageUrls,
                        caption: dom.getAttribute('data-caption') || '',
                        layout: dom.getAttribute('data-layout') || 'default',
                        imageKeys: imageKeys,
                        imageId: imageId,
                        imageAlt: imageAlt,
                        imageName: imageName,
                        imageColumn: Number(dom.getAttribute('data-column')) || 3,
                        imageColumnMobile: Number(dom.getAttribute('data-column-mobile')) || 2,
                        imageSlider: dom.getAttribute('data-slider') || false
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { 'data-type': this.name, 'data-layout': HTMLAttributes.layout, class: 'logo-slider-block' },
            [
                'div',
                { class: 'swiper-slider' },
                [
                    'div',
                    { class: 'swiper-slider-container' },
                    [
                        'div',
                        { class: 'swiper-slider-list' },
                        [
                            'div',
                            { class: 'swiper-items' },
                            ...HTMLAttributes?.imageUrls?.map((item, index) => (
                                [
                                    'div',
                                    { class: 'swiper-slide-item' },
                                    [
                                        'img',
                                        {
                                            src: item,
                                            alt: HTMLAttributes.imageAlt[index] || HTMLAttributes.imageName[index] || 'Image',
                                            'data-key': HTMLAttributes.imageKeys[index],
                                            'data-id': HTMLAttributes.imageId[index],

                                        },
                                    ],
                                ]
                            )),
                        ],
                    ],
                ]
            ],
        ];
    },   

    addCommands() {
        return {
            setLogoSliderBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                imageUrls: options?.imageUrls || [],
                                caption: options?.caption || '',
                                imageKeys: options?.imageKeys || '',
                                imageId: options?.imageId || '',
                                imageAlt: options?.imageAlt || '',
                                imageName: options?.imageName || '',
                                imageColumn: options?.imageColumn || 3,
                                imageColumnMobile: options?.imageColumnMobile || 2,
                                imageSlider: options?.imageSlider || false
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(LogoSliderNodeView);
    },
});
