import React from 'react'
import './previewHeader.css'
export const PreviewHeader = (props) => {
    return (
        <header className={`preview-header ${props?.theme}`}>
            <div className='header-inner'>
                <div className={`menu`}>

                    <div className='logo'>
                        <a id="site-link" href={props?.hasCustomDomain ? `/` : `/${props?.profile}/`}>
                            <img
                                src={props?.profilePicture}
                                alt={props?.displayName}
                                width={75}
                                height={75}
                                quality={100}
                                priority
                            />
                            <span>{props?.displayName}</span>
                        </a>
                    </div>

                    <div className="nav-wrapp">
                        <nav className='menu-inner'>
                            <ul className='level-1'>
                                <li>
                                    <div className="menu-item-wrapper ">
                                        <span><a href={props?.hasCustomDomain ? '/' : `/${props?.profile}`}>Home</a></span>
                                    </div>
                                </li>
                                <li>
                                    <div className="menu-item-wrapper ">
                                        <span><a href={props?.hasCustomDomain ? '/contact' : `/${props?.profile}/contact`}>Contact</a></span>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                        <div className="search-mobile mobile-view">
                            <a>
                                <div className="search-icon">
                                    <span className="search-line main-line"></span>
                                    <span className="search-circle"></span>
                                    <span className="search-line second-line"></span>
                                </div>
                            </a>
                        </div>

                        <button className="menu-hamburger">
                            <div className="menu-toggle">
                                <span className="hamburger"></span>
                            </div>
                        </button>

                        <div className="header-actions">
                            <div className={`search-transition search-input-closed`} ></div>
                            <div className="header-actions-overlay">
                                <a href="#" className="search-close header-close"></a>
                            </div>
                            <div className="header-top">
                                <button className={`btn-common uk-button btnCommon`} >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    )
}
