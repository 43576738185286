import { useEffect, useState } from 'react';
import axios from 'axios';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import UsersList from './UsersList';
import UsersDetail from './UsersDetail';

import "./UserModule.css";

const Users = () => {
    const userinfo = JSON.parse(localStorage.getItem("userData"));
    
        const navigate = useNavigate();
      const [userData, setUserData] = useState(userinfo);
      const [isContentChange, setContentChange] = useState(false)
      const [menuActive, setMenuActive] = useState(false);
      useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/getLoginTeamData/${userinfo?._id}/`);
                setUserData(response.data.result);
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData(); // Call the async function immediately
    }, [userinfo?._id]);

    const showConfirmationPopup = (url) => {
        const confirmLeave = window.confirm("Changes you made may not be saved. Are you sure you want to leave?");
        if (confirmLeave) {
            setContentChange(false)
            navigate(url);
        } 
    }

    return (
        <div className="main-wrapper">
            <Header activePage="users" userData={userData} isContentChange={isContentChange} showConfirmationPopup={showConfirmationPopup} menuActive={menuActive} setMenuActive={setMenuActive}/>
            <div className="main-content">
                <div className="content-wrapper">
                    <Routes basename="/users">
                        <Route path='/' element={<UsersList />} />
                        <Route path="/:id/*" element={<UsersDetail isContentChange={isContentChange} setContentChange={setContentChange} showConfirmationPopup={showConfirmationPopup}/>} ></Route>
                    </Routes>
                </div>
                <Footer  menuActive={menuActive}/>
            </div>
        </div>
    )
};

export default Users;
