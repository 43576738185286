import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import AddCategoryPage from "../Users/AddCategoryPage";
import DeletePageModal from "./DeletePageModel";

import HomePageContent from "./HomePageContent";
import ContactPageContent from "./ContactPageContent";
import Loader from "../../components/Loader/Loader";
import ImageEditor from "../../components/ImageEditor/ImageEditor";
import Error from "../error/Error404";
import Home from "../../images/icon-home.svg";
import iconDivider from "../../images/chevron-right-light.svg";
import send from "../../images/send.svg";
import plus from '../../images/plus-author.svg'
import link from "../../images/link.svg";
import linkExternal from "../../images/link-external.svg";
import coverImg from "../../images/picture.svg";
import featured from "../../images/featured.svg";
import iconDropDown from "../../images/icondropdown.svg";
import dots from "../../images/more.svg";
import search from "../../images/icon-search.svg";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import UploadMediaPopup from "./UploadMediaPopup";
// import SingleDeletePage from "../Components/DeleteModal/SingleDeletePage";

import {dataURLtoFile} from "../../utils/functions"
import NotificationMessage from "../../components/NotificationMessage";
import Notification from "../../components/Notification";
import { EditorComponent } from "../../components/TiptapEditor/Editor";
import PreviewSuccessPopUp from "../../components/modal/PreviewSuccessPopup";
function convertToSlug(str) {
    // Convert the string to lowercase
    let slug = str.toLowerCase();

    // Remove starting and ending spaces  
    slug = slug.trim();

    // Replace spaces with a single hyphen "-"
    slug = slug.replace(/\s+/g, '-').replace(/\.+/g, '-');

    return slug;
}

function EditPages({ setActivePage, profileSlug, setHideUserInfo, userData, setContentChange, isContentChange, showConfirmationPopup }) {
    setActivePage("Pages");
    setHideUserInfo(true);
    const fileInputRef = useRef(null);
    const { id, pageid } = useParams();

    const [canAccess, setCanAccess] = useState(true);
    const [isOpen, setOpen] = useState(false);
    const [featureImage, setFeatureImage] = useState(null);
    const [saveFeatureImage, setSaveFeatureImage] = useState(null);
    const [oldImage, setOldImage] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
    const [replaceImgTimeLoading, setReplaceImgTimeLoading] = useState(false);
    const [featuredImageModal, setFeaturedImageModal] = useState(false);
    const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
    const modalRef = useRef(null);
    const [zoom, setZoom] = useState(1);
    const [oldZoom, setOldZoom] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [isDragging, setIsDragging] = useState(false);
    const [isSlugChanged, setIslugChanged] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [status, setStatus] = useState("");
    const [dbPageTitle, setdbPageTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDraftLoading, setIsDraftLoading] = useState(false);
    const [validateTitle, setValidateTitle] = useState(false);
    const [validateEditorContent, setValidateEditorContent] = useState(false);
    const [validateImage, setValidateImage] = useState(false);
    const [apiCall, setApiCall] = useState(false);
    const [isHomepage, setIsHomepage] = useState(false);
    const [isContactpage, setIsContactpage] = useState(false);
    const [showPublication, setShowPublication] = useState(false);
    const [homepageData, setHomepageData] = useState({});
    const [contactpageData, setContactpageData] = useState({});
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [updatedContactpageData, setUpdatedContactpageData] = useState({
        pageText: '',
        publicAddress: '',
        publicEmail: '',
        showSocialNetwork: false,
        showContactForm: false,
        toReceiveEmail: '',
        phoneNumber: {
            dialCode: '91',
            countryCode: 'in',
            phoneNumber: '',
            value: ''
        },
        member_id: JSON.parse(localStorage.getItem("userData"))?._id
    });
    const [validationContactpageData, setValidationContactpageData] = useState({
        publicEmailValid: true,
        receiveEmailValid: true,
        showError: false
    });

    // Start Search
    const [values, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [showCategoryField, setShowCategoryField] = useState(false);
    const [searchCategory, setSearchCategory] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [selectedFeatureImage, setSelectedFeatureImage] = useState('');
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);
    const [selectedFeatureImageData, setSelectedFeatureImageData] = useState('');
    const [featureCropedImageId, setFeatureCropedImageId] = useState('');
    const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
    const [updateDraftNotify, setUpdateDraftNotify] = useState(false);
    const [categoriesNotify, setCategoriesNotify] = useState(false)
    const [updatedContent, setUpdatedContent] = useState("");
    const [isPreviewLoading, setPreviewLoading] = useState(false);
    const [featureImageId, setFeatureImageId] = useState('');
    const [openPagePopup, setOpenPagePopup] = useState(false);
    const [previewErrorMessage, setPreviewErrorMessage] = useState('');
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [popupData, setPopupData] = useState({url_address: '', pageImage: ''});
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const onClose = (e) => {
        setOpen(false);
    };

    const baseUrl = `https://jourmal-next-front.vercel.app/${profileSlug}/`;
    var notificationTimeoutId = '';

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const categoriesData = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/all/${id}`,
            );

            if (categoriesData.status == 200) {
                const categoriesArray = categoriesData.data.result.map(item => item.name);
                setCategories(categoriesArray);
            }

        } catch (error) {
            console.log(error, "error");
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                (!modalRef.current.contains(event.target) &&
                    featuredImageModal === false) ||
                event.target.className === "overlayModal"
            ) {
                setFeaturedImageModal(false);
                setZoom(oldZoom);
                setOldImage(newUploadedImage);
            }
        };

        if (featuredImageModal) {
            window.addEventListener("click", handleOutsideClick);
        }

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, [featuredImageModal]);

    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen3, setIsOpen3] = useState(true);
    const [isOpen4, setIsOpen4] = useState(true);

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown3 = () => {
        setIsOpen3(!isOpen3);
    };

    const toggleDropdown4 = () => {
        setIsOpen4(!isOpen4);
    };

    const [uploadedImage, setUploadedImage] = useState(null);
    const [newUploadedImage, setNewUploadedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [editorContent, setEditorContent] = useState("");

    const handleContentChange = (newContent) => {
        setUpdatedContent(newContent);
        setContentChange(true)
        setHasUnsavedChanges(true); 
        if (updatedContent?.trim().length > 0 || editorContent?.trim().length > 0) {
          setValidateEditorContent(false);
        } else {
          setValidateEditorContent(true);
        }
      };

    const handleImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    };

    const [url, setUrl] = useState("");

    const [allPublicationList, setAllPublicationList] = useState([]);

    const [origPublicationList, setOrigPublicationList] = useState([]);

    useEffect(() => {
        if (isHomepage) {
            setUrl("");
        }

        if (isContactpage) {
            setUrl("contact");
        }
    }, [isHomepage, isContactpage])

    useEffect(() => {
        setOrigPublicationList(allPublicationList);
    }, [allPublicationList]);

    const [publicationList, setPublicationList] = useState([]);

    const [selectPublication, setSelectPublication] = useState(false);

    const [publicationInput, setPublicationInput] = useState("");

    useEffect(() => {
        if (publicationInput.length === 0) {
            setOrigPublicationList(allPublicationList);
        } else {
            var result = [];
            var input = publicationInput.toLowerCase();
            origPublicationList.forEach((element) => {
                var title = element.title.toLowerCase();

                if (title) {
                    if (title.startsWith(input) === true) {
                        result.push(element);
                    }
                }
            });

            if (result != []) {
                setOrigPublicationList(result);
            } else {
                setOrigPublicationList([]);
            }
        }
    }, [publicationInput]);
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (selectPublication && ref.current && !ref.current.contains(e.target)) {
                setSelectPublication(false);
                setIsDraftOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [selectPublication]);

    const [openNotify, setOpenNotify] = useState(false);
    const [openNotifyDraft, setOpenNotifyDraft] = useState(false);


    const [published, setPublished] = useState(false);
    const [draft, setDraft] = useState(false);
    const [isDraftOpen, setIsDraftOpen] = useState(false);
    const [isPublishedOpen, setIsPublishedOpen] = useState(false);
    const [pageImage, setPageImage] = useState('');
    const [data, setData] = useState([]);

    const dotsRef = useRef(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (e.target != null && isPublishedOpen && (!e.target.classList.contains('publish-li') && !e.target.classList.contains('newpageMoreButtons'))) {
                setIsPublishedOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isPublishedOpen]);

    useEffect(() => {
        if (selectedFeatureImage !== '') {
            setBlobData();
        }

    }, [selectedFeatureImage]);

    const setBlobData = async () => {
        setSelectMediaLoading(true);
        try {
            const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                selectedFeatureImage,
                { responseType: 'blob' }
            );

            const imageUrl = URL.createObjectURL(imageData.data);

            setZoom(1);

            setUploadedImage(imageUrl);
            setImageFile(null);
            setOldImage(imageUrl);

            if (featureImage === null || featureImage === '') {
                setFeatureImage(imageUrl);
            }

            setloadingFeatureImg(false);
            setFeaturedImageModal(true);
            setReplcaeImageZoom(false);

            setPageImage(imageUrl);

            setSelectedFeatureImageData(selectedFeatureImage);

            setSelectMediaLoading(false);
            setShowUploadMediaPopup(false);
            setUploadMediaPopupFor('');

        } catch (error) {
            setSelectMediaLoading(false);
            console.log({ error: error, message: error.message })
        }
        setSelectMediaLoading(false);
    }
    const handleClick = () => {
        setFeaturedImageModal(!featuredImageModal);
        // setFeatureImage(saveFeatureImage);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        var divElement = document.getElementById("newpageImageUpload");

        var extraHeight = (featureImage) ? 2 : 0;

        divElement.style.height = (divElement.clientHeight + extraHeight) + "px";
        divElement.style.minHeight = (divElement.clientHeight + extraHeight) + "px";

        divElement.classList.remove("imageUploaded");
        setloadingFeatureImg(true);

        var TempReplcaeImageZoom = replcaeImageZoom
        var TempReplaceImgTimeLoading = replaceImgTimeLoading

        if (featureImage) {
            setReplcaeImageZoom(true);
            setReplaceImgTimeLoading(false);

            TempReplcaeImageZoom = true;
            TempReplaceImgTimeLoading = false;
        }

        if (TempReplcaeImageZoom === true) {
            setOldZoom(1);
            setZoom(1);
        } else {
            setZoom(oldZoom);
        }

        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                if (file) {
                    setUploadedImage(file);
                    setImageFile(file);
                    setPageImage(file);
                    TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
                    setOldImage(reader.result);
                }
            };
        };
        if (file) {
            setPageImage(file);
            setTimeout(() => {
                reader.readAsDataURL(file);
                setDragging(false);
                setUploadedImage(file);
                setImageFile(file);
                setloadingFeatureImg(false);
                var divElement = document.getElementById("newpageImageUpload");
                divElement.classList.add("imageUploaded");
                divElement.classList.add("imageUploaded-wrap");
                setOldImage(file);
                setFeaturedImageModal(true);
                setReplcaeImageZoom(false);
                setReplaceImgTimeLoading(false);

                // divElement.style.height = 'auto';
                // divElement.style.minHeight = 'unset';
            }, 4000);
        } else {
            setloadingFeatureImg(false);

            // divElement.style.height = 'auto';
            // divElement.style.minHeight = 'unset';
        }
        setIsDragging(false);
    };

    const handleFileUpload = (e) => {
        var divElement = document.getElementById("newpageImageUpload");

        var extraHeight = (featureImage) ? 2 : 0;

        divElement.style.height = (divElement.clientHeight + extraHeight) + "px";
        divElement.style.minHeight = (divElement.clientHeight + extraHeight) + "px";

        divElement.classList.remove("imageUploaded");
        setloadingFeatureImg(true);
        if (replcaeImageZoom === true) {
            setOldZoom(1);
            setZoom(1);
        } else {
            setZoom(oldZoom);
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                replcaeImageZoom !== true && setFeatureImage(reader.result);
                setOldImage(reader.result);
            };
        };
        if (file) {
            reader.readAsDataURL(file);

            setUploadedImage(null);
            setImageFile(null);
            setPageImage(file);

            setTimeout(() => {
                reader.readAsDataURL(file);
                setDragging(false);
                setloadingFeatureImg(false);
                setUploadedImage(file);
                setImageFile(file);
                var divElement = document.getElementById("newpageImageUpload");
                divElement.classList.add("imageUploaded");
                divElement.classList.add("imageUploaded-wrap");
                setFeaturedImageModal(true);
                setReplcaeImageZoom(false);

                // divElement.style.height = 'auto';
                // divElement.style.minHeight = 'unset';
            }, 4000);
        } else {
            setloadingFeatureImg(false);
            // divElement.style.height = 'auto';
            // divElement.style.minHeight = 'unset';
        }
    };

    const handleImageLoad = () => {
        const img = new Image();
        img.src = featureImage;
        img.onload = () => { };
    };

    const handleReplaceImg = () => {
        // document.getElementById("feature-img").click();
        setShowUploadMediaPopup(true);
        setUploadMediaPopupFor('feature-image');
        setFeaturedImageModal(false);
        setReplcaeImageZoom(true);
        setReplaceImgTimeLoading(false);
    };

    useEffect(() => {
        setZoom(zoom);
    }, [zoom]);

    const handleZoom = (zoom) => {
        setZoom(zoom);
    };

    const handlePosition = (newPosition) => {
        setPosition(newPosition);
    };

    const handleSavePicture = async () => {
        let click = document.getElementById("clickFunction");
        click.click();
        setFeatureImage(featureImage);
        setNewUploadedImage(oldImage);
        setSaveFeatureImage(featureImage);
        setFeaturedImageModal(false);
        setOldZoom(zoom);
        setContentChange(true)
        setHasUnsavedChanges(true); 
    };

    const handleMouseDown = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleMouseUp = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleTouchStart = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleTouchEnd = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleCancel = () => {
        if (!saveFeatureImage) {
            var divElement = document.getElementById("newpageImageUpload");
            featureImage && divElement.classList.remove("imageUploaded");
        } else {
            var divElement = document.getElementById("newpageImageUpload");
            featureImage && divElement.classList.add("imageUploaded");
        }
        setOldImage(newUploadedImage);
        setFeaturedImageModal(false);
        setZoom(oldZoom);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            setUploadedImage(null);
        }
    };

    const changeSlug = (value) => {
        // setPageTitle(value);
        if (!isSlugChanged) {
            let urlValue = convertToSlug(value);
            setUrl(urlValue);
        }
    }

    const changeTitle = (e) => {
        //Stop enter button

        if (e.key === 'Enter') {
            e.preventDefault();
            return true;
        }

    }

    const changesTitles = (e) => {
        const text = e.target.innerText.replace(/<[^>]+>/g, '');

        if (text.length <= 120 || e.key === 'Backspace') {
            // setPageTitle(text)
            changeSlug(text);
        } else {
            e.preventDefault();
            return true;
        }
    }

    const handleBlur = (e) => {
        const text = e.target.innerText.replace(/<[^>]+>/g, '');
        setdbPageTitle(text)
    }

    const handlePaste = (e) => {
        e.preventDefault();

        // Get the plain text content from clipboard
        const clipboardData = e.clipboardData.getData('text/plain');

        // Paste the plain text into the contenteditable div
        document.execCommand('insertText', false, clipboardData);
        setContentChange(true)
        setHasUnsavedChanges(true);
    }
    const [authorId, setauthorId] = useState(id);
    const [newPageID, setNewPageID] = useState(pageid);

    useEffect(() => {
        const user_id = id
        const homagepage_id = btoa(`homepage-${user_id}`);
        const contactpage_id = btoa(`contactpage-${user_id}`);
        if (homagepage_id === pageid) {
            setIsHomepage(true);
            setPublished(true);
            setStatus('Published')
        }

        if (contactpage_id === pageid) {
            setIsContactpage(true);
            setPublished(true);
            setStatus('Published')
        }

        setNewPageID(pageid);
    }, [pageid]);

    document.getElementsByClassName("page-title").value = "Johnny Bravo";

    const singlePageList = async () => {
        if (isHomepage) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/getHomepage/${id}`);

            if (res) {
                let homepagedata = res?.data?.result?.homePageData;
                setHomepageData(homepagedata);
                setCanAccess(true);

                setEditorContent(homepagedata.summary);
                setUpdatedContent(homepagedata.summary)                

                if (homepagedata?.featured_image?.selectedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                      res?.data?.homepageMedia?.selectedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);
                  setUploadedImage(imageUrl);
                  setImageFile(null);
                  setNewUploadedImage(imageUrl);
                  setOldImage(imageUrl);
                  setFeatureImageId(homepagedata?.featured_image?.selectedImage);
                }

                if (homepagedata?.featured_image?.cropedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                      res?.data?.homepageMedia?.cropedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setFeatureImage(imageUrl);
                  setSaveFeatureImage(imageUrl);
                  setFeatureCropedImageId(homepagedata?.featured_image?.cropedImage);

                  setPageImage(imageUrl);
                  setPopupData(prevState => ({
                    ...prevState,
                    url_address: '',
                    pageImage: imageUrl
                  }));
                }
                if(homepagedata?.featured_image?.zoom) {
                  setZoom(homepagedata.featured_image.zoom)
                  setOldZoom(homepagedata.featured_image.zoom)
                }        
                if(homepagedata?.featured_image?.position) {
                  setPosition(homepagedata.featured_image.position)
                }
            }
        } else if (isContactpage) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/getContactpage/${id}`);

            if (res) {
                let contactpagedata = res?.data?.result;
                setContactpageData(contactpagedata);
                setCanAccess(true);

                if (contactpagedata?.userMeta?.featured_image?.selectedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                      contactpagedata.constactMedia.selectedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setUploadedImage(imageUrl);
                  setImageFile(null);
                  setNewUploadedImage(imageUrl);
                  setOldImage(imageUrl);
                  setFeatureImageId(contactpagedata.userMeta.featured_image.selectedImage);
                }
                if (contactpagedata?.userMeta?.featured_image?.cropedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                      contactpagedata.constactMedia.cropedMedia,
                      { responseType: 'blob' }
                  );

                  const imageUrl = URL.createObjectURL(imageData.data);

                  setFeatureImage(imageUrl);
                  setSaveFeatureImage(imageUrl);
                  setFeatureCropedImageId(contactpagedata.userMeta.featured_image.cropedImage);
                  setPopupData(prevState => ({
                    ...prevState,
                    url_address: 'contact',
                    pageImage: imageUrl
                  }));
                  setPageImage(imageUrl);
                }                
                if(contactpagedata?.userMeta?.featured_image?.zoom) {
                  setZoom(contactpagedata.userMeta.featured_image.zoom)
                  setOldZoom(contactpagedata.userMeta.featured_image.zoom)
                }        
                if(contactpagedata?.userMeta?.featured_image?.position) {
                  setPosition(contactpagedata.userMeta.featured_image.position)
                }
            }
        } else {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/pages/${pageid}?userId=${id}`);

            if (res) {
                const pageData = res.data.result;
                setdbPageTitle(pageData.title);
                setStatus(pageData.status);
                setEditorContent(pageData.description);
                setUpdatedContent(pageData.description);
                setUrl(pageData.url_address);
                setData(pageData);
                setPopupData(prevState => ({
                    ...prevState,
                    url_address: pageData.url_address,
                    pageImage: res?.data?.result?.featureImageUrl
                  }));
                if (pageData.status === "Published") {
                    setPublished(true);
                }
                if (pageData.status === "Draft") {
                    setDraft(true);
                }
  
                let publicationListData = [];
                let selectedCat = [];
                pageData.categories?.forEach((item) => {
                    publicationListData.push({ title: item });
                    selectedCat.push(item);
                });
                
                if (pageData?.featured_image?.selectedImage) {
                    const imageData = await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                        pageData.featureImageData.selectedMedia,
                        { responseType: 'blob' }
                    );
  
                    const imageUrl = URL.createObjectURL(imageData.data);
  
                    setUploadedImage(imageUrl);
                    setImageFile(null);
                    setNewUploadedImage(imageUrl);
                    setOldImage(imageUrl);
                    setFeatureImageId(pageData.featured_image.selectedImage);
                }
                if (pageData?.featured_image?.cropedImage) {
                  const imageData = await axios.post(
                      `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                      pageData.featureImageData.cropedMedia,
                      { responseType: 'blob' }
                  );
  
                  const imageUrl = URL.createObjectURL(imageData.data);
                  setFeatureImage(imageUrl);
                  setSaveFeatureImage(imageUrl);
                  setFeatureCropedImageId(pageData.featured_image.cropedImage);
  
                  setPageImage(imageUrl);
                  
                }
                if(pageData?.featured_image?.zoom) {
                  setZoom(pageData.featured_image.zoom)
                  setOldZoom(pageData.featured_image.zoom)
                }
        
                if(pageData?.featured_image?.position) {
                  setPosition(pageData.featured_image.position)
                }
  
                setPublicationList(publicationListData);
                setSelectedCategories(selectedCat);
                setCanAccess(true);
            } else {
                  setCanAccess(false);
            }
        }
    }

    useEffect(() => {
        if (pageid) {
            singlePageList()
        } else {
            setEditorContent('')
            setUpdatedContent('')
            setdbPageTitle('')
            setUrl('')
            setPublicationList([])
            setIsLoading(false)
            setFeatureImage('')
            setDraft(false)
            setPublished(false)
            setIsDraftLoading(false)
            setSaveFeatureImage(null)

            setSelectedCategories([])
        }

    }, [pageid])

    const location = useLocation()
    const [page, setPages] = useState(false)
    const queryParams = new URLSearchParams(location.search);
    const statusValue = queryParams.get("status");
  useEffect(() => {
      if(statusValue === "published"){
          setOpenPagePopup(true)
      }
    },[statusValue])
    useEffect(() => {
        setPages(!page)
    }, [location])

    useEffect(() => {

        if (pageid) {
            singlePageList()
        } else {
            setEditorContent('')
            setdbPageTitle('')
            setUrl('')
            setPublicationList([])
            setIsLoading(false)
            setDraft(false)
            setPublished(false)
            setPageImage('')
            setIsDraftLoading(false)
            setSaveFeatureImage(null)
            setOrigPublicationList(allPublicationList);
        }
        setValidateEditorContent(false);
        setValidateImage(false);
        setOpenNotifyDraft(false);
        setOpenNotify(false);

        clearTimeout(notificationTimeoutId)

    }, [page]); // Specify the dependencies that trigger the useEffect


    const handleChangeToDraft = async () => {
        setIsLoading(true);
        if (published) {

            let selectedImage = featureImageId;
            let featureCropedImage = featureCropedImageId;
      
            const featureImageData = await getFeatureImageData(newPageID)
      
            selectedImage = featureImageData?.selectedImage || selectedImage;
            featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
            const newPageData = {
                description: updatedContent || editorContent,
                title: dbPageTitle,
                author_id: authorId,
                url_address: url,
                categories: selectedCategories,
                status: "Draft",
                previous_status: "Draft",
                featured_image: {
                  selectedImage: selectedImage,
                  cropedImage: featureCropedImage,
                  position: position,
                  zoom: zoom
                },
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
            }

            await axios.post(
                `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
                newPageData
            ).then((res) => {
                setIsLoading(false)
            })
                .catch((err) => {
                    setIsLoading(false)
                })
                setContentChange(false)
                setHasUnsavedChanges(false);
            setOpenNotifyDraft(true);
            //close the popup

            clearTimeout(notificationTimeoutId)
            notificationTimeoutId = setTimeout(() => {
                setOpenNotifyDraft(false)
            }, 15000);
            setPublished(false);
            setDraft(true);
            setIsPublishedOpen(false);
            setIsDraftOpen(false);
            setStatus('Draft');
        }
    };

    const handleChangeToPublish = async () => {

        setIsLoading(true);
        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;

        const featureImageData = await getFeatureImageData(newPageID)

        selectedImage = featureImageData?.selectedImage || selectedImage;
        featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
        const newPageData = {
            description: updatedContent || editorContent,
            title: dbPageTitle,
            author_id: authorId,
            url_address: url,
            categories: selectedCategories,
            status: 'Published',
            previous_status: 'Published',
            featured_image: {
              selectedImage: selectedImage,
              cropedImage: featureCropedImage,
              position: position,
              zoom: zoom
            },
            member_id: JSON.parse(localStorage.getItem("userData"))?._id
        }

        await axios.post(
            `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
            newPageData
        ).then((res) => {
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
            })
            setContentChange(false)
            setHasUnsavedChanges(false);
        setPublished(true);
        setDraft(false);
        setOpenNotify(true)
        setIsPublishedOpen(false);
        setIsDraftOpen(false);
        clearTimeout(notificationTimeoutId)
        notificationTimeoutId = setTimeout(() => {
            setOpenNotify(false)
        }, 15000);
        setStatus('Published');

    };

    const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);

    const handelSingleDelete = () => {
        setOpen(true);
        setIsDraftOpen(false);
        setIsPublishedOpen(false);
        document.body.classList.add('hidden-message');
    }

    const closeAccountDeletedPopup = () => {
        setIsPopupOpenDeleteAccount(false);
        document.body.classList.remove('hidden-message');
    }

    const closeCategoryField = (action) => {

        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');

        setTimeout(() => {
            scrollToBottom();
        }, 100);
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (e.target != null && isPopupOpenDeleteAccount && (e.target.classList.contains('popupModalOverlay'))) {
                setIsPopupOpenDeleteAccount(false);
            }

            if (e.target != null && isDraftOpen && !(e.target.classList.contains('publishedButtonsList')) && !(e.target.classList.contains('publish-li'))) {
                setIsDraftOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isPopupOpenDeleteAccount, isDraftOpen]);

    const handleDraft = async () => {

        if (apiCall === false) {
            draftValidateFields();
            setIsLoading(true)
            setApiCall(true)

            let featureCropedImage = featureCropedImageId;
            let selectedImage = featureImageId;
            const featureImageData = await getFeatureImageData(newPageID)

            selectedImage = featureImageData?.selectedImage || selectedImage;
            featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

            
            const newPageData = {
                description: updatedContent || editorContent,
                title: dbPageTitle,
                author_id: authorId,
                url_address: url,
                categories: selectedCategories,
                status: "Draft",
                previous_status: "Draft",
                featured_image: {
                  selectedImage: selectedImage,
                  cropedImage: featureCropedImage,
                  position: position,
                  zoom: zoom
                },
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
            }

            await axios.post(
                `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
                newPageData
            )
                .then((res) => {
                    setIsLoading(false)
                    setUpdateDraftNotify(true);

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifyDraft(false)
                    }, 15000);

                    if (isLoading === false) {
                        setApiCall(false)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    if (isLoading === false) {
                        setApiCall(false)
                    }
                })
                setContentChange(false)
                setHasUnsavedChanges(false);
        }
    }

    const handlePublish = async () => {

        if (apiCall === false) {
            setIsLoading(true)
            setApiCall(true)

            let selectedImage = featureImageId;
            let featureCropedImage = featureCropedImageId;

            const featureImageData = await getFeatureImageData(newPageID)

            selectedImage = featureImageData?.selectedImage || selectedImage;
            featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
            
            const newPageData = {
                description: updatedContent || editorContent,
                title: dbPageTitle,
                author_id: authorId,
                url_address: url,
                categories: selectedCategories,
                status: 'Published',
                previous_status: 'Published',
                featured_image: {
                  selectedImage: selectedImage,
                  cropedImage: featureCropedImage,
                  position: position,
                  zoom: zoom
                },
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
            }

            if (isHomepage) {
                const homepageData = {
                    summary: updatedContent || editorContent,
                    showPublication: showPublication,
                    featured_image: {
                      selectedImage: selectedImage,
                      cropedImage: featureCropedImage,
                      position: position,
                      zoom: zoom
                    },
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id
                };
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateHomepage/${id}`,
                    homepageData
                ).then((res) => {
                    setOpenNotify(true)
                    setIsLoading(false)

                    if (isLoading === false) {
                        setApiCall(false)
                    }

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotify(false)
                    }, 15000);
                })
                    .catch((error) => {
                        if (isLoading === false) {
                            setApiCall(false)
                        }
                        setIsLoading(false)
                    });
                    setContentChange(false)
                    setHasUnsavedChanges(false);
            }

            if (isContactpage) {

                const contactpageData = {
                    ...updatedContactpageData,
                    featured_image: {
                      selectedImage: selectedImage,
                      cropedImage: featureCropedImage,
                      position: position,
                      zoom: zoom
                    }
                }

                await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/updateContactpage/${id}`,
                    contactpageData
                ).then((res) => {
                    setOpenNotify(true)
                    setIsLoading(false)

                    if (isLoading === false) {
                        setApiCall(false)
                    }

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotify(false)
                    }, 15000);
                })
                    .catch((error) => {
                        if (isLoading === false) {
                            setApiCall(false)
                        }
                        setIsLoading(false)
                    });
                    setContentChange(false)
                    setHasUnsavedChanges(false);
            }

            if (!isHomepage && !isContactpage) {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/pages/updatepage/${newPageID}`,
                    newPageData
                )
                    .then((res) => {
                        setOpenNotify(true)
                        setIsLoading(false)

                        setStatus('Published');

                        if (isLoading === false) {
                            setApiCall(false)
                        }

                        clearTimeout(notificationTimeoutId)
                        notificationTimeoutId = setTimeout(() => {
                            setOpenNotify(false)
                        }, 15000);
                    })
                    .catch((error) => {
                        if (isLoading === false) {
                            setApiCall(false)
                        }
                        setIsLoading(false)
                    })
                    setContentChange(false)
                    setHasUnsavedChanges(false);
            }

        }
    }

    const getFeatureImageData = async (pageID) => {
        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;
        
        if( imageFile !== null ) {
      
              const imageFormData = new FormData();
              imageFormData.append('files', imageFile); // Append each file individually
              try {
                const res = await axios.post(
                  `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                  imageFormData, {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  }
                );          
                selectedImage = res.data.result[0]._id;
        
                const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");
                const formData = new FormData();
                formData.append('files', featureImageFile);
                formData.append('id', pageID);
                const uploadedMedia = await axios.post(
                  `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
                  formData, {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  }
                );
      
                featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
      
              } catch (error) {
                console.log(error);
              }
            //   (featureImageId !== '' || selectedFeatureImageData?._id) && oldImage !== saveFeatureImage 
          } else if((selectedFeatureImageData?._id) || oldImage !== saveFeatureImage) {        
              const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");
      
              const formData = new FormData();
              formData.append('files', featureImageFile);
              formData.append('id', pageID);
      
              const uploadedMedia = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/uploadMedia/${JSON.parse(localStorage.getItem("userinfo"))?._id}`,
            formData, {
              headers: {
                        'Content-Type': 'multipart/form-data'
                    }
            }
              );
      
              selectedImage = selectedFeatureImageData?._id || selectedImage;
              featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
          }          
        const result = {selectedImage, featureCropedImage}
          
        return result;
    }


    const handleChangeURL = (pageID) => {
        const newUrl = `/users/${id}/pages/page/${pageID}`;  // Replace with the URL you want

        // Change the URL without reloading the page
        window.history.pushState({}, '', newUrl);
    };

    const addData = async (isDraft, status) => {
        if(newPageID){

            let selectedImage = featureImageId;
            let featureCropedImage = featureCropedImageId;        
            const featureImageData = await getFeatureImageData(newPageID)

            selectedImage = featureImageData?.selectedImage || selectedImage;
            featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
            try {
                const newPageData = {
                    description: updatedContent || editorContent,
                    title: dbPageTitle,
                    author_id: authorId,
                    url_address: url,
                    categories: selectedCategories,
                    status: status,
                    previous_status: status,
                    featured_image: {
                      selectedImage: selectedImage,
                      cropedImage: featureCropedImage,
                      position: position,
                      zoom: zoom
                    },
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id
                }

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/pages/addpage`,
                    newPageData
                )


                if (response.status = 201) {
                    handleChangeURL(response.data.result._id)
                    setNewPageID(response.data.result._id)
                    setData(response.data.result);
                    setContentChange(false)
                    setHasUnsavedChanges(false);
                }

                setIsLoading(false);
                if (status === "Draft") {
                    setOpenNotifyDraft(true)

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifyDraft(false)
                    }, 15000);
                }
                if (status === "Published") {
                    setOpenNotify(true)

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotify(false)
                    }, 15000);
                }
                setIsDraftLoading(false);

            } catch (error) {
                setIsDraftLoading(false);
                setIsLoading(false);
            }
        } else {
            try {
                const newPageData = {
                    description: updatedContent || editorContent,
                    title: dbPageTitle,
                    author_id: authorId,
                    url_address: url,
                    categories: selectedCategories,
                    status: status,
                    previous_status: status,
                }
            const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/pages/addpage`,
            newPageData
            );
      
            if (response.status = 201) {
                const _pageId = response.data.result._id;
                handleChangeURL(_pageId);
                setNewPageID(_pageId);
                
                let selectedImage = featureImageId;
                let featureCropedImage = featureCropedImageId;
        
                const featureImageData = await getFeatureImageData(_pageId)
        
                selectedImage = featureImageData?.selectedImage || selectedImage;
                featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
        
                const updatedPageData = { ...newPageData, 
                    featured_image: {
                        selectedImage: selectedImage,
                        cropedImage: featureCropedImage,
                        position: position,
                        zoom: zoom
                }};
        
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/pages/updatepage/${_pageId}`,
                    updatedPageData
                )
                }
                setContentChange(false)
                setHasUnsavedChanges(false);
              setIsLoading(false);
              if (status === "Draft") {
                setOpenNotifyDraft(true)
                
                clearTimeout(notificationTimeoutId) 
                notificationTimeoutId = setTimeout(() => {
                  setOpenNotifyDraft(false)
                }, 15000);
              }
              if (status === "Published") {
                setOpenNotify(true)
      
                clearTimeout(notificationTimeoutId) 
                notificationTimeoutId = setTimeout(() => {
                  setOpenNotify(false)
                }, 15000);
              }
              setIsDraftLoading(false);
      
            } catch (error) {
              setIsDraftLoading(false);
              setIsLoading(false);
            }
        }
    }

    const validateFields = () => {
        if (pageTitle.length > 0) {
          setValidateTitle(false);
        } else {
          setValidateTitle(true);
        }
        if (dbPageTitle.length > 0) {
          setValidateTitle(false);
        } else {
          setValidateTitle(true);
        }
        if ((updatedContent?.trim().length > 0 || editorContent?.trim().length > 0)) {
          setValidateEditorContent(false);
        } else {
          setValidateEditorContent(true);
        }
        if (pageImage === '') {
          setValidateImage(true);
        } else {
          setValidateImage(false);
        }
      }
    
      const draftValidateFields = () => {
        if (pageTitle.length > 0) {
          setValidateTitle(false);
        } else {
          setValidateTitle(true);
        }
        if (dbPageTitle.length > 0) {
          setValidateTitle(false);
        } else {
          setValidateTitle(true);
        }
        setPreviewLoading(false)
      }

    useEffect(() => {
        // Use the effect to clear the error message after 2 seconds
        const timer = setTimeout(() => {
            setErrorMessage('');
        }, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [errorMessage]);


    const handleCheckboxChange = (value) => {
        setContentChange(true)
        setHasUnsavedChanges(true);
        if (selectedCategories.includes(value)) {
            setSelectedCategories(selectedCategories.filter((v) => v !== value));
        } else {
            setSelectedCategories([...selectedCategories, value]);
        }
    };

    const filteredValues = values.filter((value) =>
        value.toLowerCase().includes(searchCategory.toLowerCase())
    );

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            setSearchCategory(inputValue);
            setInputValue('');
        }
    };

    const handleNewCategoryClick = () => {
        setShowCategoryField(true);
        document.body.classList.add('hidden-message');
    };

    // Function to scroll the ul to the bottom
    const scrollToBottom = () => {
        var categoriesList = document.querySelector('.categories-list');
        var liElements = categoriesList.querySelectorAll('li');

        let totalHeight = ((parseInt(liElements.length) + 1) * 22) + 5;

        categoriesList.scrollTop = totalHeight;
    };

    // End Search
    const showPreview = async () => {
        let canShowPreview = false;

        if(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '' ){
            canShowPreview = true;
        }

        if( ! canShowPreview ) {
            setPreviewLoading(false)
            return false;
        }
        let _pageId;
        let sendStatus;
        setPreviewLoading(true)
        if ((!newPageID) || (newPageID === undefined)) {
            setPreviewLoading(true)
            draftValidateFields();
            if((dbPageTitle.length > 0)){
            setPreviewLoading(true)
            let selectedImage = featureImageId;
            let featureCropedImage = featureCropedImageId;
    
            const featureImageData = await getFeatureImageData(newPageID)
   
            selectedImage = featureImageData?.selectedImage || selectedImage;
            featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
            const newPageData = {
                description: updatedContent || editorContent,
                title: dbPageTitle,
                author_id: authorId,
                url_address: url,
                categories: selectedCategories,
                status: 'Draft',
                previous_status: status,
                featured_image: {
                    selectedImage: selectedImage,
                    cropedImage: featureCropedImage,
                    position: position,
                    zoom: zoom
                },
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/pages/addpage`,
                newPageData
            );
    
            if (response.status === 200) {
                _pageId = response.data.result._id;
                console.log({pageid:_pageId})
                sendStatus = response.data.result.status;
                handleChangeURL(_pageId);
                setNewPageID(_pageId);
        
                let selectedImage = featureImageId;
                let featureCropedImage = featureCropedImageId;
        
                const featureImageData = await getFeatureImageData(_pageId)
        
                selectedImage = featureImageData?.selectedImage || selectedImage;
                featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
        
                const updatedPageData = {
                    ...newPageData,
                    featured_image: {
                    selectedImage: selectedImage,
                    cropedImage: featureCropedImage,
                    position: position,
                    zoom: zoom
                    }
                };
      
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/pages/updatepage/${_pageId}`,
                    updatedPageData
                )
                setPreviewLoading(false)
                setStatus('Draft')
                setPublished(false);
                setDraft(true);
            }
            } else {
                setPreviewLoading(false)
            }
        }
        setPreviewLoading(true)
        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;
        const featureImageData = await getFeatureImageData(newPageID)
        selectedImage = featureImageData?.selectedImage || selectedImage;
        featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
        if (isHomepage) {
          const homepageData = {
    
            tempData: {
                author_id: authorId,
                url_address: url,
                status: sendStatus? sendStatus : status,
                previous_status: sendStatus ? sendStatus : status,
                summary: updatedContent || editorContent,
                postSummary: updatedContent || editorContent,
                showPublication:showPublication,
                featured_image: {
                    selectedImage: selectedImage,
                    cropedImage: featureCropedImage,
                    position: position,
                    zoom: zoom
                },
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
            }
        };
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/updateHomepage/${id}`,
            homepageData
        )
        if (res?.status === 200) {
            setPreviewLoading(false)
            window.open(`https://reactadminportal.codeptsolutions.com/users/${id}/pages/previewhome/${id}`, "_blank");
        }
          return;
        }
        if (isContactpage) {
            const contactpageData = {
                tempData: {
                    ...updatedContactpageData,
                    featured_image: {
                    selectedImage: selectedImage,
                    cropedImage: featureCropedImage,
                    position: position,
                    zoom: zoom
                    }
                }
            }
    
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/updateContactpage/${id}`,
                contactpageData
            )
            if (res?.status === 200) {
                setPreviewLoading(false)
                window.open(`https://reactadminportal.codeptsolutions.com/users/${id}/pages/previewcontact/${id}`, "_blank");
            } else{
                setPreviewLoading(false)
            }
            return;

        }

        if ((!isHomepage && !isContactpage)) {
    
            const newPageData = {
                tempData: {
                description: updatedContent || editorContent,
                title: dbPageTitle,
                author_id: authorId,
                url_address: url,
                categories: selectedCategories,
                status: sendStatus ? sendStatus : status,
                previous_status: sendStatus ? sendStatus : status,
                featured_image: {
                    selectedImage: selectedImage,
                    cropedImage: featureCropedImage,
                    position: position,
                    zoom: zoom
                },
                pageTitle: dbPageTitle,
                postContent: updatedContent || editorContent,
                pageImage: featureCropedImage,
                member_id: JSON.parse(localStorage.getItem("userData"))?._id
                }   
            }

            if(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') {
                const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/pages/updatepage/${_pageId ? _pageId : newPageID}`,
                newPageData
                )
                if (res?.status === 200) {
                    setPreviewLoading(false)
                    window.open(`https://reactadminportal.codeptsolutions.com/users/${id}/pages/previewpage/${_pageId ? _pageId : newPageID}`, "_blank");
                }
            }else {
                setPreviewLoading(false)
            }
        }else {
            setPreviewLoading(false)
        }
    }

    const validateForm = () => {
        const validateForm = () => {
            let isValid;
            if(isHomepage || isContactpage){
              isValid = pageImage !== ''
            }else{
              isValid = (dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '');
            }
            setIsFormInvalid(!isValid);  // Update the form validation state
          };
    };
    
    useEffect(() => {
    // Validate form whenever tempData changes
    validateForm();
    }, [dbPageTitle, editorContent, updatedContent, pageImage]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          if (hasUnsavedChanges) {
            const confirmLeave = window.confirm("Changes you made may not be saved. Are you sure you want to leave?");
            event.returnValue = confirmLeave;
            if (confirmLeave) {
              setHasUnsavedChanges(false)
            }
            return confirmLeave;
          }
        };
    
        window.addEventListener("beforeunload", handleBeforeUnload);
    
        return () => {
          window.removeEventListener("beforeunload", handleBeforeUnload);
        };
      }, [hasUnsavedChanges]);
      const handleChangePage = (e, url) =>{
        if(isContentChange === true){
            e.preventDefault();
            showConfirmationPopup(url)
        }
    }
    return (
        <>
            {
                canAccess ?
                    <section className="dashboard new-page new-page-loader">
                        <div className="commonPage">
                            <div className="dashboardTop">
                                <div className="newpageContent">
                                    <div className="newpageLeft publicationLeft">

                                        <div className="breadcrumbs">
                                            <Link to={'/'} onClick={(e) => { handleChangePage(e,'/') }}><img src={Home} className="home-icon" alt="home-icon" /></Link>
                                            <img className="divider" src={iconDivider} alt="dropdown" />
                                            <Link to={`/users`} onClick={(e) => { handleChangePage(e,'/users') }}><span className="">Users</span></Link>
                                            <img className="divider" src={iconDivider} alt="dropdown" />
                                            <Link to={`/users/${id}`} onClick={(e) => { handleChangePage(e,`/users/${id}`) }}><span className="">{userData.firstName} {userData.lastName}</span></Link>
                                            <img className="divider" src={iconDivider} alt="dropdown" />
                                            <Link to={`/users/${id}/pages/`} onClick={(e) => { handleChangePage(e,`/users/${id}/pages/`) }}><span className="">Pages</span></Link>
                                            {pageid == undefined && (
                                                <>
                                                    <img className="divider" src={iconDivider} alt="dropdown" />
                                                    <Link to={`/users/${id}/pages/page`} onClick={(e) => { handleChangePage(e,`/users/${id}/pages/page`) }}><span className="">New Page</span></Link>
                                                </>
                                            )}
                                        </div>

                                        {
                                            isHomepage &&
                                            <HomePageContent homepageData={homepageData} setEditorContent={setEditorContent} validateEditorContent={validateEditorContent} setValidateEditorContent={setValidateEditorContent} showPublication={showPublication} setShowPublication={setShowPublication} setContentChange={setContentChange} setHasUnsavedChanges={setHasUnsavedChanges}/>
                                        }
                                        {
                                            isContactpage &&
                                            <ContactPageContent contactpageData={contactpageData} setUpdatedContactpageData={setUpdatedContactpageData} validationContactpageData={validationContactpageData} setValidationContactpageData={setValidationContactpageData} setContentChange={setContentChange} setHasUnsavedChanges={setHasUnsavedChanges}/>
                                        }

                                        {
                                            (!isHomepage && !isContactpage) &&
                                            <>

                                                <div className="newpageLeftRow editor-block">
                                                    <input type="hidden" className="page-title" value={dbPageTitle} onChange={(e) => { changeSlug(e.target.value); setContentChange(true); setHasUnsavedChanges(true);}} placeholder="Title" />

                                                    <div id="post-title" placeholder="Title" contentEditable="true"
                                                        onKeyDown={(e) => {
                                                            changeTitle(e)
                                                            if (e.target.innerText.length > 0) {
                                                                setValidateTitle(false);
                                                            }
                                                            setContentChange(true)
                                                            setHasUnsavedChanges(true);
                                                        }}
                                                        onKeyUp={(e) => {
                                                            changesTitles(e);
                                                            if (e.target.innerText.length > 0) {
                                                                setValidateTitle(false);
                                                            }
                                                            setContentChange(true)
                                                            setHasUnsavedChanges(true);
                                                        }}
                                                        onBlur={(e) => handleBlur(e)}
                                                        onPaste={(e) => handlePaste(e)} >{dbPageTitle}</div>
                                                    {validateTitle === true ? (
                                                        <p className="validateError">Please enter a title</p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className={`newpageLeftRow textBlock ${validateEditorContent ? 'validateError' : ''}`}>
                                                    <EditorComponent setUpdatedContent={handleContentChange} editorContent={editorContent} type="page" id={pageid} setValidateEditorContent={setValidateEditorContent} setEditorContent={setEditorContent}/>
                                                    {validateEditorContent === true ? (
                                                        <p className="validateError editor-error editor-error-space">Please enter a content to the page</p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                            </>
                                        }

                                    </div>
                                    <div className="newpageRight newpageRightSticky">
                                        <div className="buttonsDesktop">

                                            {(published === false && draft === false) ?
                                                <div className="newpageRightButtons">
                                                    <button className="primary-button button publish-button"
                                                        onClick={(e) => {
                                                            validateFields();
                                                            if (dbPageTitle.length > 0 && ((updatedContent?.trim().length > 0 || editorContent?.trim().length > 0)) && pageImage !== '') {
                                                                setIsLoading(true);
                                                                addData(true, "Published");
                                                                setStatus('Published');
                                                                // setOpenNotify(true);
                                                                setPublished(true);
                                                                setPublicationList([...publicationList, { title: "Published" }]);

                                                                clearTimeout(notificationTimeoutId)
                                                                notificationTimeoutId = setTimeout(() => {
                                                                    setOpenNotify(false)
                                                                }, 15000);
                                                            }
                                                        }
                                                        }
                                                    >
                                                        {isLoading ?
                                                            <div className="publishbutton-loader">
                                                                <div className="ring-loader"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                <img src={send} alt="Send" className="publish-arrow"/>
                                                                <p>Publish</p>
                                                            </>}
                                                    </button>
                                                    <button className="secondary-button button"
                                                        onClick={(e) => {
                                                            draftValidateFields();
                                                            if (dbPageTitle.length > 0) {
                                                                setIsDraftLoading(true);
                                                                addData(true, "Draft");
                                                                setStatus('Draft');
                                                                setDraft(true);
                                                                setOpenNotifyDraft(true);
                                                            }
                                                        }}>
                                                        {isDraftLoading ?
                                                            <div className="draftButton-loader">
                                                                <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                Save as draft
                                                            </>}
                                                    </button>
                                                    {isPreviewLoading ? 
                                                        <div className="previewButtonLoader">
                                                        <div className="ring-loader cancel"></div>
                                                        </div>
                                                        :
                                                        <button className={`${(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                            onMouseEnter={() => {
                                                                if (isFormInvalid) {
                                                                setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                }
                                                            }}
                                                            onMouseLeave= {() => {
                                                                setPreviewErrorMessage("");
                                                            }}>
                                                            {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Preview
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                ''}

                                            {published === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        <button className="primary-button button" onClick={(e) => {
                                                            validateFields();
                                                            if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                                                handlePublish(e)
                                                            }
                                                            if (isHomepage && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                                                handlePublish(e)
                                                            }
                                                            if (isContactpage && pageImage !== '') {

                                                                if (validationContactpageData.publicEmailValid && validationContactpageData.receiveEmailValid) {
                                                                    handlePublish(e);
                                                                } else {
                                                                    setValidationContactpageData((prevState) => ({
                                                                        ...prevState, // Spread the previous state
                                                                        showError: true // Update showError with the new value
                                                                    }));
                                                                }
                                                            }
                                                        }}>
                                                            {isLoading ?
                                                                <div className="updatebutton-loader">
                                                                    <div className="ring-loader"></div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <p>Update</p>
                                                                </>}
                                                        </button>



                                                        <button
                                                            className="newpageMoreButtons secondary-button button"
                                                            onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                                                        >
                                                            <img src={dots} alt="Dots" className="action-dots" />
                                                        </button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }

                                                        {isPublishedOpen && !isHomepage && !isContactpage ? (
                                                            <div className="publishedButtonsList">
                                                                <ul>
                                                                    <li className="publish-li"

                                                                        onClick={(e) => {
                                                                            draftValidateFields();
                                                                            if (dbPageTitle.length > 0) {
                                                                                handleChangeToDraft();
                                                                            }
                                                                        }}>Change to draft</li>
                                                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    {published === true ?
                                                        <p className="publishStatus">
                                                            <>Status: <span>{status}</span></>
                                                        </p>
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            ) : (''

                                            )}

                                            {draft === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        <button className="primary-button button" onClick={(e) => {
                                                            draftValidateFields();
                                                            if (dbPageTitle.length > 0) {
                                                                handleDraft(e);
                                                            }
                                                        }}>
                                                            {isLoading ?
                                                                <div className="updateDraftbutton-loader">
                                                                    <div className="ring-loader"></div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <p>Update Draft</p>
                                                                </>}
                                                        </button>
                                                        <button
                                                            className="newpageMoreButtons secondary-button button"
                                                            onClick={(e) => setIsDraftOpen(!isDraftOpen)}
                                                        >
                                                            <img src={dots} alt="Dots" className="action-dots" />
                                                        </button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }
                                                        {isDraftOpen ? (
                                                            <div className="publishedButtonsList">
                                                                <ul>
                                                                    <li className="publish-li" onClick={(e) => {
                                                                        validateFields();
                                                                        if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                                                            handleChangeToPublish(e, () => setIsLoading(true))
                                                                        }
                                                                    }}>Publish</li>
                                                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    {draft === true ? (
                                                        <p className="publishStatus">
                                                            Status: <span className="draftStatus">Draft</span>
                                                        </p>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : ''}
                                        </div>

                                        <div className="newPageRightRow">
                                            <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown1}>
                                                <span>
                                                    URL address
                                                </span>
                                                <div className="dropdownIcon">
                                                    <img src={iconDropDown} alt="dropdown" />
                                                </div>
                                            </p>
                                            <div className={`contentDrop ${isOpen1 ? 'expanded' : 'collapse'}`}>
                                                <p className="newPageRightFullUrl">
                                                    <span>Display at the end of the publication...</span>
                                                </p>
                                                <div className="adressInputBlock">
                                                    <img className="adressLink" src={link} alt="Link" />
                                                    <input
                                                        id="page-slug"
                                                        type="text"
                                                        placeholder="URL"
                                                        className="inputFields"
                                                        readOnly={isHomepage || isContactpage ? true : false}
                                                        value={isHomepage ? 'home' : url}
                                                        autocomplete="off"
                                                        onChange={(e) => {
                                                            if (isHomepage || isContactpage) {
                                                                return false;
                                                            }
                                                            setContentChange(true)
                                                            setHasUnsavedChanges(true);
                                                            setUrl(e.target.value);
                                                            setIslugChanged(true)
                                                            if (e.target.value === '') {
                                                                setIslugChanged(false)
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            if (isHomepage || isContactpage) {
                                                                return false;
                                                            }
                                                            let urlValue = convertToSlug(e.target.value);
                                                            setUrl(urlValue);
                                                            if (e.target.value === '') {
                                                                changeSlug(pageTitle);
                                                                setIslugChanged(false)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <p className="newPageRightFullUrl slug-box">
                                                    Full URL:
                                                    {
                                                        published === true && status == 'Published' ? (
                                                            <Link className="pageurl" target={'_blank'} to={`${baseUrl}${url}`}>
                                                                <span>{baseUrl}{url}</span>
                                                                <img className="link-external" src={linkExternal} />
                                                            </Link>
                                                        ) : (
                                                            <span className="pageurl">{baseUrl}{url}</span>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        {!isHomepage && !isContactpage &&
                                            <div className="newPageRightRow categoryFieldDrop">
                                                <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown4}>
                                                    <span>Categories (optional)</span>
                                                    <div className="dropdownIcon">
                                                        <img src={iconDropDown} alt="dropdown" />
                                                    </div>
                                                </p>

                                                <div className={`contentDrop ${isOpen4 ? 'expanded' : 'collapse'}`}>
                                                    <p className="newPageRightFullUrl">
                                                        <span>Display at the end of the publication...</span>
                                                    </p>
                                                    <div className="adressInputBlock urlInput">
                                                        <img className="adressLink" src={search} alt="Search" />
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            value={searchCategory}
                                                            className="inputFields"
                                                            onChange={(e) => setSearchCategory(e.target.value)}
                                                            onKeyPress={handleKeyPress}
                                                            autocomplete="off"
                                                        />
                                                        <ul className="list-search categories-list">
                                                            {
                                                                filteredValues
                                                                    .filter((category) => category.toLowerCase().includes(searchCategory.toLowerCase()))
                                                                    .map((category, index) => (
                                                                        <li key={index}>
                                                                            <label>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={selectedCategories.includes(category)}
                                                                                    onChange={() => handleCheckboxChange(category)}
                                                                                />
                                                                                <p>{category}</p>
                                                                            </label>
                                                                        </li>
                                                                    ))
                                                            }
                                                        </ul>

                                                        <button className="new-category" onClick={handleNewCategoryClick}>
                                                            <img src={plus} alt="Plus" /> New Category
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="newPageRight desktopFeatures">
                                            <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown3}>
                                                <span>
                                                    Featured image
                                                </span>
                                                <div className="dropdownIcon">
                                                    <img src={iconDropDown} alt="dropdown" />
                                                </div>
                                            </p>
                                            <div className={`contentDrop ${isOpen3 ? 'expanded' : 'collapse'}`}>
                                                <p className="newPageRightFullUrl">
                                                    <span>Display at the end of the publication...</span>
                                                </p>
                                                <div
                                                    className="newPageRightRow imageUploadBox"
                                                    onDragOver={handleDragOver}
                                                    onDragLeave={handleDragLeave}
                                                    onDrop={handleDrop}
                                                >
                                                    {saveFeatureImage ? (
                                                        <div
                                                            id="newpageImageUpload"
                                                            className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                            onClick={handleClick}
                                                        >
                                                            {loadingFeatureImg === true &&
                                                                featuredImageModal == false ? (
                                                                <div className="featureImg-loader">
                                                                    <Loader />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div onClick={handleClick}>
                                                                        <img
                                                                            className="newpage_CoverImg"
                                                                            src={featureImage}
                                                                            onLoad={handleImageLoad}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            id="newpageImageUpload"
                                                            className={isDragging ? "draging newpageImageUpload" : "newpageImageUpload"}
                                                            onClick={() => {
                                                                setShowUploadMediaPopup(true);
                                                                setUploadMediaPopupFor('feature-image');
                                                                setUploadMediaPopupTab('upload');
                                                            }}
                                                        >
                                                            {loadingFeatureImg === true &&
                                                                featuredImageModal === false ? (
                                                                <div className="featureImg-loader">
                                                                    <Loader />
                                                                </div>
                                                            ) : (
                                                                <div className="featureImgMiddleContent">
                                                                    <img
                                                                        className="fileUploadCover_Img"
                                                                        src={featured}
                                                                        alt="Featured Image"
                                                                    />
                                                                    <p className="newPageImageUploadText">
                                                                        <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                                                                        <br />
                                                                        JPG, PNG (max. 128MB)
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    <input
                                                        type="file"
                                                        id="feature-img"
                                                        ref={fileInputRef}
                                                        onChange={(e) => {
                                                            handleFileUpload(e);
                                                            setValidateImage(false);
                                                        }}

                                                        accept="image/*"
                                                        hidden
                                                    />
                                                </div>
                                                {validateImage === true ? (
                                                    <p className="validateError">The field is not filled</p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                        </div>
                                        <div className="buttonsMobile">
                                            {(published === false && draft === false) ?
                                                <div className="newpageRightButtons">
                                                    <button className="primary-button button publish-button"
                                                        onClick={(e) => {
                                                            validateFields();
                                                            if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                                                setIsLoading(true);
                                                                addData(true, "Published");
                                                                // setOpenNotify(true);
                                                                setPublished(true);
                                                                setPublicationList([...publicationList, { title: "Published" }]);

                                                                clearTimeout(notificationTimeoutId)
                                                                notificationTimeoutId = setTimeout(() => {
                                                                    setOpenNotify(false)
                                                                }, 15000);
                                                            }
                                                        }
                                                        }
                                                    >
                                                        {isLoading ?
                                                            <div className="publishbutton-loader">
                                                                <div className="ring-loader"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                <img src={send} alt="Send" className="publish-arrow"/>
                                                                <p>Publish</p>
                                                            </>}
                                                    </button>
                                                    <button className="secondary-button button"
                                                        onClick={(e) => {
                                                            draftValidateFields();
                                                            if (dbPageTitle.length > 0) {
                                                                setIsDraftLoading(true);
                                                                addData(true, "Draft");
                                                                setDraft(true);
                                                                setOpenNotifyDraft(true)
                                                            }
                                                        }}>
                                                        {isDraftLoading ?
                                                            <div className="draftButton-loader">
                                                                <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                Save as draft
                                                            </>}
                                                    </button>
                                                    {isPreviewLoading ? 
                                                        <div className="previewButtonLoader">
                                                        <div className="ring-loader cancel"></div>
                                                        </div>
                                                        :
                                                        <button className={`${(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                            onMouseEnter={() => {
                                                                if (isFormInvalid) {
                                                                setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                }
                                                            }}
                                                            onMouseLeave= {() => {
                                                                setPreviewErrorMessage("");
                                                            }}>
                                                            {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Preview
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                ''}

                                            {published === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        <button className="primary-button button" onClick={(e) => {
                                                            validateFields();
                                                            if (dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                                                handlePublish(e)
                                                            }
                                                            if (isHomepage && ((editorContent.length > 0) || (updatedContent.length > 0)) && pageImage !== '') {
                                                                handlePublish(e)
                                                            }
                                                        }}>
                                                            {isLoading ?
                                                                <div className="updatebutton-loader">
                                                                    <div className="ring-loader"></div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <p>Update</p>
                                                                </>}
                                                        </button>
                                                        <button
                                                            className="newpageMoreButtons secondary-button button"
                                                            onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                                                        >
                                                            <img src={dots} alt="Dots" />
                                                        </button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }
                                                        {isPublishedOpen && !isHomepage && !isContactpage ? (
                                                            <div className="publishedButtonsList">
                                                                <ul>
                                                                    <li className="publish-li" onClick={handleChangeToDraft}>Change to draft</li>
                                                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    {published === true ? (
                                                        <p className="publishStatus">
                                                            Status: <span>{status}</span>
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            {draft === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        <button className="primary-button button" onClick={(e) => {
                                                            draftValidateFields();
                                                            if (dbPageTitle.length > 0) {
                                                                handleDraft(e)
                                                            }
                                                        }}>
                                                            {isLoading ?
                                                                <div className="updateDraftbutton-loader">
                                                                    <div className="ring-loader"></div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <p>Update Draft</p>
                                                                </>}
                                                        </button>
                                                        <button
                                                            className="newpageMoreButtons secondary-button button"
                                                            onClick={(e) => setIsDraftOpen(!isDraftOpen)}
                                                        >
                                                            <img src={dots} alt="Dots" className="action-dots" />
                                                        </button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent?.length > 0) || (updatedContent?.length > 0)) && pageImage !== '') ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }

                                                        {isDraftOpen ? (
                                                            <div className="publishedButtonsList">
                                                                <ul>
                                                                    <li className="publish-li" onClick={(e) => {
                                                                        validateFields();
                                                                        if (dbPageTitle.length > 0 && ((updatedContent?.trim().length > 0 || editorContent?.trim().length > 0)) && pageImage !== '') {
                                                                            handleChangeToPublish(e, () => setIsLoading(true))
                                                                        }
                                                                    }}>Publish</li>
                                                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    <p className="publishStatus">
                                                        Status: <span className="draftStatus">Draft</span>
                                                    </p>
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       {showUploadMediaPopup &&
                            <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedFeatureImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
                        }
                        {(isPopupOpenDeleteAccount || showCategoryField) && <div className="popupModalOverlay"></div>}
                        <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} data={values} setCategory={setCategories} setSelectedCategory={setSelectedCategories} isCategoryPage="no" userId={id} setOpenNotify={setCategoriesNotify} />
                        <DeletePageModal isOpen={isOpen} item={data} selectedItems={data} onClose={onClose} setOpen={setOpen} type="page" redirect={`users/${id}/pages`} fetchData={fetchData} />
                        { categoriesNotify && 
                            <Notification title={`Category has been successfully added!`} setOpenNotify={setCategoriesNotify} type={"success"} openNotify={categoriesNotify} />
                        }
                        {featuredImageModal && (
                            <>
                                <div className="imagCropModal">
                                    <div className="featuredImageModal featurboxModal" ref={modalRef}>
                                        <div className="modal-content">
                                            {uploadedImage === null ? (
                                                <div
                                                    className="newPageRightRow"
                                                    onDragOver={handleDragOver}
                                                    onDragLeave={handleDragLeave}
                                                    onDrop={handleDrop}
                                                >
                                                    {((saveFeatureImage && saveFeatureImage === oldImage) ||
                                                        saveFeatureImage !== featureImage) &&
                                                        (saveFeatureImage ? (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div>
                                                                            <div className="crop-container">
                                                                                <ImageEditor
                                                                                    type="featureImg"
                                                                                    image={featureImage}
                                                                                    oldImage={oldImage}
                                                                                    setOldImage={setOldImage}
                                                                                    setFeatureImage={setFeatureImage}
                                                                                    setUploadedImage={setUploadedImage}
                                                                                    replaceImage={handleFileUpload}
                                                                                    zoom={zoom}
                                                                                    position={position}
                                                                                    setZoom={handleZoom}
                                                                                    setPosition={handlePosition}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                                onClick={() =>{
                                                                    // document.getElementById("feature-img").click()
                                                                    setShowUploadMediaPopup(true);
                                                                    setUploadMediaPopupFor('feature-image');
                                                                    setUploadMediaPopupTab('upload');
                                                                }}
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="featureImgMiddleContent">
                                                                            <img
                                                                                className="fileUploadCover_Img"
                                                                                src={featured}
                                                                                alt="Featured Image"
                                                                            />
                                                                            <p className="newPageImageUploadText">
                                                                            <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                                                                            <br />
                                                                            JPG, PNG (max. 128MB)
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))}

                                                    {featureImage &&
                                                        saveFeatureImage === featureImage &&
                                                        (featureImage ? (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div>
                                                                            <div className="crop-container">
                                                                                <ImageEditor
                                                                                    type="featureImg"
                                                                                    image={featureImage}
                                                                                    oldImage={oldImage}
                                                                                    setOldImage={setOldImage}
                                                                                    setFeatureImage={setFeatureImage}
                                                                                    setUploadedImage={setUploadedImage}
                                                                                    replaceImage={handleFileUpload}
                                                                                    zoom={zoom}
                                                                                    position={position}
                                                                                    setZoom={handleZoom}
                                                                                    setPosition={handlePosition}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                                onClick={() =>{
                                                                    // document.getElementById("feature-img").click()
                                                                    setShowUploadMediaPopup(true);
                                                                    setUploadMediaPopupFor('feature-image');
                                                                    setUploadMediaPopupTab('upload');
                                                                }}
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="featureImgMiddleContent">
                                                                            <img
                                                                                className="fileUploadCover_Img"
                                                                                src={featured}
                                                                                alt="Featured Image"
                                                                            />
                                                                            <p className="newPageImageUploadText">
                                                                            <span>Upload</span> or <span onClick={(e) => {e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media');}}>Open Media Library</span>
                                                                            <br />
                                                                            JPG, PNG (max. 128MB)
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))}
                                                    <input
                                                        type="file"
                                                        id="feature-img"
                                                        onChange={handleFileUpload}
                                                        accept="image/*"
                                                        hidden
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="crop-container">
                                                        {featureImage && oldImage && (
                                                            <ImageEditor
                                                                type="featureImg"
                                                                image={featureImage}
                                                                oldImage={oldImage}
                                                                setFeatureImage={setFeatureImage}
                                                                setUploadedImage={setUploadedImage}
                                                                replaceImage={handleFileUpload}
                                                                zoom={zoom}
                                                                position={position}
                                                                setZoom={handleZoom}
                                                                setPosition={handlePosition}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="replaceImageRange">
                                                <div className="replaceImgBtn">
                                                    <button
                                                        className="uploadImage secondary-button button"
                                                        type="button"
                                                        onClick={() => handleReplaceImg()}
                                                    >
                                                        <span>Replace Image</span>
                                                    </button>
                                                </div>
                                                <div className="imgRange">
                                                    <img className="coverImgLeft" src={coverImg} />
                                                    <input
                                                        id="zoom-range"
                                                        className="zoom-range"
                                                        type="range"
                                                        onMouseDown={handleMouseDown}
                                                        onMouseUp={handleMouseUp}
                                                        onTouchStart={handleTouchStart}
                                                        onTouchEnd={handleTouchEnd}
                                                        value={zoom}
                                                        min={1}
                                                        max={3}
                                                        step={0.1}
                                                        aspect={384 / 200}
                                                        aria-labelledby="Zoom"
                                                        style={{
                                                            background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100
                                                                }%, #B7BCC2 0%)`,
                                                        }}
                                                        onChange={(e) => {
                                                            handleZoom(e.target.value);
                                                        }}
                                                    />
                                                    <img className="coverImgRight" src={coverImg} />
                                                </div>
                                            </div>
                                            <div className="featuredImageModalbtn">
                                                <button
                                                    className="cancel secondary-button button"
                                                    type="button"
                                                    onClick={() => handleCancel()}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="savePicture primary-button button"
                                                    type="button"
                                                    onClick={() => handleSavePicture()}
                                                >
                                                    Save Picture
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {featuredImageModal && <div className="overlayModal"> </div>}
                            </>
                        )}
                        { openPagePopup && (
                            <>
                                <PreviewSuccessPopUp setShowPreviewPopup={setOpenPagePopup} showPreviewPopup={openPagePopup} previewpage={"Page"} tempData={popupData} userMetaData={userData}  title={"Your page is live!"} description={"Your page has been successfully published on your web profile. You can go to the navigation menu in your settings and add it there to make it easily accessible."}/>
                            </>
                        )}
                        {
                            <Notification title={"Your page has been saved as a draft!"} setOpenNotify={setOpenNotifyDraft} type={"success"} openNotify={openNotifyDraft} />
                        }
                        { 
                            <NotificationMessage title={"Your page has been successfully published!"} text={"You can view it "} link= {"here."} to={`/dashboard/page/${newPageID}`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
                        }
                        {updateDraftNotify &&
                            <NotificationMessage title={"Your page has been successfully updated!"} text={"You can view it "} link= {"here."} to={`/dashboard/page/${newPageID}`} setOpenNotify={setUpdateDraftNotify} type={"success"} openNotify={updateDraftNotify} />
                        }
             
                    </section>
                    :
                    <Error />
            }
        </>
    );
}

export default EditPages;