import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultsTeam(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No team members found</p>
                <p className='noResDesc'>It looks like there are no team members to display. Add employees, support staff, or profile assistants to build your team and streamline collaboration.</p>
            </div>
        </div>
    );
}

export default NoResultsTeam;
