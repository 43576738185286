import React, { useRef, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';

import NoResultsCategories from './NoResultsCategories';
import AddCategoryPage from "./AddCategoryPage";
import EditCategoryModel from "./EditCategoryModel";
import DeleteCategoryModel from "./DeleteCategoryModel";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import Pagination from "../../components/Pagination";

import search from '../../images/search.svg'
import down from '../../images/down-arrow.svg'
import clear from '../../images/delete.svg'
import plus from '../../images/plus-icon.svg'
import iconDropDown from "../../images/icondropdown.svg";
import more from '../../images/more.svg'
import edit from '../../images/pen-img.svg'
import Notification from "../../components/Notification";

function Categories(props) {
    props.setActivePage('Categories');
    const { id } = useParams();

    const [userFilterSelect, setFilterTypeSelect] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState([])

    const [searchInput, setSearchInput] = useState('')

    const [categoriesData, setCategoriesData] = useState([])
    const [allCategoriesData, setAllCategoriesData] = useState([])

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([])

    const [selectedCatIds, setSelectedCatIds] = useState([])
    const [selectedCatName, setSelectedCatName] = useState([])

    const [nameSort, setNameSort] = useState(false)
    const [pageSort, setPageSort] = useState(false)
    const [pubicSort, setPubicSort] = useState(false)
    const [newsSort, setNewsSort] = useState(false)

    const [openPopupIndex, setOpenPopupIndex] = useState('');

    const [showCategoryField, setShowCategoryField] = useState(false);
    const [showEditModel, setShowEditModel] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const filterType = ['Pages', 'Publications', 'Newsletters']
    const [deleteNotify, setDeleteNotify] = useState(false)
    const [multiDeleteNotify, setMultiDeteleNotify] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const [openNotify, setOpenNotify] = useState(false);
    const [editNotify, setEditNotify] = useState(false);
    const [selectCatLenght, setSelectCatLenght] = useState(0)
    const ref = useRef();
    const tableActionRef = useRef();
    const cateDropDownRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if ( ref.current && ! ref.current.contains(e.target) ) {
                // setActivateEdit(false);
                // togglePopup(false)
                // setOpenPopupIndex('')
            }

            if( cateDropDownRef.current && ! cateDropDownRef.current.contains(e.target) ) {
                setFilterTypeSelect(false)
            }

            if (tableActionRef.current && !tableActionRef.current.contains(e.target)) {
                setOpenPopupIndex('');
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, []);

    
    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        const types = selectedFilterType.length > 0 ? selectedFilterType.join(',') : '';
        setIsLoading(false);
        try {
            setIsLoading(true);
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/list/${id}?page=${currentPage}&search=${search}&types=${types}`,
            )
            setCategoriesData(response?.data?.result);
            setTotalPage(response.data.totalPages || 1);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error, "error");
        }
    }

    const fetchAllCategories = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/all/${id}`,
            )
            setAllCategoriesData(response?.data?.result || [])
            
        } catch (error) {
            console.log(error, "error");
        }
    }

    useEffect(() => {
        fetchData();
    }, [id, currentPage, searchInput, selectedFilterType]);

    useEffect(() => {
        fetchAllCategories();
    }, [id]);



    const handleNewCategoryClick = () => {
        setShowCategoryField(true);
        document.body.classList.add('hidden-message');
    }

    const handleDelete = (itemIds) => {
        setSelectedCatIds(itemIds);
        setShowDeleteModel(true);
        document.body.classList.add('hidden-message');
    }

    const handleCheckboxChange = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter((id) => id !== itemId);
                setSelectAll(updatedSelectedItems.length === categoriesData.length);
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(updatedSelectedItems.length === categoriesData.length);
                return updatedSelectedItems;
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allItemIds = categoriesData.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const sortByName = () => {
        const result = [...categoriesData].sort((a, b) => {
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameSort ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        });

        setCategoriesData(result);
    }

    const sortByPage = () => {
        const result = [...categoriesData].sort((a, b) => {
            const pageA = parseFloat(a.page_count) || 0;
            const pageB = parseFloat(b.page_count) || 0;
            return pageSort ? pageA - pageB : pageB - pageA;
        });

        setCategoriesData(result);
    }

    const sortByPubic = () => {
        const result = [...categoriesData].sort((a, b) => {
            const publicA = parseFloat(a.publication_count) || 0;
            const publicB = parseFloat(b.publication_count) || 0;
            return pubicSort ? publicA - publicB : publicB - publicA;
        });

        setCategoriesData(result);
    }

    const sortByNews = () => {
        const result = [...categoriesData].sort((a, b) => {
            const newsA = parseFloat(a.newslatter_count) || 0;
            const newsB = parseFloat(b.newslatter_count) || 0;
            return newsSort ? newsA - newsB : newsB - newsA;
        });

        setCategoriesData(result);
    }

    const togglePopup = (index) => {
        if (index === openPopupIndex) {
            setOpenPopupIndex(null);
        } else {
            setOpenPopupIndex(index);
        }
    };

    const handleEdit = (itemIds, itemName ) => {
        setSelectedCatIds(itemIds);
        setSelectedCatName(itemName)
        setShowEditModel(true);
        document.body.classList.add('hidden-message');
    }
    const closeEditModel = () => {
        setShowEditModel(false);
        document.body.classList.remove('hidden-message');
    }

    const closeCategoryField = (action) => {
        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');
    }

    const closeDeleteModel = () => {
        setShowDeleteModel(false);
        document.body.classList.remove('hidden-message');
    }

    const updateCategoriesData = (categoryId, categoryName, action) => {

        if (action == 'add') {
            const categoriesDatas = categoriesData;

            const foundData = categoriesDatas.find(obj => obj.name === categoryName);

            if (foundData) {
                return "This category already exists.";
            }
        }

        if (action == 'update') {
            const categoriesDatas = categoriesData;
            categoriesDatas.push(categoryId);

            setCategoriesData(categoriesDatas);
        }


        if (action == 'edit') {
            const categoriesDatas = categoriesData;

            const categoryFound = categoriesDatas.find(obj => obj._id !== categoryId && obj.name === categoryName);

            if (categoryFound !== undefined) {
                return "This category already exists.";
            }

            const foundData = categoriesDatas.find(obj => obj._id === categoryId);

            foundData.name = categoryName;

            const indexId = categoriesDatas.findIndex(item => item._id === foundData._id);

            if (indexId !== -1) {
                categoriesDatas[indexId] = foundData;
            }

            setCategoriesData(categoriesDatas);
        }

        if (action == 'delete') {
            const categoriesDatas = categoriesData.filter(item => !categoryId.includes(item._id));
            setCategoriesData(categoriesDatas);
        }

        if (action == 'move') {
            const categoriesDatas = categoriesData;

            const foundData = categoriesDatas.find(obj => obj._id === categoryName);
            const foundDeletedData = categoriesDatas.filter(obj => categoryId.includes(obj._id));

            const page_ids = Array.from(new Set([...foundData.page_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.page_ids))]]));
            const newslatter_ids = Array.from(new Set([...foundData.newslatter_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.newslatter_ids))]]));
            const publication_ids = Array.from(new Set([...foundData.publication_ids, ...[...new Set(foundDeletedData.flatMap(obj => obj.publication_ids))]]));

            foundData.page_ids = page_ids;
            foundData.newslatter_ids = newslatter_ids;
            foundData.publication_ids = publication_ids;

            foundData.page_count = page_ids.length;
            foundData.newslatter_count = newslatter_ids.length;
            foundData.publication_count = publication_ids.length;

            const indexId = categoriesDatas.findIndex(item => item._id === foundData._id);

            if (indexId !== -1) {
                categoriesDatas[indexId] = foundData;
            }
            setCategoriesData(categoriesDatas);

            return foundData;
        }

        return true;
    }

    return (
        <section className='dashboard categoriesPage'>

                <div className="pagesTableWrapper">
                    <div className='pagesSearch pageTeam'>
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input type="text" id="pages_search" name="pages_search" className="inputFields" placeholder={'Search categories..'} value={searchInput} onChange={e => setSearchInput(e.target.value)} autocomplete="off" />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown pages-dropdown">
                                    <button className="selectFields" onClick={(e) => setFilterTypeSelect(!userFilterSelect)}>
                                        All Categories{" "}
                                        {selectedFilterType.length > 0 ? `(${selectedFilterType.length})` : ""}
                                        <img src={iconDropDown} alt="down-icon" />
                                    </button>
                                    {
                                        (userFilterSelect === true) ? <CheckboxActionDropdown refrence={cateDropDownRef} options={filterType} selectedOptions={selectedFilterType} changeOption={setSelectedFilterType} /> : ''
                                    }
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div className='deleteIcon' onClick={() => handleDelete(selectedItems)}>
                                <img className='deleteIconImage' src={clear} alt="Clear Input" />
                            </div>
                            <div className='add-new-member'>
                                <button className="form-btn primary-button button" onClick={handleNewCategoryClick}><img src={plus} alt="Icon" /> <span>New Category</span></button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={ categoriesData.length > 0 ? "pagesTable" : "pagesTable tableBottomRounded" } >
                    <div className="table-data-wrapper table-pagination-wrapper">
                        <div className="table-data table-five-column">
                            <div className="table-head">
                                <div className="table-status">
                                    <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                                    <div className={`title ${nameSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setNameSort(!nameSort); sortByName(); }} >
                                        <p className="table-title">Name</p>
                                        <img src={down} onClick={(e) => { setNameSort(!nameSort); sortByName(); }} alt="Down" />
                                    </div>
                                </div>
                                <div className={`team-member-role ${pageSort === false ? "down-arrow" : "up-arrow"}`} >
                                    <p className="table-title" onClick={(e) => { setPageSort(!pageSort); sortByPage(); }} > Pages </p>
                                    <img src={down} onClick={(e) => { setPageSort(!pageSort); sortByPage(); }} alt="Down" />
                                </div>
                                <div className={`team-category ${pubicSort === false ? "down-arrow" : "up-arrow"}`} >
                                    <p className="table-title" onClick={(e) => { setPubicSort(!pubicSort); sortByPubic(); }} > Publications   </p>
                                    <img src={down} onClick={e => { setPubicSort(!pubicSort); sortByPubic(); }} alt="Down" />
                                    </div>
                                <div className={`date-user ${newsSort === false ? "down-arrow" : "up-arrow"}`}>
                                    <p className="table-country" onClick={(e) => { setNewsSort(!newsSort); sortByNews(); }} > Newsletters </p>
                                    <img src={down} onClick={(e) => { setNewsSort(!newsSort); sortByNews(); }} alt="Down" />
                                </div>
                                <div className="actions">Actions</div>
                            </div>
                            {isLoading ?
                                <div className="loader-container">
                                    <div className="loader"></div>
                                </div>
                                :                            
                                (categoriesData.length > 0) ?
                                categoriesData.map((item, index) => {
                                    return (
                                        <div className="table-item" key={item._id}>
                                            <div className="table-status">
                                                <div className="user-status">
                                                    <input type="checkbox" checked={selectedItems.includes( item._id )} onChange={() => handleCheckboxChange(item._id) } />
                                                    <div className="user-content">{item.name}</div>
                                                </div>
                                            </div>
                                            <div className='page-count'>{item.page_count}</div>
                                            <div className="publication-count">{item.publication_count}</div>
                                            <div className="newsletter-count"><span>{item.newslatter_count}</span></div>

                                            <div className="table-action" ref={ref}>
                                                <span>
                                                    <img src={more}  onClick={() => togglePopup(index)} alt="More" />
                                                </span>
                                                {
                                                    (index === openPopupIndex) && 
                                                        <>
                                                        <div className='table-data-dropdown' ref={tableActionRef} >
                                                            <ul>
                                                                <li key={`edit-${item._id}`}>
                                                                    <span onClick={() => { handleEdit(item._id, item.name) }}>
                                                                        <img src={edit} alt="Icon" />
                                                                        <p>Edit</p>
                                                                    </span>
                                                                </li>
                                                                <li key={`delete-${item._id}`}>  
                                                                    <span onClick={() => { handleDelete([item._id]) }}>                          
                                                                        <img src={clear} alt="Icon" />
                                                                        <p>Delete</p>
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                                :
                                <NoResultsCategories type="categories" name="categories" onclickhandle={() => handleNewCategoryClick()} />
                            }
                        </div>
                        {
                            totalPage > 1 && 
                                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        }  
                    </div>
                </div>

            {(showCategoryField || showDeleteModel || showEditModel) && <div className="popupModalOverlay"></div>}
            {showCategoryField && <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} updateCategoriesData={updateCategoriesData} userId={id} currentPage={currentPage} setCurrentPage={setCurrentPage} fetchData={fetchData} setOpenNotify={setOpenNotify}/>}
            {showEditModel && <EditCategoryModel isOpen={showEditModel} onClose={closeEditModel} categoryId={selectedCatIds} categoryName={selectedCatName} updateCategoriesData={updateCategoriesData} userId={id} setEditNotify={setEditNotify}/> }
            {showDeleteModel && <DeleteCategoryModel isOpen={showDeleteModel} onClose={closeDeleteModel} categoryIds={selectedCatIds} updateCategoriesData={updateCategoriesData} categoriesData={categoriesData} allCategoriesData={allCategoriesData} fetchData={fetchData} fetchAllCategories={fetchAllCategories} setDeleteNotify={setDeleteNotify} setMultiDeteleNotify={setMultiDeteleNotify} setSelectCatLenght={setSelectCatLenght} setSelectedCatIds={setSelectedCatIds}/>}
            { openNotify && 
                <Notification title={`Category has been successfully added!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { editNotify && 
                <Notification title={`Category has been successfully edited!`} setOpenNotify={setEditNotify} type={"success"} openNotify={editNotify} />
            }
            { deleteNotify && 
                <Notification title={`The category was successfully deleted!`} setOpenNotify={setDeleteNotify} type={"success"} openNotify={deleteNotify} />
            }
            { multiDeleteNotify && 
                <Notification title={`${selectCatLenght} categories were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeleteNotify} />
            }
        </section>
    );
}

export default Categories;
