import React, { useEffect, useRef, useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import { Masonry } from "react-plock";
const PublicationsSection = ({ publicationsData, profile, hasCustomDomain }) => {
    const isotopeRef = useRef();
    const [postsPerPage, setPostsPerPage] = useState(3);
    const [blogData, setBlogData] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    useEffect(() => {
        loadInitialData();
    }, []);

    // Function to load initial data
    const loadInitialData = () => {
        // Slice the initial posts
        const firstPosts = Object?.keys(publicationsData.length > 0 && publicationsData)?.slice(0, postsPerPage);
        const initialData = firstPosts?.map((post) => publicationsData[post]);
        setBlogData(initialData);
    };

    // Function to fetch more data
    const fetchMoreData = () => {
        if (postsPerPage >= Object?.keys(publicationsData.length > 0 && publicationsData).length) {
            setHasMore(false);
            return;
        }

        // Simulate loading delay
        const offset = postsPerPage + 3;
        setTimeout(() => {
            const nextPosts = Object?.keys(publicationsData.length > 0 && publicationsData).slice(
                postsPerPage,
                offset
            );
            const newData = nextPosts?.map((post) => publicationsData[post]);

            setBlogData([...blogData, ...newData]);
            setPostsPerPage(offset);
        }, 1000);
    };

    const stripHtmlTags = (html) => {
        if (!html || typeof html !== "string") {
            return "";
        }
        return html.replace(/<[^>]*>?/gm, ""); // Regular expression to remove HTML tags
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { month: "short", day: "2-digit", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };


    const getPublicationLink = (blog) => {
        if (["Images", "Video", "Link"].includes(blog?.publication_type)) {
            if (hasCustomDomain?.verified) {
                return `${hasCustomDomain?.domain}/${profile}?pid=${blog?._id}`;
            } else {
                return `https://jourmal-next-front.vercel.app/${profile}?pid=${blog?._id}`;
            }
        }
        if (hasCustomDomain?.verified) {
            return `${hasCustomDomain?.domain}/${profile}/${blog?.url_address}`;

        } else {
            return `https://jourmal-next-front.vercel.app/${profile}/${blog?.url_address}`;

        }
    };
    return (
        <div className={`masonry-section articlesDetail`}>
            <InfiniteScroll
                className={"infiniteScroll"}
                dataLength={blogData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={""}
                style={{ display: "flex", flexWrap: "wrap" }}
            >
                <div className="articles-list" ref={isotopeRef}>
                    <Masonry
                        items={blogData}
                        config={{
                            columns: [1, 2, 3],
                            gap: [15, 30, 30],
                            media: [767, 990, 991],
                        }}
                        render={(blog, index) => {
                            let getUrl = Object.values(
                                blog?.cropImage ? blog?.cropImage : ""
                            );
                            return (
                                <div className={"isotopeItem"} key={blog._id}>
                                    <div className={"blogItem"}>
                                        <div className={"blogImage"}>
                                            <a
                                                href={getPublicationLink(blog)}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <img src={blog?.fetureImage} alt={blog.title} fill={false} quality={100} width={500} height={300} priority />
                                            </a>

                                        </div>
                                        <div className={"blogData"}>
                                            <h2 className={"blogIitle"}>

                                                <a
                                                    href={getPublicationLink(blog)}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    {blog.title}
                                                </a>

                                            </h2>
                                            <p className={"blogDate"}>{formatDate(blog.createdAt)}</p>
                                            <p className={"blogDescription"}>{stripHtmlTags(blog.description)}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </div>
            </InfiniteScroll>

        </div>
    )
}
export default PublicationsSection