
import '../../App.css';
import noResults from '../../images/noResults.svg'

function NoResultPages(props) {

    return (
        <div className='NoResults'>
            <div className="noResultsBody">
                <img src={noResults} alt="No results" />
                <p className="noResTitle">No newsletters yet</p>
                <p className='noResDesc'>It looks like you haven’t created any newsletters yet. Start crafting your content and keep your subscribers and informed and updated.</p>
            </div>
        </div>
    );
}

export default NoResultPages;
