import { useEffect, useRef, useState } from "react"
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Color from "@tiptap/extension-color"
import Highlight from "@tiptap/extension-highlight"
import Placeholder from "@tiptap/extension-placeholder"
import TaskList from "@tiptap/extension-task-list"
import TaskItem from "@tiptap/extension-task-item"
import Subscript from "@tiptap/extension-subscript"
import Superscript from "@tiptap/extension-superscript"
import Underline from "@tiptap/extension-underline"
import TextStyle from "@tiptap/extension-text-style"
import TextAlign from "@tiptap/extension-text-align"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import { all, createLowlight } from 'lowlight'
import './Editor.css'


import Document from "./Component/extensions/Document"
import Selection from "./Component/extensions/Selection"
import Heading from "./Component/extensions/Heading"
import TextMenu from "./Component/TextMenu/TextMenu"
import { LinkMenu } from "./Component/LinkMenu"
import { TrailingNode } from "./Component/extensions/TrailingNode"
import HorizontalRule from "./Component/extensions/HorizontalRule"
import SlashCommand from "./Component/SlashCommand/SlashCommand"
import FontSize from "./Component/extensions/FontSize"
import Link from "@tiptap/extension-link"
import Table from "./Component/extensions/Table/Table"
import TableCell from "./Component/extensions/Table/Cell"
import TableHeader from "./Component/extensions/Table/Header"
import TableRow from "./Component/extensions/Table/Row"
import Quote from "./Component/extensions/BlockquoteFigure/Quote"
import QuoteCaption from "./Component/extensions/BlockquoteFigure/QuoteCaption"
import BlockquoteFigure from "./Component/extensions/BlockquoteFigure/BlockquoteFigure"
import Columns from "./Component/extensions/MultiColumn/Columns"
import Column from "./Component/extensions/MultiColumn/Column"
import { TableOfContents, getHierarchicalIndexes } from "@tiptap-pro/extension-table-of-contents"
import { TableOfContentsNode } from "./Component/extensions/TableOfContentsNode/TableOfContentsNode"
import { Button } from "./Component/extensions/Button/Button"
import { BookmarkBlock } from "./Component/extensions/Bookmark/BookmarkBlock"
import { Bookmark } from "./Component/extensions/Bookmark/Bookmark"
import { Publications } from "./Component/extensions/Publications/Publications"
import { Product } from "./Component/extensions/Product/Product"
import { YouTubeBlock } from "./Component/extensions/Embeds/YouTube/YouTubeBlock"
import { YouTubeEmbeds } from "./Component/extensions/Embeds/YouTube/YouTubeEmbeds"
import { VimeoBlock } from "./Component/extensions/Embeds/Vimeo/VimeoBlock"
import { VimeoEmbeds } from "./Component/extensions/Embeds/Vimeo/VimeoEmbeds"
import { TwitterEmbeds } from "./Component/extensions/Embeds/Twitter/TwitterEmbeds"
import { TwitterBlock } from "./Component/extensions/Embeds/Twitter/TwitterBlock"
import { SpotifyEmbeds } from "./Component/extensions/Embeds/Spotify/SpotifyEmbeds"
import { SpotifyBlock } from "./Component/extensions/Embeds/Spotify/SpotifyBlock"
import { SoundCloudEmbeds } from "./Component/extensions/Embeds/SoundCloud/SoundCloudEmbeds"
import { SoundCloudBlock } from "./Component/extensions/Embeds/SoundCloud/SoundCloudBlock"
import { OtherEmbeds } from "./Component/extensions/Embeds/Other/OtherEmbeds"
import { OtherBlock } from "./Component/extensions/Embeds/Other/OtherBlock"
import { OtherLinkBlock } from "./Component/extensions/Embeds/Other/OtherLinkBlock"
import { ImageBlock } from "./Component/extensions/Image/ImageBlock"
import { ImageEmbeds } from "./Component/extensions/Image/Image"
import { AiWriter } from "./Component/extensions/AiWriter/AiWriter"
import { GalleryView } from "./Component/extensions/GalleryImage/GalleryView"
import { GalleryBlock } from "./Component/extensions/GalleryImage/GalleryBlock"
import { VideoEmbeds } from "./Component/extensions/Video/Video"
import { VideoBlock } from "./Component/extensions/Video/VideoBlock"
import { FileBlock } from "./Component/extensions/File/FileBlock"
import { File } from "./Component/extensions/File/File"
import { LogoSlider } from "./Component/extensions/LogoSlider/LogoSlider"
import { LogoSliderBlock } from "./Component/extensions/LogoSlider/LogoSliderBlock"
import { UrlPaste } from "./Component/extensions/URLpaste/UrlPaste"
import UrlPasteBlock from "./Component/extensions/URLpaste/UrlPasteBlock"
import ImageBubbleMenu from "./Component/extensions/Image/ImageBubbleMenu"
import ColumnBubbleMenu from "./Component/extensions/MultiColumn/ColumnBubbleMenu"
import PublicationBubbleMenu from "./Component/extensions/Publications/PublicationsBubbleMenu"
import VideoBubbleMenu from "./Component/extensions/Video/VideoBubbleMenu"
import GalleryBubbleMenu from "./Component/extensions/GalleryImage/GalleryBubbleMenu"
import ProductBubbleMenu from "./Component/extensions/Product/ProductBubbleMenu"
import FileBubbleMenu from "./Component/extensions/File/FileBubbleMenu"
import LogoSliderBubbleMenu from "./Component/extensions/LogoSlider/LogoSliderBubbleMenu"
import { ContentItemMenu } from "./Component/ContentItemMenu"
import TableRowMenu from "./Component/extensions/Table/menus/TableRow"
import TableColumnMenu from "./Component/extensions/Table/menus/TableColumn"
import ButtonBubbleMenu from "./Component/extensions/Button/ButtonBubbleMenu"
const lowlight = createLowlight(all)

export const EditorComponent = ({setUpdatedContent, editorContent, type="", id = "", setValidateEditorContent, setEditorContent}) => {
    const mainContainerRef = useRef(null)

    const editor = useEditor({
        onUpdate: ({ editor }) => {
            let html = editor.getHTML();
            html = html.replace(/<p>(\s|<br>)*<\/p>/g, '').trim();
            setUpdatedContent(html);
            
            if(id !== "" && id !== undefined){
                setEditorContent(html);
            }
            if(html?.length > 0){
                setValidateEditorContent(false)
            }
        },
        extensions: [
            Document,
            Selection,
            Heading.configure({
                levels: [1, 2, 3, 4, 5, 6],
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Highlight.configure({ multicolor: true }),
            Placeholder.configure({
                includeChildren: true,
                showOnlyCurrent: false,
                placeholder: ({ node }) => {
                    if (node.type.name === 'heading') {
                      return `Heading ${node.attrs.level}`;
                    }
          
                    return "Type '/' for commands";
                }
            }),
            TaskList,
            TaskItem.configure({
                nested: true,
            }),
            TrailingNode,
            StarterKit.configure({
                document: false,
                dropcursor: false,
                heading: false,
                horizontalRule: false,
                blockquote: false,
                history: true,
                codeBlock: false,
            }),
            TableOfContents.configure({
                getIndex: getHierarchicalIndexes,
            }),
            TableOfContentsNode,
            Link.configure({
                openOnClick: false,
                autolink: true,
                defaultProtocol: 'https',
            }),
            CodeBlockLowlight.configure({
                lowlight,
                defaultLanguage: 'javascript',
                HTMLAttributes: {
                    class: 'code-block',
                }
            }),
            HorizontalRule,
            SlashCommand,
            FontSize,
            Color,
            Subscript,
            Superscript,
            Underline,
            TextStyle,
            Table,
            TableCell,
            TableHeader,
            TableRow,
            Quote,
            QuoteCaption,
            BlockquoteFigure,
            Columns,
            Column,
            Button,
            BookmarkBlock,
            Bookmark,
            Publications,
            Product,
            YouTubeBlock,
            YouTubeEmbeds,
            VimeoBlock,
            VimeoEmbeds,
            TwitterEmbeds,
            TwitterBlock,
            SpotifyEmbeds,
            SpotifyBlock,
            SoundCloudEmbeds,
            SoundCloudBlock,
            OtherEmbeds,
            OtherBlock,
            OtherLinkBlock,
            ImageBlock,
            ImageEmbeds,
            AiWriter,
            GalleryView,
            GalleryBlock,
            VideoEmbeds,
            VideoBlock,
            FileBlock,
            File,
            LogoSlider,
            LogoSliderBlock,
            UrlPaste,
            UrlPasteBlock,
        ],
        editorProps: {
            handlePaste: function(view, event) {
                const clipboardData = event.clipboardData;
                const text = clipboardData.getData('text');

                // Simple URL validation
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const iframeRegex = /<iframe.*?src="(.*?)".*?<\/iframe>/g;
                if(iframeRegex.test(text)){
                    const { state } = view;
                    const { $from, empty } = state.selection;
                    const currentNode = $from.node();
                    const isCurrentNodeEmpty = currentNode.isTextblock && currentNode.content.size === 0;

                    if (empty && isCurrentNodeEmpty) {
                        event.preventDefault();
                        view.dispatch(
                            view.state.tr.replaceSelectionWith(
                              view.state.schema.nodes.urlPaste.create({url:text})
                            )
                        );
                        return true;
                    }
                } else {
                    if (urlRegex.test(text)) {
                        const { state } = view;
                        const { $from, empty } = state.selection;
                        const currentNode = $from.node();
                        const isCurrentNodeEmpty = currentNode.isTextblock && currentNode.content.size === 0;
    
                        if (empty && isCurrentNodeEmpty) {
                            event.preventDefault();
    
                            // Replace the URL with your custom element if the current node is empty
                            const url = text.match(urlRegex)[0];
    
                            view.dispatch(
                                view.state.tr.replaceSelectionWith(
                                  view.state.schema.nodes.urlPaste.create({url:url})
                                )
                            );
                            return true;
                        }
                    }
                }
                return false;
            },
            transformPastedHTML: (html) => {
                return html.replace(/<span.*?>/g, '').replace(/<\/span>/g, '');
            },
        },
        content: editorContent || "",
    })

    const [isOpen, setOpen] = useState(false)
    const [moreOption, setMoreOption] = useState(false);
    const menuRef = useRef();
    const dropdownRef = useRef();
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpen && setOpen && menuRef?.current && !menuRef?.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (moreOption && setMoreOption && dropdownRef?.current && !dropdownRef?.current.contains(e.target)) {
                setMoreOption(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [moreOption]);
    
    useEffect(() => {
        if (editor && editorContent) {
            if((id !== "") && (id !== undefined)){
                editor.commands.setContent(editorContent); // Set content in the editor
            }else{
                editor.commands.setContent(''); 
            }
        }
    }, [editor, editorContent,id]);

    return (
        <>
            <div className='tiptop-editor' ref={mainContainerRef}>
                <EditorContent editor={editor} />
                <TextMenu editor={editor} />
                <LinkMenu editor={editor} appendTo={mainContainerRef} />
                <ContentItemMenu editor={editor} />
                <TableRowMenu editor={editor} appendTo={mainContainerRef} />
                <TableColumnMenu editor={editor} appendTo={mainContainerRef} />
                <ButtonBubbleMenu editor={editor} appendTo={mainContainerRef} />
                <ImageBubbleMenu editor={editor} appendTo={mainContainerRef} type={type} />
                <ColumnBubbleMenu editor={editor} appendTo={mainContainerRef} />
                <PublicationBubbleMenu editor={editor} appendTo={mainContainerRef} />
                <VideoBubbleMenu editor={editor} appendTo={mainContainerRef} type={type} />
                <GalleryBubbleMenu editor={editor} appendTo={mainContainerRef} type={type} />
                <ProductBubbleMenu editor={editor} appendTo={mainContainerRef} />
                <FileBubbleMenu editor={editor} appendTo={mainContainerRef} />
                <LogoSliderBubbleMenu editor={editor} appendTo={mainContainerRef} type={type} />
            </div>
        </>
    )
}