import React, { useState, useEffect } from "react";

import {formattedDate, getCountryName, getCountryWithFlag} from "../../utils/functions";

import avatar from "../../images/profile-picture 1.png";
import { Link } from "react-router-dom";
import { DataTableList } from "../../components/DataTable/DataTableList";

const DashboardNewUserTable = ({userData, isLoading}) => {
    const [columnWidths, setColumnWidths] = useState([]);

    useEffect(() => {
            if (userData?.length > 0) {
                calculateColumnWidths();
            }
        }, [userData]);
    
        const calculateColumnWidths = () => {
            const tempWidths = userData?.reduce((acc, row) => {
                Object.keys(row).forEach((key) => {
                    const dataValue = row[key].toString();
                    const title = row[key];
    
                    let maxContentLength = 0;
                    if (key === 'country') {
                        const countryFlagContent = getCountryName(row?.country);
                        maxContentLength = Math.max(countryFlagContent.length - 3); // Add extra padding for flag content
                    }  else {
                        maxContentLength = Math.max(dataValue.length, title.length);
                    }
                    if (!acc[key] || maxContentLength > acc[key]) {
                        acc[key] = maxContentLength;
                    }
                });
                return acc;
            }, {});
            if(tempWidths !== undefined){
                const widthMap = Object.keys(tempWidths).reduce((acc, key) => {
                    
                        acc[key] = `${tempWidths[key] * 10 + 40}px`;
                    return acc;
                }, {});
    
                setColumnWidths(widthMap); // Set the calculated column widths
            }
        
        };

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 500,
                fontSize: '14px',
            },
        },
    };

    const customTitleSort = (rowA, rowB) => {
        const titleA = rowA?.displayName.toString().toLowerCase();
        const titleB = rowB?.displayName.toString().toLowerCase();
    
        // Natural sort comparison for mixed numbers and letters
        return titleA.localeCompare(titleB, undefined, { numeric: true, sensitivity: 'base' });
    };

    const columns = [
        {
            name: 'Full Name',
            selector: (row) => row.displayName,
            cell: (row) => {
                return <div className="user-content teamTable">
                            <img src={`${row?.profileMedia == "" ? avatar : row?.profileMedia }`} />
                            <div className="user-brief">
                                <span className="user-name">
                                    <Link to={`/users/${row._id}`}>{row?.displayName || 'NO NAME'}</Link>
                                </span>
                                <p className="user-mail">{row.email}</p>
                            </div>
                        </div>
            },
            sortable: true,
            sortFunction: customTitleSort,
            minWidth: columnWidths['email'] && parseInt(columnWidths['email']) > 300 ? '300px' : columnWidths['displayName'],
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
        },
        {
            name: 'Country',
            selector: (row) => row.country,
            width: columnWidths['country'] || '150px',
            cell: (row) => {
                return <div className="table-country">
                            <span className="country-flag">{getCountryWithFlag(row.country)}</span>
                        </div>
            },
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px" },
            sortable: true,
        },
        {
            name: 'Register Date',
            sortable: true,
            selector: (row) => row.createdAt,
            cell: (row) => {
                return <div className="subscription-date">
                            <span>{formattedDate(row.createdAt)}</span>
                        </div>
            },
            paddingLeft: 0,
            width: '150px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", fontSize: "14px", width: '150px', },
        },
        {
            name: 'Plan',
            sortable: true,
            selector: (row) => row?.planDetail?.plan,
            cell: (row) => {
                return <div className="">
                        <span className={`plan-btn ${row.planDetail !== 'FREE' && 'pro-btn'}`}>{`${(row?.planDetail?.plan === "free_plan" || row?.planDetail?.plan === "basic_plan" || row?.planDetail === "FREE") ? "Free" : (row?.planDetail?.plan === "unlimited_plan") ? "Unlimited" : "Personal Assistant"}`}</span>
                    </div>
            },
            paddingLeft: 0,
            width: '130px',
            style: { paddingLeft: 0, paddingRight: "16px", paddingTop: "16px", paddingBottom: "16px", width: '130px', fontSize: "14px" },
        },
    ];

    return (
        <>
            <div className="table-data-wrapper table-pagination-wrapper">
                <h3 className="heading">New Users</h3>
                <div className="pagesTable dataTable-list">
                    <DataTableList
                        columns={columns}
                        data={userData}
                        progressPending={isLoading}
                        progressComponent={<div className="loader-container"> <div className="loader"></div> </div>}
                        customStyles={customStyles}
                        selectableRowsComponent="input"
                        selectableRows={false}
                        
                    />
                </div>
            </div>
        </>
    );
};
export default DashboardNewUserTable;
