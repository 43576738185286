import { useState } from 'react';
import '../.././App.css';
import axios from 'axios';
import VerifyCode from './VerificationCode';

function TwoFactorPhone(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [showOptions, setShowOptions] = useState(true);
    const [showVerify, setShowVerify] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const phonePattern = /^[0-9]{10,15}$/;

    const handleBack = () => {
        if (props.selectedOption === 'login_mobile') {
            if (showVerify === true) {
                setShowVerify(false);
                setShowOptions(true);
            } else {
                props.setShowFieldPopup(false);
                props.setShowTwoFactorOption(true);
            }
        } else if (props.selectedOption === 'login_email') {
            props.setShowTwoFactorOption(true);
            props.setShowFieldPopup(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendBtn();
        }
        const allowedKeys = /[0-9]/;
        if (!allowedKeys.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleSendBtn = async (e) => {
        if (props.selectedOption === 'login_mobile') {
            if (phoneNumber.trim() === '') {
                setPhoneNumberError('Please enter a phone number');
                setEmailError('');
                return;
            }
            if ((phoneNumber.trim() !== '') && (!phonePattern.test(phoneNumber))) {
                setPhoneNumberError('Please enter a valid phone number');
                setEmailError('');
                return;
            } else {
                setPhoneNumberError('');
            }
            setTimeout(() => {
                setIsLoading(false);
                setShowVerify(true);
                setShowOptions(false);
            }, 2000);
        } else if (props.selectedOption === 'login_email') {
            e.preventDefault();
            try {
                setIsLoading(true);
                if (props?.status?.email) {

                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/twoFactorEmailOTP/${JSON.parse(localStorage.getItem("userData"))?._id}`,
                        { email: props?.status?.email }
                    );
                    props.setEditNotify(true)
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error)
            }

        }
        setIsLoading(true);
    };

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
        if (e.target.value.trim() !== '') {
            setPhoneNumberError('');
        }
    };

    return (
        <>
            {showOptions && (
                <>
                    {props.selectedOption === 'login_mobile' &&
                        <>
                            <div className="popupContent">
                                <p className="popupTitle">Set Two Factor Authentication</p>
                                <p className="popupDesc">Please enter your phone number in the field provided. Once you submit phone number, you will receive an email with a code.</p>
                            </div>
                            <div className='input-phNumber'>
                                <p className='emailAddress-text'>Phone number</p>
                                <input className='background_icon icon-public-number' type="number" placeholder='Enter your phone number' onChange={handlePhoneNumber} onKeyPress={(e) => handleKeyPress(e)} />
                                {phoneNumberError && <p className="validateError">{phoneNumberError}</p>}
                            </div>
                            <div className="popupButtons">
                                <button className='settingsWhite billingSaveBtn button secondary-button'onClick={handleBack}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                        <path d="M12.3332 5H1.6665M1.6665 5L5.6665 9M1.6665 5L5.6665 1" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Back</button>
                                <button className='settingsBlue billingSaveBtn sendCodeBtn button primary-button' onClick={handleSendBtn} >
                                    {isLoading ?
                                        <div className="settingPage-loader">
                                            <div className="ring-loader"></div>
                                        </div>
                                        : "Send Code"}</button>
                            </div>
                        </>
                    }
                    {props.selectedOption === 'login_email' ? (
                        <>
                            <VerifyCode setShowVerify={setShowVerify} setShowOptions={setShowOptions} setIsAddFactorOpen={props.setIsAddFactorOpen}
                                setShowTwoFactorOption={props?.setShowTwoFactorOption}
                                handleBack={handleBack}
                                selectedOption={props.selectedOption}
                                email={props.selectedOption === 'login_email' ? props?.status?.email : undefined}
                                phoneNumber={props.selectedOption === 'login_mobile' ? phoneNumber : undefined} isActiveField={props?.isActiveField} setStatus={props.setStatus}  setOpenNotify={props?.setOpenNotify}/>
                        </>
                    ) : ""}


                </>
            )}

            {(showVerify && !isLoading) &&
                <VerifyCode setShowVerify={setShowVerify} setShowOptions={setShowOptions} setIsAddFactorOpen={props.setIsAddFactorOpen}
                    handleBack={handleBack}
                    email={props.selectedOption === 'login_email' ? props?.status?.email : undefined}
                    phoneNumber={props.selectedOption === 'login_mobile' ? phoneNumber : undefined} isActiveField={props?.isActiveField} setStatus={props.setStatus} />
            }

        </>
    );
}

export default TwoFactorPhone;
