import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import close from "../../images/close.svg"

function DeleteTeamMember(props) {
    const {item, isOpen, refrence, onClose, userMemberList, setOpen, selectedItems, setUserMemberList, setSelectAll, setSelectedItems, togglePopup, navigate, toggleSelect, setToggleSelect, fetchData, setOpenPopupitem } = props;

    const history = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [userPassword, setUserPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState(false);
    const [error, setError] = useState(false);
    const [readOnly, setReadOnly] = useState(true);

    const deleteUser = async (itemIDs) => {        
        try {
            setError(false);
            setValidatePassword(false);

            if (userPassword.length > 0) {
                setIsLoading(true);

                const deletePromis = itemIDs?.map(async (itemID) => {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/admin/deleteuser/${JSON.parse(localStorage.getItem("userData"))?._id}`,
                        { password: userPassword, id:itemID  }
                    );
                });

                await Promise.all(deletePromis);

                if( userMemberList ) {
                    const idsToRemove = Array.isArray(itemIDs) ? itemIDs : [itemIDs];
                    const updatedUsers = userMemberList.filter(
                        (user) => !idsToRemove.includes(user._id)
                    );
                    setUserMemberList(updatedUsers);
                    setSelectedItems([])
                    setOpenPopupitem([])
                    togglePopup(null);
                    setSelectAll(false);
                    setToggleSelect(!toggleSelect)
                    await fetchData()
                } else {
                    document.body.classList.remove('hidden-message');
                    { navigate && history(`/${navigate}`)}                    
                }

                setOpen(false);
            } else {
                setValidatePassword(true)
            }
            
            setIsLoading(false);
            setUserPassword("");
        }  catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                setError(true);
            } else {
                console.log(error, "error");
            }
        }
    };

    const handleKeyPress = (e)=>{
        if (e.key === 'Enter') {
            deleteUser( item && item.length != 0 ? [item._id] : selectedItems ) 
        }
    }
    
    // Function to get display name from _id
    const getDisplayNameById = (id) => {
        const user = userMemberList.find(user => user._id === id);
        return user ? user.displayName : "";
    };

    return (
        <>
            {isOpen && (
                <>
                    <div className="popupModalOverlay"></div>
                    <div className='popupModal' ref={refrence}>
                        <div className='popupTopClose'>
                            <img src={close} alt="close-icon" onClick={onClose}></img>
                        </div>
                        <h3 className='popupModalTitle'>Delete Team {item && item.length != 0 ? 'Member' : 'Members' }</h3>
                        {item && item.length != 0 ? (
                            <p className='popupModalContent'>Are you sure you want to delete <span>{item?.displayName || `${item.firstName} ${item.lastName}` }</span>? This action can’t be undone.</p>
                        ) : (
                            <p className='popupModalContent'>Are you sure you want to delete {selectedItems && selectedItems.length > 1 ? 'these users' : <span>{getDisplayNameById(selectedItems[0])}</span>}? This action can’t be undone. </p>

                        )}
                        <div className='popupFields password-field'>
                            <label className='fieldsLabel'>Enter password</label>
                            <input type="password" readOnly={readOnly} onFocus={ () => setReadOnly(false) } onBlur={ () => setReadOnly(true) } name='password' placeholder='Enter your password' autoComplete='off' onChange={(e) => setUserPassword(e.target.value)} onKeyPress={(e) => handleKeyPress(e)}/>
                            {validatePassword === true && (
                                <p className="validateError">The field is not filled</p>
                            )}
                            {error === true && (
                                <p className="validateError">Passwords do not match</p>
                            )}
                        </div>
                        <div className="popupModalBtn">
                            <button className="cancel secondary-button button" type="button" onClick={onClose}>Cancel</button>
                            <button className="savePicture primary-button button" type="button" onClick={() => deleteUser( (item && item.length != 0 && item !== undefined) ? [item._id] : selectedItems )}>
                                {isLoading ?
                                    <div className="settingPage-loader">
                                        <div className="ring-loader"></div>
                                    </div>
                                : "Delete"}
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default DeleteTeamMember;