import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import moment from "moment";
import axios from 'axios';

import { useParams } from "react-router-dom";
import moreIcon from "../../images/more.svg";
import Delete from "../../images/delete.svg";
import Down from "../../images/down-arrow.svg";
import penIcon from "../../images/pen-img.svg";
import linkIMG from "../../images/link.svg";
import iconDropDown from "../../images/icondropdown.svg";
import CheckboxActionDropdown from "../../components/CheckboxActionDropdown";
import search from "../../images/search.svg";
import plus from '../../images/plus.svg'

import Pagination from "../../components/Pagination";
import NoResultsPages from "./NoResultPages";
import DeletePageModal from "./DeletePageModel";
import Notification from "../../components/Notification";

const Pages = (props) => {
    const { id } = useParams();
    const ref = useRef();
    const pageStatusRef = useRef();
    const popupRef = useRef();

    props.setActivePage("Pages");
    props.setHideUserInfo(false);

    const baseUrl = `https://jourmal-next-front.vercel.app/${props.profileSlug}`;

    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openPopupitem, setOpenPopupitem] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [userStatusSelect, setPageStatusSelect] = useState(false);
    const [selectedPageStatus, setSelectedPageStatus] = useState([]);

    const homagepage_id = btoa(`homepage-${id}`);
    const contactpage_id = btoa(`contactpage-${id}`)
    const [originalData, setOriginalData] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false)

    const statusList = ['Published', 'Draft', 'Deleted'];
    const excludedItemIds = [homagepage_id, contactpage_id];
    const [readFields, setReadFields] = useState(true);
    const [openNotify, setOpenNotify] = useState(false);
    const [multiDeteleNotify, setMultiDeteleNotify] = useState(false);
    const [selectedPageLenght, setSelectedPageLenght] = useState(0)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pageStatusRef.current && !pageStatusRef.current.contains(event.target)) {
                setPageStatusSelect(false);
            }

            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopupitem([]);
                setOpen(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const fetchData = async () => {
        const search = searchInput.toLowerCase();
        const status = selectedPageStatus.length > 0 ? selectedPageStatus.join(',') : '';
        setIsLoading(false)
        try {
            setIsLoading(true)
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/pages/pageslist/${id}?page=${currentPage}&search=${search}&status=${status}`,
            )

            const result = response?.data?.result;

            setOriginalData(result);
            setTotalPage(response?.data?.totalPages || 1)
            //setAllFiltered(response?.data?.result);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error, "error");
        }
    }
    useEffect(() => {
        setCurrentPage(1);
    }, [selectedPageStatus]);
    
    useEffect(() => {
        fetchData();
    }, [id, currentPage, searchInput, selectedPageStatus]);

    useEffect(() => {
        var result = [];
        if (originalData !== undefined) {
            originalData.forEach(element => {
                if (result.indexOf(element.status) === -1) {
                    result.push(element.status);
                }
            });
        } else {
            setPageStatusSelect(false);
            setOriginalData(originalData);
        }
    }, [originalData])

    const togglePopup = (item) => {
        if (openPopupitem && item && item._id === openPopupitem._id) {
            setOpenPopupitem([]);
        } else {
            setOpen(false);
            setOpenPopupitem(item);
        }
    };

    const [titleSort, setTitleSort] = useState(false);
    function sortByTitle() {
        var result;
        if (titleSort === true) {
            result = [...originalData].sort((a, b) =>
                a.title.localeCompare(b.title)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.title.localeCompare(b.title)
            );
        }
        setOriginalData(result);
    }

    const [statusSort, setStatusSort] = useState(false);
    function sortByStatus() {
        var result;
        if (statusSort === true) {
            result = [...originalData].sort((a, b) =>
                a.status.localeCompare(b.status)
            );
        } else {
            result = [...originalData].sort(
                (a, b) => -1 * a.status.localeCompare(b.status)
            );
        }
        setOriginalData(result);
    }

    const [dateSort, setDateSort] = useState(false);
    const [lastUpdatedDateSort, setLastUpdatedSort] = useState(false);
    function sortBy(key) {
        return function (a, b) {
            var now = moment();

            var dateA = moment(a[key]).format("ll");
            var daysA = moment(dateA).diff(now, "Days");

            var dateB = moment(b[key]).format("ll");
            var daysB = moment(dateB).diff(now, "Days");

            if (dateSort) {
                return daysA - daysB;
            } else if (lastUpdatedDateSort) {
                // Use the appropriate property for last updated date
                // Assuming it's named 'lastUpdatedDate', replace it with your actual property name
                var lastUpdatedDateA = moment(a.lastUpdatedDate).format("ll");
                var lastUpdatedDaysA = moment(lastUpdatedDateA).diff(now, "Days");

                var lastUpdatedDateB = moment(b.lastUpdatedDate).format("ll");
                var lastUpdatedDaysB = moment(lastUpdatedDateB).diff(now, "Days");

                return lastUpdatedDaysA - lastUpdatedDaysB;
            }
            return 0; // Default case
        };
    }

    function sortByDate() {
        var result = [...originalData].sort(sortBy("createdAt"));
        setOriginalData(dateSort ? result : result.reverse());
        setDateSort(!dateSort);
    }

    function sortByLastUpdated() {
        var result = [...originalData].sort(sortBy("updatedAt")); // Replace witfh your actual property name
        setOriginalData(lastUpdatedDateSort ? result : result.reverse());
        setLastUpdatedSort(!lastUpdatedDateSort);
    }

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            // const allItemIds = originalData.map((item) => item._id).filter((itemId) => !excludedItemIds.includes(itemId));
            // setSelectedItems(allItemIds);

            const allItemIds = originalData.map((item) => item._id);
            setSelectedItems(allItemIds);
        }
        setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (itemId) => {
        // if (excludedItemIds.includes(itemId)) {
        //     return;
        // }
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (_id) => _id !== itemId
                );
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            } else {
                const updatedSelectedItems = [...prevSelectedItems, itemId];
                setSelectAll(
                    updatedSelectedItems.length === originalData.length
                );
                return updatedSelectedItems;
            }
        });
    };

    const handleActionEvent = (e) => {
        togglePopup(null);
    };

    const handleDeleteActionEvent = (item) => {
        if (item.length != 0 || selectedItems.length != 0) {
            setOpen(true);
        }
    };

    const deletePage = (itemID) => {
        const idsToRemove = Array.isArray(itemID) ? itemID : [itemID];
        const updatedUsers = originalData.filter(
            (page) => !idsToRemove.includes(page._id)
        );
        setOriginalData(updatedUsers);
        togglePopup(null);
        setOpen(false);
        setSelectAll(false);
    };

    const onClose = (e) => {
        setOpen(false);
    };

    function formattedDate(date) {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };

        var newDate = new Date(date);
        var formatteddate = newDate.toLocaleDateString('en-US', options);
        return formatteddate;
    }

    return (
        <>
            <section className="dashboard">
                <div className="pagesTableWrapper">
                    <div className="pagesSearch pagesNew">
                        <div className="pageSearchTop">
                            <div className="pageSearchInput">
                                <input
                                    type="text"
                                    name="pages_search"
                                    id="pages_search"
                                    className="inputFields"
                                    placeholder={"Search pages..."}
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    autocomplete="off"
                                    readOnly={readFields}
                                    onFocus={() => setReadFields(false)}
                                    onBlur={() => setReadFields(true)}
                                />
                                <img src={search} alt="Search" />
                            </div>
                            <div className="userrole-dropdown" ref={pageStatusRef}>
                                <button className="selectFields" onClick={(e) => setPageStatusSelect(!userStatusSelect)}>
                                    All Pages{" "} {selectedPageStatus.length > 0 ? `(${selectedPageStatus.length})` : ""} <img src={iconDropDown} alt="User Roles" />
                                </button>
                                {
                                    (userStatusSelect === true) ? <CheckboxActionDropdown options={statusList} selectedOptions={selectedPageStatus} changeOption={setSelectedPageStatus} /> : ''
                                }
                            </div>
                        </div>
                        <div className="actions-buttons">
                            <div className="deleteIcon">
                                <img onClick={() => handleDeleteActionEvent([])} className="deleteIconImage" src={Delete} alt="Clear Input" />
                            </div>
                            <div className="add-new-member">
                                <Link to={`/users/${id}/pages/page/`}>
                                    <button className="form-btn primary-button button">
                                        <img src={plus} alt="plus" />
                                        New Page
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={originalData && originalData.length > 0 ? "pagesTable" : "pagesTable tableBottomRounded"} >
                        <div className="table-data-wrapper table-pagination-wrapper">
                            <div className="table-data table-six-column">
                                <div className="table-head">
                                    <div className="table-status">
                                        <input type={"checkbox"} checked={selectAll} onChange={handleSelectAll} />
                                        <div className={`title ${titleSort === false ? "down-arrow" : "up-arrow"}`} onClick={(e) => { setTitleSort(!titleSort); sortByTitle(); }} >
                                            <p className="table-title">Title</p>
                                            <img src={Down} onClick={(e) => { setTitleSort(!titleSort); sortByTitle(); }} alt="Down" />
                                        </div>
                                    </div>
                                    <div className={`team-member-role ${statusSort === false ? "down-arrow" : "up-arrow"}`} >
                                        <p className="table-title" onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} >Status</p>
                                        <img src={Down} onClick={(e) => { setStatusSort(!statusSort); sortByStatus(); }} alt="Down" />
                                    </div>
                                    <div className={`country ${dateSort === false ? "down-arrow" : "up-arrow"}`}>
                                        <p className="table-country" onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} > Date </p>
                                        <img src={Down} onClick={(e) => { setDateSort(!dateSort); sortByDate(); }} alt="Down" />
                                    </div>
                                    <div className={`last-updated-date ${lastUpdatedDateSort === false ? "down-arrow" : "up-arrow"}`}>
                                        <p
                                            className="table-title"
                                            onClick={(e) => { setLastUpdatedSort(!lastUpdatedDateSort); sortByLastUpdated(); }}
                                        >
                                            Last Updated
                                        </p>
                                        <img src={Down} onClick={(e) => { setLastUpdatedSort(!lastUpdatedDateSort); sortByLastUpdated(); }} alt="Down" />
                                    </div>
                                    <div className="link">Link</div>
                                    <div className="actions">Actions</div>
                                </div>
                                {isLoading ?
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                    </div>
                                    :
                                    (originalData && originalData.length > 0) ?
                                        originalData.map((item) => {
                                            return (
                                                <div className="table-item" key={item._id}>
                                                    <div className="table-title">
                                                        <div className="user-status">
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedItems.includes(item._id)}
                                                                onChange={() => handleCheckboxChange(item._id)}
                                                            />
                                                            <Link to={`/users/${id}/pages/page/${item._id}`}>{item.title}</Link>
                                                            {/* <div className="user-content">{item.title}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className={`table-status ${item.status}`}>
                                                        <span className="status-btn">{item.status}</span>
                                                    </div>
                                                    <div className="table-date">
                                                        <span>{formattedDate(item.createdAt)}</span>
                                                    </div>
                                                    <div className="table-date">
                                                        <span>{formattedDate(item.updatedAt)}</span>
                                                    </div>
                                                    <div className="table-link">
                                                        <Link to={`${baseUrl}/${item.url_address}`} target={'_blank'} className="link-btn">
                                                            <img className="socialIcon" src={linkIMG} alt="SocialIcon" />
                                                            Link
                                                        </Link>
                                                    </div>
                                                    <div className="table-action" onClick={() => togglePopup(item)} ref={ref} >
                                                        <img src={moreIcon} alt="More" />
                                                    </div>
                                                    {openPopupitem &&
                                                        openPopupitem._id == item._id && (
                                                            <div className="table-data-dropdown" ref={popupRef}>
                                                                <ul>
                                                                    <li>
                                                                        <span>
                                                                            <Link className="table-edit-action" to={`/users/${id}/pages/page/${item._id}`} onClick={handleActionEvent} >
                                                                                <img src={penIcon} alt="pen-icon" />
                                                                                Edit
                                                                            </Link>
                                                                        </span>
                                                                    </li>
                                                                    {!excludedItemIds.includes(item._id) &&
                                                                        <li>
                                                                            <span onClick={() => handleDeleteActionEvent(item)} >
                                                                                <img src={Delete} alt="pen-icon" />
                                                                                Delete
                                                                            </span>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )}
                                                </div>
                                            );
                                        })
                                        :
                                        <NoResultsPages />
                                }
                            </div>
                            {
                                totalPage > 1 &&
                                <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            }
                        </div>
                    </div>
                </div>
            </section>

            <DeletePageModal isOpen={isOpen} item={openPopupitem} selectedItems={selectedItems} onClose={onClose} setOpen={setOpen} refrence={popupRef} originalData={originalData} fetchData={fetchData} setOpenNotify={setOpenNotify} setMultiDeteleNotify={setMultiDeteleNotify} setSelectedPageLenght={setSelectedPageLenght} setSelectedItems={setSelectedItems}/>
            { openNotify && 
                <Notification title={`The page was successfully deleted!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
            }
            { multiDeteleNotify && 
                <Notification title={`${selectedPageLenght} pages were successfully deleted!`} setOpenNotify={setMultiDeteleNotify} type={"success"} openNotify={multiDeteleNotify} />
            }
        </>
    );
}
export default Pages;