import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Calendar } from 'react-date-range';
import moment from 'moment-timezone';
import axios from 'axios';
import { DateTime } from 'luxon';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import send from "../../images/send.svg";
import featured from "../../images/featured.svg";
import search from "../../images/icon-search.svg";
import dots from "../../images/dots.svg";
import Loader from "../../components/Loader/Loader";
import coverImg from "../../images/picture.svg";
import plus from "../../images/plus_author.svg";
import iconDropDown from "../../images/icondropdown.svg";
import calendar from '../../images/calendar.svg'
import Home from '../../images/icon-home.svg';
import iconDivider from "../../images/chevron-right-light.svg";
import iconLock from "../../images/locked.svg"
import ImageEditor from "../../components/ImageEditor/ImageEditor";
import AddCategoryPage from "../Users/AddCategoryPage";
import DeleteNewsletterModal from "../Users/DeleteNewsletterModal";
import Error from "../error/Error404";
import UploadMediaPopup from "../../../src/Pages/Users/UploadMediaPopup"
import { dataURLtoFile } from "../../utils/functions"
import Notification from "../../components/Notification";
import Select, { components } from "react-select";
import { EditorComponent } from "../../components/TiptapEditor/Editor";
import PreviewSuccessPopUp from "../../components/modal/PreviewSuccessPopup";


function convertToSlug(str) {
    // Convert the string to lowercase
    let slug = str.toLowerCase();

    // Remove starting and ending spaces  
    slug = slug.trim();

    // Replace spaces with a single hyphen "-"
    slug = slug.replace(/\s+/g, '-').replace(/\.+/g, '-');

    return slug;
}

function NewNewsletter({ userData, setActivePage, setHideUserInfo, billingDetails, billingData, showUpgradeModel, setShowUpgradeModel }) {
    setActivePage("Newsletters");
    setHideUserInfo(true);
    const { id, newsletterid } = useParams();


    const popupRef = useRef();
    const modelRef = useRef(null);
    const subscriberRef = useRef();

    const [canAccess, setCanAccess] = useState(true);
    const [NewsLetterID, setNewsLetterID] = useState(null);
    const fileInputRef = useRef(null);
    const [featureImage, setFeatureImage] = useState(null);
    const [saveFeatureImage, setSaveFeatureImage] = useState(null);
    const [oldImage, setOldImage] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [loadingFeatureImg, setloadingFeatureImg] = useState(false);
    const [replaceImgTimeLoading, setReplaceImgTimeLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [featuredImageModal, setFeaturedImageModal] = useState(false);
    const modalRef = useRef(null);
    const [zoom, setZoom] = useState(1);
    const [oldZoom, setOldZoom] = useState(1);
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [replcaeImageZoom, setReplcaeImageZoom] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [status, setStatus] = useState("");
    const [dbPageTitle, setdbPageTitle] = useState('');
    const [validateTitle, setValidateTitle] = useState(false);
    const [validateEditorContent, setValidateEditorContent] = useState(false);
    const [validateCategories, setValidateCategories] = useState(false);
    const [isSlugChanged, setIslugChanged] = useState(false);
    const [openPagePopup, setOpenPagePopup] = useState(false);
    const [popupData, setPopupData] = useState({pageImage: ''});
    const [isPopupOpenDeleteAccount, setIsPopupOpenDeleteAccount] = useState(false);
    const [data, setData] = useState([]);
    const [apiCall, setApiCall] = useState(false)

    // Start Search
    const [values, setValues] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [showCategoryField, setShowCategoryField] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    // Schedule
    let userTimeZone = localStorage.getItem("userTimeZone");
    let userTimeZoneParts = (userTimeZone && typeof userTimeZone === 'string') ? userTimeZone.split(' ') : [];
    let scheduleTimezone = (userTimeZoneParts.length > 1) ? userTimeZoneParts[1] : new Intl.DateTimeFormat().resolvedOptions().timeZone;

    let clientTimeZone = moment.tz.names().find((tz) => tz.includes(scheduleTimezone));
    let clientTimeFormate = moment.tz(clientTimeZone).format();
    let clientDate = DateTime.fromISO(clientTimeFormate, { zone: clientTimeZone });

    let clientDateyear = clientDate.year;
    let clientDatemonth = clientDate.month - 1;
    let clientDatedate = clientDate.day;
    let clientDatehours = clientDate.hour + 1;
    let clientDateminutes = clientDate.minute;
    let clientCurrentDate = new Date(clientDateyear, clientDatemonth, clientDatedate, clientDatehours, clientDateminutes);

    const calendarRef = useRef(null);
    const dateSelectorRef = useRef(null);
    const hourRef = useRef(null);
    const minuteRef = useRef(null);
    const [isSchedule, setIsSchedule] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [scheduleDate, setScheduleDate] = useState(clientCurrentDate);
    const [scheduleHour, setScheduleHour] = useState(scheduleDate.getHours().toString().padStart(2, '0'));
    const [scheduleMinute, setScheduleMinute] = useState(scheduleDate.getMinutes().toString().padStart(2, '0'));

    const [saveScheduleDate, setSaveScheduleDate] = useState(false);
    const [saveScheduleHour, setSaveScheduleHour] = useState(false);
    const [saveScheduleMinute, setSaveScheduleMinute] = useState(false);

    const [timeErrorMessage, setTimeErrorMessage] = useState('');

    const [showUploadMediaPopup, setShowUploadMediaPopup] = useState(false);
    const [selectedFeatureImage, setSelectedFeatureImage] = useState('');
    const [selectMediaLoading, setSelectMediaLoading] = useState(false);
    const [selectedFeatureImageData, setSelectedFeatureImageData] = useState('');
    const [uploadMediaPopupFor, setUploadMediaPopupFor] = useState('');
    const [uploadMediaPopupTab, setUploadMediaPopupTab] = useState('upload');
    const [imageFile, setImageFile] = useState(null);
    const [featureCropedImageId, setFeatureCropedImageId] = useState('');
    const [featureImageId, setFeatureImageId] = useState('');
    const [scheduleNotify, setScheduleNotify] = useState(false)
    const [subscriberSelect, setSubscriberSelect] = useState('All Subscribers');
    const [subscriberTypeOpen, setSubscriberTypeOpen] = useState(false);
    const [subscriberInterval, setSubscriberInterval] = useState([{ value: "All Subscribers", label: "All Subscribers", slug:'all' }]);
    const [sendTo, setSendTo] = useState({ value: "All Subscribers", label: "All Subscribers", slug:'all' })
    const [sendToOpen, setSendToOpen] = useState(true)
    const [categoriesNotify, setCategoriesNotify] = useState(false)
    const [isPreviewLoading, setPreviewLoading] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(true);
    const [previewErrorMessage, setPreviewErrorMessage] = useState('');
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const statusValue = queryParams.get("status");

    let notificationTimeoutId = '';

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpenDeleteAccount(false);
            }
            if (modelRef.current && !modelRef.current.contains(event.target)) {
                setShowUpgradeModel(false);
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener("mousedown", handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(statusValue === "sent"){
            setOpenPagePopup(true)
        }
      },[statusValue])

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (selectedFeatureImage !== '') {
            setBlobData();
        }
    }, [selectedFeatureImage]);

    const setBlobData = async () => {
        setSelectMediaLoading(true);
        try {
            const imageData = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                selectedFeatureImage,
                { responseType: 'blob' }
            );

            const imageUrl = URL.createObjectURL(imageData.data);
            setZoom(1);

            setUploadedImage(imageUrl);
            setImageFile(null);
            setOldImage(imageUrl);

            if (featureImage === null || featureImage === '') {
                setFeatureImage(imageUrl);
            }

            setloadingFeatureImg(false);
            setFeaturedImageModal(true);
            setReplcaeImageZoom(false);

            setPageImage(imageUrl);

            setSelectedFeatureImageData(selectedFeatureImage);

            setSelectMediaLoading(false);
            setShowUploadMediaPopup(false);
            setUploadMediaPopupFor('');

        } catch (error) {
            setSelectMediaLoading(false);
            console.log({ error: error, message: error.message })
        }
        setSelectMediaLoading(false);
    }

    const fetchData = async () => {
        try {
            const categoriesData = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/all/${id}`,
            );

            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/newsLetter/plan/list/${id}`,
            );

                const planDetailsData = response?.data?.result;
        
                const formattedPlans = planDetailsData.map(item => ({
                  value: item.name, 
                  label: item.name ,
                  slug: item?.slug
                }));
                setSubscriberInterval([
                  {
                    value: 'All Subscribers',
                    label: 'All Subscribers',
                    slug: 'all'
                  },
                  ...formattedPlans
                ]);

            if (categoriesData.status == 200) {
                const categoriesArray = categoriesData.data.result.map(item => item.name);
                setValues(categoriesArray);
            }

        } catch (error) {
            console.log(error, "error");
        }
    }

    const datetimevalidationCheck = (type, value) => {
        let isValid = true;

        if (!isSchedule) {
            setTimeErrorMessage('');
            return isValid;
        }

        let clientTimeFormateOnChange = moment.tz(clientTimeZone).format();
        let clientDateOnChange = DateTime.fromISO(clientTimeFormateOnChange, { zone: clientTimeZone });

        let clientDateyearOnChange = clientDateOnChange.year;
        let clientDatemonthOnChange = clientDateOnChange.month - 1;
        let clientDatedateOnChange = clientDateOnChange.day;
        let clientDatehoursOnChange = clientDateOnChange.hour;
        let clientDateminutesOnChange = clientDateOnChange.minute;

        let clientScheduleHour = scheduleHour;
        let clientScheduleMinute = scheduleMinute;
        let clientScheduleDate = scheduleDate;

        if (type == 'hours') {
            clientScheduleHour = value;
        }

        if (type == 'minutes') {
            clientScheduleMinute = value;
        }

        if (type == 'Date') {
            clientScheduleDate = value;
        }

        if (parseInt(clientScheduleDate.getFullYear()) <= parseInt(clientDateyearOnChange)) {
            if (parseInt(clientScheduleDate.getMonth()) <= parseInt(clientDatemonthOnChange)) {
                if (parseInt(clientScheduleDate.getDate()) <= parseInt(clientDatedateOnChange)) {
                    if (parseInt(clientDatehoursOnChange) > parseInt(clientScheduleHour)) {
                        setTimeErrorMessage('This time has already passed, please choose a time in the future.');
                        isValid = false;
                    } else {
                        if (parseInt(clientDatehoursOnChange) == parseInt(clientScheduleHour)) {
                            if (parseInt(clientDateminutesOnChange) > parseInt(clientScheduleMinute)) {
                                setTimeErrorMessage('This time has already passed, please choose a time in the future.');
                                isValid = false;
                            } else {
                                setTimeErrorMessage('');
                                isValid = true;
                            }
                        } else {
                            setTimeErrorMessage('');
                            isValid = true;
                        }
                    }
                } else {
                    setTimeErrorMessage('');
                    isValid = true;
                }
            } else {
                setTimeErrorMessage('');
                isValid = true;
            }
        } else {
            setTimeErrorMessage('');
            isValid = true;
        }

        return isValid;
    }

    const handleHourInput = (e) => {
        const newHours = parseInt(e.target.value, 10);

        if (!isNaN(newHours) && newHours >= 0 && newHours <= 23) {
            setScheduleHour(newHours);
        } else if (newHours === 24) {
            setScheduleHour(23);
        }

        if (e.target.value.length == 2) {
            hourRef.current.blur();
            minuteRef.current.focus();
            minuteRef.current.select();
        }

    };

    const handleHourInputBlur = (e) => {
        let newHours = e.target.value;

        if (newHours.length == 1) {
            // Add a leading zero for single-digit numbers (1-9)
            newHours = `0${newHours}`;
        }

        if (!isNaN(newHours) && newHours >= 0 && newHours <= 23) {
            setScheduleHour(newHours);
        } else if (newHours >= 24) {
            setScheduleHour(23);
        }

        datetimevalidationCheck('hours', e.target.value);
    }

    const handleMinuteInput = (e) => {
        const newMinutes = parseInt(e.target.value, 10);

        if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
            setScheduleMinute(newMinutes);
        } else if (newMinutes === 60) {
            setScheduleMinute(59);
        }

        if (e.target.value.length == 2) {
            minuteRef.current.blur();
        }

    };

    const handleMinuteInputBlur = (e) => {
        let newMinutes = e.target.value;

        if (newMinutes.length == 1) {
            // Add a leading zero for single-digit numbers (1-9)
            newMinutes = `0${newMinutes}`;
        }

        if (!isNaN(newMinutes) && newMinutes >= 0 && newMinutes <= 59) {
            setScheduleMinute(newMinutes);
        } else if (newMinutes >= 59) {
            setScheduleMinute(59);
        }

        datetimevalidationCheck('minutes', e.target.value);
    }


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                (!modalRef.current.contains(event.target) &&
                    featuredImageModal === false) ||
                event.target.className === "overlayModal"
            ) {
                setFeaturedImageModal(false);
                setZoom(oldZoom);
                setOldImage(newUploadedImage);
            }
        };

        if (featuredImageModal) {
            window.addEventListener("click", handleOutsideClick);
        }

        return () => {
            window.removeEventListener("click", handleOutsideClick);
        };
    }, [featuredImageModal]);

    const [uploadedImage, setUploadedImage] = useState(null);
    const [newUploadedImage, setNewUploadedImage] = useState(null);

    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(true);
    const [isOpen3, setIsOpen3] = useState(true);

    const toggleDropdown1 = () => {
        setIsOpen1(!isOpen1);
    };

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const toggleDropdown3 = () => {
        setIsOpen3(!isOpen3);
    };


    const [editorContent, setEditorContent] = useState("");
    const [updatedContent, setUpdatedContent] = useState("");
    const handleEditorChange = (event, editor) => {
        if (editorContent.length > 0) {
            setValidateEditorContent(false);
        }
        const data = editor.getData();
        setEditorContent(data);
    };

    const handleImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (e) => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    };

    const [url, setUrl] = useState("");

    const [allPublicationList, setAllPublicationList] = useState([
        {
            title: "Sport",
        },
        {
            title: "Games",
        },
    ]);

    const [origPublicationList, setOrigPublicationList] = useState([]);

    useEffect(() => {
        setOrigPublicationList(allPublicationList);
    }, [allPublicationList]);

    const [publicationList, setPublicationList] = useState([]);

    function deletePublication(title) {
        var result = [];
        result = publicationList.filter((item) => item.title !== title);
        setPublicationList(result);

        setOrigPublicationList([...origPublicationList, { title: title }]);
    }

    const [selectPublication, setSelectPublication] = useState(false);

    const [publicationInput, setPublicationInput] = useState("");

    useEffect(() => {
        if (publicationInput.length === 0) {
            setOrigPublicationList(allPublicationList);
        } else {
            var result = [];
            var input = publicationInput.toLowerCase();
            origPublicationList.forEach((element) => {
                var title = element.title.toLowerCase();

                if (title) {
                    if (title.startsWith(input) === true) {
                        result.push(element);
                    }
                }
            });

            if (result != []) {
                setOrigPublicationList(result);
            } else {
                setOrigPublicationList([]);
            }
        }
    }, [publicationInput]);

    const ref = useRef();

    const [openNotify, setOpenNotify] = useState(false);
    const [openNotifyDraft, setOpenNotifyDraft] = useState(false);
    const [openNotifySend, setOpenNotifySend] = useState(false);


    const [published, setPublished] = useState(false);
    const [Draft, setDraft] = useState(false);
    const [Send, setSend] = useState(false);
    const [isPublishedOpen, setIsPublishedOpen] = useState(false);
    const [pageImage, setPageImage] = useState('');


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (e.target != null && isPublishedOpen && (!e.target.classList.contains('publish-li') && !e.target.classList.contains('newpageMoreButtons'))) {
                setIsPublishedOpen(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isPublishedOpen]);

    const dotsRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleClick = () => {
        setFeaturedImageModal(!featuredImageModal);
        // setFeatureImage(saveFeatureImage);
        var divElement = document.getElementById("newpageImageUpload");
        divElement.classList.add("imageUploaded");
    };

    const handleDrop = (e) => {
        var divElement = document.getElementById("newpageImageUpload");

        // var extraHeight = ( featureImage ) ? 2 : 0;

        divElement.style.height = (divElement.clientHeight + 2) + "px";
        divElement.style.minHeight = (divElement.clientHeight + 2) + "px";

        divElement.classList.remove("imageUploaded");
        setloadingFeatureImg(true);

        var TempReplcaeImageZoom = replcaeImageZoom
        var TempReplaceImgTimeLoading = replaceImgTimeLoading

        if (featureImage) {
            setReplcaeImageZoom(true);
            setReplaceImgTimeLoading(false);

            TempReplcaeImageZoom = true;
            TempReplaceImgTimeLoading = false;
        }

        if (TempReplcaeImageZoom === true) {
            setOldZoom(1);
            setZoom(1);
        } else {
            setZoom(oldZoom);
        }
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                if (file) {
                    setUploadedImage(file);
                    TempReplcaeImageZoom !== true && setFeatureImage(reader.result);
                    setOldImage(reader.result);
                }
            };
        };
        if (file) {
            setTimeout(() => {
                reader.readAsDataURL(file);
                setDragging(false);
                setUploadedImage(file);
                setloadingFeatureImg(false);
                var divElement = document.getElementById("newpageImageUpload");
                divElement.classList.add("imageUploaded");
                divElement.classList.add("imageUploaded-wrap");
                setFeaturedImageModal(true);
                setOldImage(file);
                setReplcaeImageZoom(false);
                setReplaceImgTimeLoading(false)

                divElement.style.height = 'auto';
                if (featureImage) {
                    divElement.style.minHeight = 'unset'
                }
            }, 4000);
        } else {
            setloadingFeatureImg(false);
            divElement.style.height = 'auto';
            if (featureImage) {
                divElement.style.minHeight = 'unset'
            }
        }
        setIsDragging(false);
    };

    const handleFileUpload = (e) => {
        var divElement = document.getElementById("newpageImageUpload");

        divElement.style.height = (divElement.clientHeight + 2) + "px";
        divElement.style.minHeight = (divElement.clientHeight + 2) + "px";

        divElement.classList.remove("imageUploaded");
        setloadingFeatureImg(true);
        if (replcaeImageZoom === true) {
            setOldZoom(1);
            setZoom(1);
        } else {
            setZoom(oldZoom);
        }
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                replcaeImageZoom !== true && setFeatureImage(reader.result);
                setOldImage(reader.result);
            };
        };
        if (file) {
            reader.readAsDataURL(file);
            setPageImage(file);
            setUploadedImage(null);

            setTimeout(() => {
                reader.readAsDataURL(file);
                setDragging(false);
                setloadingFeatureImg(false);
                setUploadedImage(file);
                var divElement = document.getElementById("newpageImageUpload");
                divElement.classList.add("imageUploaded");
                divElement.classList.add("imageUploaded-wrap");
                setFeaturedImageModal(true);
                setReplcaeImageZoom(false);
                setReplaceImgTimeLoading(false)

                divElement.style.height = 'auto';
                if (featureImage) {
                    divElement.style.minHeight = 'unset'
                }
            }, 4000);
        } else {
            setloadingFeatureImg(false);
            divElement.style.height = 'auto';
            if (featureImage) {
                divElement.style.minHeight = 'unset'
            }
        }
    };

    const handleImageLoad = () => {
        const img = new Image();
        img.src = featureImage;
        img.onload = () => { };
    };

    const handleReplaceImg = () => {
        // document.getElementById("feature-img").click();
        setShowUploadMediaPopup(true);
        setUploadMediaPopupFor('feature-image');
        setFeaturedImageModal(false);
        setReplcaeImageZoom(true);
        setReplaceImgTimeLoading(false)
    };

    useEffect(() => {
        setZoom(zoom);
    }, [zoom]);

    const handleZoom = (zoom) => {
        setZoom(zoom);
    };

    const handlePosition = (newPosition) => {
        setPosition(newPosition);
    };

    const handleSavePicture = async () => {
        let click = document.getElementById("clickFunction");
        click.click();
        setFeatureImage(featureImage);
        setNewUploadedImage(oldImage);
        setSaveFeatureImage(featureImage);
        setFeaturedImageModal(false);
        setOldZoom(zoom);
    };

    const handleMouseDown = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleMouseUp = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleTouchStart = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.add("slider-cursor");
    };

    const handleTouchEnd = () => {
        const slider = document.getElementById("zoom-range");
        slider.classList.remove("slider-cursor");
    };

    const handleCancel = () => {
        if (!saveFeatureImage) {
            var divElement = document.getElementById("newpageImageUpload");
            featureImage && divElement.classList.remove("imageUploaded");
        } else {
            var divElement = document.getElementById("newpageImageUpload");
            featureImage && divElement.classList.add("imageUploaded");
        }
        setOldImage(newUploadedImage);
        setFeaturedImageModal(false);
        setZoom(oldZoom);

        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            setUploadedImage(null);
        }
    }

    useEffect(() => {
        setNewsLetterID(newsletterid);
    }, [newsletterid]);

    const findSingleNewsLetttr = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/newsLetter/${newsletterid}?userId=${id}`)

            if (res) {
                const result = res.data.result;
                setdbPageTitle(result.subject);
                setStatus(result.status === "Send" ? "Sent" : result.status);
                setEditorContent(result.description);
                setUpdatedContent(result?.description);
                setData(result);
                setSubscriberSelect(result?.sendTo?.plan)
    
                let publicationListData = [];
                let selectedCat = [];
    
                result.categories?.forEach((item) => {
                    publicationListData.push({ title: item });
                    selectedCat.push(item);
                });
    
                setPublicationList(publicationListData);
                setSelectedValues(selectedCat);
    
                if (result.status === "Send" || result.status === "Schedule") {
                    setPublished(true);
                }
                if (result.status === "Draft") {
                    setDraft(true);
                }
    
                setIsSchedule(result.isSchedule);
                setTimeErrorMessage('');
    
                let schedule_data = res?.data?.result?.schedule_data || [];

                if (schedule_data.length > 0) {

                    setScheduleDate(schedule_data['scheduleFullDate'] ? new Date(schedule_data['scheduleFullDate']) : clientCurrentDate);
                    setScheduleHour(schedule_data['scheduleHour'] ? schedule_data['scheduleHour'] : clientCurrentDate.getHours().toString().padStart(2, '0'));
                    setScheduleMinute(schedule_data['scheduleMinute'] ? schedule_data['scheduleMinute'] : clientCurrentDate.getMinutes().toString().padStart(2, '0'));

                    setSaveScheduleDate(schedule_data['scheduleFullDate'] ? new Date(schedule_data['scheduleFullDate']) : false);
                    setSaveScheduleHour(schedule_data['scheduleHour'] ? schedule_data['scheduleHour'] : false);
                    setSaveScheduleMinute(schedule_data['scheduleMinute'] ? schedule_data['scheduleMinute'] : false);

                }
    
                if (result.featured_image?.selectedImage) {
                    const selectedImageData = await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                        result.featureImageData.selectedMedia,
                        { responseType: 'blob' }
                    );
    
                    const selectedImageUrl = URL.createObjectURL(selectedImageData.data);
                    setUploadedImage(selectedImageUrl);
                    setImageFile(null);
                    setNewUploadedImage(selectedImageUrl);
                    setOldImage(selectedImageUrl);
                    setFeatureImageId(result.featured_image.selectedImage);
                }
    
                if (result.featured_image?.cropedImage) {
                    const croppedImageData = await axios.post(
                        `${process.env.REACT_APP_API_URL}/user/fetchImageData/${id}`,
                        result.featureImageData.cropedMedia,
                        { responseType: 'blob' }
                    );
    
                    const croppedImageUrl = URL.createObjectURL(croppedImageData.data);
                    setFeatureImage(croppedImageUrl);
                    setSaveFeatureImage(croppedImageUrl);
                    setFeatureCropedImageId(result.featured_image.cropedImage);
                    setPageImage(croppedImageUrl);
                    setPopupData(prevState => ({
                        ...prevState, 
                        pageImage: croppedImageUrl
                      }));
                }
    
                if (result.featured_image?.zoom) {
                    setZoom(result.featured_image.zoom);
                    setOldZoom(result.featured_image.zoom);
                }
    
                if (result.featured_image?.position) {
                    setPosition(result.featured_image.position);
                }
    
                setCanAccess(true);
            } else {
                setCanAccess(false);
            }
        } catch (err) {
            setCanAccess(false);
            console.log(err);
        }
    };

    const [page, setPages] = useState(false)

    useEffect(() => {
        setPages(!page)
    }, [location])

    useEffect(() => {

        if (newsletterid) {
            findSingleNewsLetttr()
        } else {
            setdbPageTitle('')
            setEditorContent('')
            setUpdatedContent('');
            setPublicationList([])
            setIsLoading(false)
            setDraft(false)
            setPublished(false)
            setIsDraftLoading(false)
            setSaveFeatureImage(null)
            setOrigPublicationList(allPublicationList);

            setIsSchedule(false);
            setScheduleDate(clientCurrentDate);
            setScheduleHour(clientCurrentDate.getHours().toString().padStart(2, '0'));
            setScheduleMinute(clientCurrentDate.getMinutes().toString().padStart(2, '0'));
        }

        setValidateTitle(false);
        setValidateEditorContent(false);
        setValidateCategories(false);
        setOpenNotifyDraft(false);
        setOpenNotify(false);

        clearTimeout(notificationTimeoutId)

    }, [page]);

    useEffect(() => {
        if (newsletterid) {
            findSingleNewsLetttr()
        } else {
            setdbPageTitle('')
            setEditorContent('')
            setUpdatedContent('');
            setPublicationList([])
            setIsLoading(false)
            setDraft(false)
            setPublished(false)
            setIsDraftLoading(false)
            setSaveFeatureImage(null)
        }

    }, [newsletterid])

    const changeSlug = (value) => {
        // setPageTitle(value);
        if (!isSlugChanged) {
            let urlValue = convertToSlug(value);
            setUrl(urlValue);
        }
    }

    const changeTitle = (e) => {
        //Stop enter button

        if (e.key === 'Enter') {
            e.preventDefault();
            return true;
        }

    }

    const changesTitles = (e) => {
        const text = e.target.innerText.replace(/<[^>]+>/g, '');

        if (text.length <= 120 || e.key === 'Backspace') {
            // setPageTitle(text)
            changeSlug(text);
        } else {
            e.preventDefault();
            return true;
        }
    }

    const handleBlur = (e) => {
        const text = e.target.innerText.replace(/<[^>]+>/g, '');
        setdbPageTitle(text)
    }

    const handlePaste = (e) => {
        e.preventDefault();

        // Get the plain text content from clipboard
        const clipboardData = e.clipboardData.getData('text/plain');

        // Paste the plain text into the contenteditable div
        document.execCommand('insertText', false, clipboardData);
    }
    const [authorId, setauthorId] = useState(id);
    const [isLoading, setIsLoading] = useState(false);
    const [isDraftLoading, setIsDraftLoading] = useState(false);

    const categories = publicationList.map((item) => item.title);

    const handleChangeURL = (newsLetterID) => {
        const newUrl = `/users/${id}/newsLetters/newsLetter/${newsLetterID}`;  // Replace with the URL you want

        // Change the URL without reloading the page
        window.history.pushState({}, '', newUrl);
    };

    const closeAccountDeletedPopup = () => {
        setIsPopupOpenDeleteAccount(false);
        document.body.classList.remove('hidden-message');
    }

    const closeCategoryField = (action) => {

        setShowCategoryField(false);
        document.body.classList.remove('hidden-message');

        setTimeout(() => {
            scrollToBottom();
        }, 100);
    }

    const handelSingleDelete = () => {
        setIsPublishedOpen(false);
        setIsPopupOpenDeleteAccount(true);
        document.body.classList.add('hidden-message');

    }
    const addData = async (isDraft, Status) => {
        if (newsletterid) {
            try {
                let schedule_data = {};
                if (isSchedule) {
                    schedule_data = {
                        scheduleFullDate: scheduleDate,
                        scheduleYear: scheduleDate.getFullYear(),
                        scheduleMonth: scheduleDate.getMonth(),
                        scheduleDate: scheduleDate.getDate(),
                        scheduleHour: scheduleHour,
                        scheduleMinute: scheduleMinute,
                        scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
                        scheduleTimezone: clientTimeZone,
                    };

                    setSaveScheduleDate(scheduleDate);
                    setSaveScheduleHour(scheduleHour);
                    setSaveScheduleMinute(scheduleMinute);
                }

                let selectedImage = featureImageId;
                let featureCropedImage = featureCropedImageId;

                const featureImageData = await getFeatureImageData(newsletterid)

                selectedImage = featureImageData?.selectedImage || selectedImage;
                featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

                const newNewsLetterData = {
                    subject: pageTitle,
                    description: editorContent || updatedContent,
                    author_id: authorId,
                    categories: selectedValues,
                    status: isSchedule && Status == 'Send' ? 'Schedule' : Status,
                    previous_status: isSchedule && Status == 'Send' ? 'Schedule' : Status,
                    isSchedule: isSchedule,
                    schedule_data: schedule_data,
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                    featured_image: {
                        selectedImage: selectedImage,
                        cropedImage: featureCropedImage,
                        position: position,
                        zoom: zoom
                    },
                    sendTo: {
                        plan: sendTo?.value,
                        slug: sendTo?.slug
                    }
                }
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/newsLetter/update/${newsletterid}`,
                    newNewsLetterData
                )
                if (Status === "Draft") {
                    setOpenNotifyDraft(true)

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifyDraft(false)
                    }, 15000);

                }
                if (Status === "Send") {
                    setOpenNotifySend(true)

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifySend(false)
                    }, 15000);
                }
                setIsLoading(false);
                setIsDraftLoading(false);
            } catch (error) {
                setIsLoading(false);
                setIsDraftLoading(false);
            }
        } else {
            try {

                let schedule_data = {};
                if (isSchedule) {
                    schedule_data = {
                        scheduleFullDate: scheduleDate,
                        scheduleYear: scheduleDate.getFullYear(),
                        scheduleMonth: scheduleDate.getMonth(),
                        scheduleDate: scheduleDate.getDate(),
                        scheduleHour: scheduleHour,
                        scheduleMinute: scheduleMinute,
                        scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
                        scheduleTimezone: clientTimeZone,
                    };

                    setSaveScheduleDate(scheduleDate);
                    setSaveScheduleHour(scheduleHour);
                    setSaveScheduleMinute(scheduleMinute);
                }

                const newNewsLetterData = {
                    subject: dbPageTitle,
                    description: editorContent || updatedContent,
                    author_id: authorId,
                    categories: selectedValues,
                    status: isSchedule && Status == 'Send' ? 'Schedule' : Status,
                    previous_status: isSchedule && Status == 'Send' ? 'Schedule' : Status,
                    isSchedule: isSchedule,
                    schedule_data: schedule_data,
                    member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                    sendTo: {
                        plan: sendTo?.value,
                        slug: sendTo?.slug
                    }
                }
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/newsLetter/add`,
                    newNewsLetterData
                )

                if (response.status = 201) {
                    const _newsId = response.data.result._id;
                    handleChangeURL(response.data.result._id)
                    setNewsLetterID(response.data.result._id)
                    setData(response.data.result);

                    let selectedImage = featureImageId;
                    let featureCropedImage = featureCropedImageId;

                    const featureImageData = await getFeatureImageData(_newsId)

                    selectedImage = featureImageData?.selectedImage || selectedImage;
                    featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

                    const updatedNewsLetterData = {
                        ...newNewsLetterData,
                        featured_image: {
                            selectedImage: selectedImage,
                            cropedImage: featureCropedImage,
                            position: position,
                            zoom: zoom
                        }
                    };

                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/newsLetter/update/${_newsId}`,
                        updatedNewsLetterData
                    )
                }

                setIsLoading(false);
                if (Status === "Draft") {
                    setDraft(true)
                    setOpenNotifyDraft(true)

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifyDraft(false)
                    }, 15000);
                }
                if (Status === "Send") {
                    setSend(true)
                    setOpenNotifySend(true)

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifySend(false)
                    }, 15000);
                }
                setIsDraftLoading(false);
            } catch (error) {
                setIsLoading(false);
                setIsDraftLoading(false);
            }
        }
    }

    const handleDraft = async () => {
        if (apiCall === false) {
            setIsDraftLoading(true)
            setApiCall(true)

            let selectedImage = featureImageId;
            let featureCropedImage = featureCropedImageId;

            const featureImageData = await getFeatureImageData(NewsLetterID)

            selectedImage = featureImageData?.selectedImage || selectedImage;
            featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

            const newNewsLetterData = {
                subject: dbPageTitle,
                description: editorContent || updatedContent,
                author_id: authorId,
                categories: selectedValues,
                status: "Draft",
                previous_status: "Draft",
                member_id: JSON.parse(localStorage.getItem("userData"))?._id,
                featured_image: {
                    selectedImage: selectedImage,
                    cropedImage: featureCropedImage,
                    position: position,
                    zoom: zoom
                }
            }
            await axios.post(
                `${process.env.REACT_APP_API_URL}/newsLetter/update/${NewsLetterID}`,
                newNewsLetterData
            )
                .then((res) => {
                    setOpenNotifyDraft(true)
                    setIsDraftLoading(false)
                    if (isDraftLoading === false) {
                        setApiCall(false)
                    }

                    clearTimeout(notificationTimeoutId)
                    notificationTimeoutId = setTimeout(() => {
                        setOpenNotifyDraft(false)
                    }, 15000);
                })
                .catch((err) => {
                    console.log(err);
                    if (isDraftLoading === false) {
                        setApiCall(false)
                    }
                })
        }
    }

    const handleChangeToSent = async () => {

        if (!datetimevalidationCheck(null, null)) {
            setIsLoading(false);
            return false;
        }

        setIsLoading(true);
        setIsDraftLoading(true);

        let schedule_data = {};

        if (isSchedule) {
            schedule_data = {
                scheduleFullDate: scheduleDate,
                scheduleYear: scheduleDate.getFullYear(),
                scheduleMonth: scheduleDate.getMonth(),
                scheduleDate: scheduleDate.getDate(),
                scheduleHour: scheduleHour,
                scheduleMinute: scheduleMinute,
                scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
                scheduleTimezone: clientTimeZone,
            };

            setSaveScheduleDate(scheduleDate);
            setSaveScheduleHour(scheduleHour);
            setSaveScheduleMinute(scheduleMinute);
        }

        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;

        const featureImageData = await getFeatureImageData(newsletterid)

        selectedImage = featureImageData?.selectedImage || selectedImage;
        featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;

        const newNewsLetterData = {
            subject: dbPageTitle,
            description: editorContent || updatedContent,
            author_id: authorId,
            categories: selectedValues,
            status: isSchedule ? 'Schedule' : 'Send',
            previous_status: isSchedule ? 'Schedule' : 'Send',
            isSchedule: isSchedule,
            schedule_data: schedule_data,
            member_id: JSON.parse(localStorage.getItem("userData"))?._id,
            featured_image: {
                selectedImage: selectedImage,
                cropedImage: featureCropedImage,
                position: position,
                zoom: zoom
            },
            sendTo: {
                plan: sendTo?.value,
                slug: sendTo?.slug
            }
        }

        await axios.post(
            `${process.env.REACT_APP_API_URL}/newsLetter/update/${newsletterid}`,
            newNewsLetterData
        )
            .then((res) => {
                setIsLoading(false)
                setIsDraftLoading(false);
                setOpenNotifySend(true)

                clearTimeout(notificationTimeoutId)
                notificationTimeoutId = setTimeout(() => {
                    setOpenNotifySend(false)
                }, 15000);
            })
            .catch((err) => {
                setIsLoading(false)
                setIsDraftLoading(false);
                console.log(err);
            })
        setPublished(true);
        billingData();
        setDraft(false);
        setIsPublishedOpen(false);

        setStatus(isSchedule ? 'Schedule' : 'Send');
    };

    const validateFields = () => {
        if (dbPageTitle.length > 0) {
            setValidateTitle(false);
        } else {
            setValidateTitle(true);
        }

        if ((editorContent.length > 0) || (updatedContent.length > 0)) {
            setValidateEditorContent(false);
        } else {
            setValidateEditorContent(true);
        }

        if (selectedValues.length > 0) {
            setValidateCategories(false);
        } else {
            setValidateCategories(true);
        }
    }

    const draftValidateFields = () => {
        if (pageTitle.length > 0) {
            setValidateTitle(false);
        } else {
            setValidateTitle(true);
        }
        if (dbPageTitle.length > 0) {
            setValidateTitle(false);
        } else {
            setValidateTitle(true);
        }
        setPreviewLoading(false)
    }


    const handleCheckboxChange = (value) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((v) => v !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };

    const filteredValues = values.filter((value) =>
        value.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            setSearchValue(inputValue);
            setInputValue('');
        }
    };

    const handleNewCategoryClick = () => {
        // setInputValue(''); // Clear input when creating a new category
        setShowCategoryField(true); // Hide closeLink    
        // setErrorMessage('');
        document.body.classList.add('hidden-message');
    };

    // Function to scroll the ul to the bottom
    const scrollToBottom = () => {
        var categoriesList = document.querySelector('.categories-list');
        var liElements = categoriesList.querySelectorAll('li');

        let totalHeight = ((parseInt(liElements.length) + 1) * 22) + 5;

        categoriesList.scrollTop = totalHeight;
    };

    const getFeatureImageData = async (newsLetterId) => {
        let selectedImage = featureImageId;
        let featureCropedImage = featureCropedImageId;

        if (imageFile !== null) {
            const imageFormData = new FormData();
            imageFormData.append('files', imageFile); // Append each file individually
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                    imageFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );
                selectedImage = res.data.result[0]._id;

                const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");
                const formData = new FormData();
                formData.append('files', featureImageFile);
                formData.append('id', newsLetterId);
                const uploadedMedia = await axios.post(
                    `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                );

                featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;

            } catch (error) {
                console.log(error);
            }
        } else if ((selectedFeatureImageData?._id) || oldImage !== saveFeatureImage) {
            const featureImageFile = await dataURLtoFile(featureImage, "feature-image.jpg");

            const formData = new FormData();
            formData.append('files', featureImageFile);
            formData.append('id', newsLetterId);

            const uploadedMedia = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/uploadMedia/${id}`,
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            );

            selectedImage = selectedFeatureImageData?._id || selectedImage;
            featureCropedImage = uploadedMedia?.data?.result[0]?._id || featureCropedImage;
        }

        const result = { selectedImage, featureCropedImage }

        return result;
    }

    // Start Schedule Checkbox 
    const handleScheduleCheckbox = () => {
        setIsSchedule(!isSchedule);
        setShowCalendar(false);
    };

    const handleCalendar = () => {
        if (!showCalendar) {
            setShowCalendar(true);
        }
    };

    const closeCalendar = () => {
        setShowCalendar(false);
    };

    const handleClickOutsideCalendar = (e) => {
        if (showCalendar && calendarRef.current && !calendarRef.current.contains(e.target)) {
            closeCalendar();
        }
    };

    // Handle clicks outside the calendar when it's open
    useEffect(() => {
        if (showCalendar) {
            document.addEventListener('click', handleClickOutsideCalendar);
        } else {
            document.removeEventListener('click', handleClickOutsideCalendar);
        }

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutsideCalendar);
        };
    }, [showCalendar]);

    const handleDateSelectorClick = (e) => {
        e.stopPropagation(); // Prevent click events from bubbling up
        handleCalendar();
    };
    const toggleSendToDropdown = () => {
        setSendToOpen(!sendToOpen);
    };

    const handleSubscriberChange = (selectedOption) => {
        setSendTo(selectedOption);
        setSubscriberSelect(selectedOption?.value);
    };


    const showPreview = async () => {

        let canShowPreview = false;

        if(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0){
            canShowPreview = true;
        }

        if( ! canShowPreview ) {
            setPreviewLoading(false)
            return false;
        }

        setPreviewLoading(true)
        let _newsId;
        let sendStatus;
        if ((!NewsLetterID) || (NewsLetterID === undefined)) {
          draftValidateFields()
          setPreviewLoading(true)
          if(dbPageTitle.length > 0){
            let schedule_data = {};
            if( isSchedule ) {
              schedule_data = {
                scheduleFullDate: scheduleDate,
                scheduleYear: scheduleDate.getFullYear(),
                scheduleMonth: scheduleDate.getMonth(),
                scheduleDate: scheduleDate.getDate(),
                scheduleHour: scheduleHour,
                scheduleMinute: scheduleMinute,
                scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
                scheduleTimezone: clientTimeZone,
              };
    
              setSaveScheduleDate(scheduleDate);        
              setSaveScheduleHour(scheduleHour);
              setSaveScheduleMinute(scheduleMinute);
            }
    
            const newNewsLetterData = {
              subject: dbPageTitle,
              description: updatedContent || editorContent,
              postContent: updatedContent || editorContent,
              author_id: authorId,
              categories: selectedValues,
              status: isSchedule  ? 'Schedule' : 'Draft',
              previous_status: isSchedule  ? 'Schedule' : 'Draft',
              isSchedule: isSchedule,
              schedule_data: schedule_data,
              sendTo: {
                plan: sendTo?.value,
                slug: sendTo?.slug
              }
            }
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/newsLetter/add`,
              // formData,
              newNewsLetterData
            )
            if (response.status = 201) {
              _newsId = response.data.result._id;
              sendStatus = response.data.result.status;
              handleChangeURL(_newsId)
              setNewsLetterID(_newsId)
              setPreviewLoading(false)
              let selectedImage = featureImageId;
              let featureCropedImage = featureCropedImageId;
      
              const featureImageData = await getFeatureImageData(_newsId)
      
              selectedImage = featureImageData?.selectedImage || selectedImage;
              featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
      
              const updatedNewsLetterData = {
                ...newNewsLetterData,
                featured_image: {
                  selectedImage: selectedImage,
                  cropedImage: featureCropedImage,
                  position: position,
                  zoom: zoom
                }
              };
              await axios.post(
                `${process.env.REACT_APP_API_URL}/newsLetter/update/${_newsId}`,
                updatedNewsLetterData
              )
            }
            setDraft(true)
          }
        }
        
        setPreviewLoading(true)
        let featureCropedImage = featureCropedImageId;
        let selectedImage = featureImageId;
        
        const featureImageData = await getFeatureImageData(_newsId ? _newsId : NewsLetterID)
    
        selectedImage = featureImageData?.selectedImage || selectedImage;
        featureCropedImage = featureImageData?.featureCropedImage || featureCropedImage;
    
        let schedule_data = {};
        if (isSchedule) {
          schedule_data = {
            scheduleFullDate: scheduleDate,
            scheduleYear: scheduleDate.getFullYear(),
            scheduleMonth: scheduleDate.getMonth(),
            scheduleDate: scheduleDate.getDate(),
            scheduleHour: scheduleHour,
            scheduleMinute: scheduleMinute,
            scheduleTimezoneOffset: scheduleDate.getTimezoneOffset(),
            scheduleTimezone: clientTimeZone,
          };
    
          setSaveScheduleDate(scheduleDate);
          setSaveScheduleHour(scheduleHour);
          setSaveScheduleMinute(scheduleMinute);
        }
        
        const newNewsLetterData = {
          tempData : {
            subject: dbPageTitle,
            description: updatedContent || editorContent,
            postContent: updatedContent || editorContent,
            author_id: authorId,
            categories: selectedValues,
            status: isSchedule  ? 'Schedule' : sendStatus ? sendStatus : status,
            previous_status: isSchedule  ? 'Schedule' : sendStatus ? sendStatus : status,
            isSchedule: isSchedule,
            schedule_data: schedule_data,
            featured_image: {
              selectedImage: selectedImage,
              cropedImage: featureCropedImage,
              position: position,
              zoom: zoom
            },
            sendTo: {
              plan: sendTo?.value,
              slug: sendTo?.slug
            },
          }

        }
        
        if (dbPageTitle.length > 0) {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}/newsLetter/update/${_newsId ? _newsId : NewsLetterID}`,
                newNewsLetterData
            )
            if (res?.status === 200) {
                setPreviewLoading(false)
                window.open(`https://reactadminportal.codeptsolutions.com/users/${id}/newsletters/previewnewsletter/${_newsId ? _newsId : NewsLetterID}`, "_blank");
            }
        }
        setPreviewLoading(false)
    }
    const validateForm = () => {
        const isValid = dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0;
        setIsFormInvalid(!isValid);
    };

    useEffect(() => {
        validateForm();
    }, [dbPageTitle ,updatedContent, selectedValues]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
          if (subscriberTypeOpen && subscriberRef.current && !subscriberRef.current.contains(e.target)) {
            setSubscriberTypeOpen(false);
          }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);
    
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside);
        };
      }, [subscriberTypeOpen]);

    return (
        <>
            {
                canAccess ?
                    <section className="dashboard new-newsletter new-newsletter-loader">
                        <div className="commonPage">
                            <div className="dashboardTop">
                                <div className="newpageContent newNewsletterPageContent">
                                    <div className="newpageLeft publicationLeft">
                                        <div className="breadcrumbs">
                                            <Link to={'/'} ><img src={Home} className="home-icon" alt="home-icon" /></Link>
                                            <img className="divider" src={iconDivider} alt="dropdown" />
                                            <Link to={`/users`} ><span className="">Users</span></Link>
                                            <img className="divider" src={iconDivider} alt="dropdown" />
                                            <Link to={`/users/${id}`} ><span className="">{userData.firstName} {userData.lastName}</span></Link>
                                            <img className="divider" src={iconDivider} alt="dropdown" />
                                            <Link to={`/users/${id}/newsletters`} ><span className="">Newsletters</span></Link>
                                            {newsletterid == undefined && (
                                                <>
                                                    <img className="divider" src={iconDivider} alt="dropdown" />
                                                    <Link to={`/users/${id}/newsletters/newsletter`} ><span className="">New Newsletter</span></Link>
                                                </>
                                            )}
                                        </div>
                                        <div className="newpageLeftRow editor-block">
                                            <input type="hidden" className="page-title" value={dbPageTitle}
                                                onChange={(e) => {
                                                    changeSlug(e.target.value);
                                                }}
                                                placeholder="Subject" />
                                            <div id="post-title" placeholder="Subject" contentEditable="true"
                                                onKeyDown={(e) => {
                                                    changeTitle(e)
                                                    if (e.target.innerText.length > 0) {
                                                        setValidateTitle(false);
                                                    }
                                                }}
                                                onBlur={(e) => handleBlur(e)}
                                                onKeyUp={(e) => changesTitles(e)}
                                                onPaste={(e) => handlePaste(e)}>{dbPageTitle}</div>
                                        </div>

                                        {validateTitle === true ? (
                                            <p className="validateError">The field is not filled</p>
                                        ) : (
                                            ""
                                        )}
                                        <div className={`newpageLeftRow textBlock ${validateEditorContent ? 'validateError inputError' : ''}`}>
                                            <EditorComponent setUpdatedContent={setUpdatedContent} editorContent={editorContent} type="newsLetter" id={newsletterid}/>
                                        </div>
                                        {validateEditorContent === true ? (
                                            <p className="validateError">The field is not filled</p>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="newpageRight">
                                        <div className="buttonsDesktop">
                                            {(published === false && Draft === false) ?
                                                <div className="newpageRightButtons">
                                                    <button className="primary-button button"
                                                        onClick={(e) => {
                                                            validateFields();
                                                            if (dbPageTitle.length > 0 && editorContent.length > 0 && updatedContent.length > 0) {
                                                                if (billingDetails.permission.canPublishNewsletter === 'no') {
                                                                    setIsPublishedOpen(false);
                                                                    setShowUpgradeModel(true);
                                                                    return false;
                                                                }
                                                                setIsLoading(true);
                                                                addData(true, "Send");
                                                                setStatus(isSchedule ? 'Schedule' : 'Send');
                                                                setOpenNotify(true);
                                                                setPublished(true);
                                                                setPublicationList([
                                                                    ...publicationList,
                                                                    { title: "Send" },
                                                                ]);

                                                                billingData();
                                                                clearTimeout(notificationTimeoutId)
                                                                notificationTimeoutId = setTimeout(() => {
                                                                    setOpenNotify(false)
                                                                }, 15000);
                                                            }
                                                        }
                                                        }
                                                    >
                                                        {isLoading ?
                                                            <div className="sendbutton-loader">
                                                                <div className="ring-loader"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                <img src={send} alt="Send" />
                                                                <p>{isSchedule ? 'Schedule' : 'Send'}</p>
                                                            </>}

                                                    </button>
                                                    <button
                                                        className="saveAsDraftButton secondary-button button"
                                                        onClick={(e) => {
                                                            draftValidateFields();
                                                            if (dbPageTitle.length > 0) {
                                                                setIsDraftLoading(true);
                                                                addData(true, "Draft");
                                                                setStatus('Draft');
                                                                // setDraft(true);
                                                            }
                                                        }} >
                                                        {isDraftLoading ?
                                                            <div className="draftButton-loader">
                                                                <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                Save as draft
                                                            </>}
                                                    </button>
                                                    {isPreviewLoading ? 
                                                        <div className="previewButtonLoader">
                                                        <div className="ring-loader cancel"></div>
                                                        </div>
                                                        :
                                                        <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                        onMouseEnter={() => {
                                                            if (isFormInvalid) {
                                                              setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                            }
                                                          }}
                                                          onMouseLeave= {() => {
                                                            setPreviewErrorMessage("");
                                                          }}>
                                                            {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Preview
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                ''}

                                            {published === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        {
                                                            status == 'Schedule' ?
                                                                <>
                                                                    <button className="primary-button button"
                                                                        onClick={(e) => {
                                                                            validateFields();
                                                                            if (dbPageTitle.length > 0 && editorContent.length > 0 && updatedContent.length > 0) {
                                                                                {
                                                                                    handleChangeToSent(e, () => setIsLoading(true))
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {isLoading ?
                                                                            <div className="updatebutton-loader">
                                                                                <div className="ring-loader"></div>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                Update
                                                                            </>}
                                                                    </button>
                                                                    <button
                                                                        className="newpageMoreButtons secondary-button button"
                                                                        onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                                                                    >
                                                                        <img src={dots} alt="Dots" />
                                                                    </button>
                                                                    {isPreviewLoading ? 
                                                                        <div className="previewButtonLoader">
                                                                        <div className="ring-loader cancel"></div>
                                                                        </div>
                                                                        :
                                                                        <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                            onMouseEnter={() => {
                                                                                if (isFormInvalid) {
                                                                                setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                                }
                                                                            }}
                                                                            onMouseLeave= {() => {
                                                                                setPreviewErrorMessage("");
                                                                            }}>
                                                                            {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                        Preview
                                                                        </button>
                                                                    }
                                                                    {isPublishedOpen ? (
                                                                        <div className="publishedButtonsList">
                                                                            <ul>
                                                                                <li className="publish-li" >
                                                                                    Change to draft
                                                                                </li>
                                                                                <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                            </ul>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                                :
                                                                <>
                                                                <button className="primary-button button">Sent</button>
                                                                {isPreviewLoading ? 
                                                                    <div className="previewButtonLoader">
                                                                    <div className="ring-loader cancel"></div>
                                                                    </div>
                                                                    :
                                                                    <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                    onMouseEnter={() => {
                                                                        if (isFormInvalid) {
                                                                          setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                        }
                                                                    }}
                                                                    onMouseLeave= {() => {
                                                                        setPreviewErrorMessage("");
                                                                    }}>
                                                                        {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                            <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        </svg>
                                                                        Preview
                                                                    </button>
                                                                }
                                                                </>
                                                        }

                                                    </div>

                                                    {published === true ? (
                                                        <p className="publishStatus">
                                                            {
                                                                status == 'Schedule' ?
                                                                    <>Status: <span>{status}d for {saveScheduleDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} at {saveScheduleHour}:{saveScheduleMinute}</span></>
                                                                    :
                                                                    <>Status: <span>{status}</span></>
                                                            }
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) :
                                                ''}

                                            {Draft === true ? (
                                                <div>
                                                    <div className="newpageRightButtons updateDraftButton" ref={dotsRef}>
                                                        <button className="primary-button button"
                                                            onClick={(e) => {
                                                                draftValidateFields();
                                                                if (dbPageTitle.length > 0) {
                                                                    handleDraft(e)
                                                                }
                                                            }}>
                                                            {isDraftLoading ?
                                                                <div className="updateDraftbutton-loader">
                                                                    <div className="ring-loader"></div>
                                                                </div>
                                                                :
                                                                <>
                                                                    Update Draft
                                                                </>}
                                                        </button>
                                                        <button
                                                            className="newpageMoreButtons secondary-button button"
                                                            onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                                                        >
                                                            <img src={dots} alt="Dots" />
                                                        </button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }
                                                        {isPublishedOpen ? (
                                                            <div className="publishedButtonsList">
                                                                <ul>
                                                                    <li className="publish-li" onClick={(e) => {
                                                                        validateFields();
                                                                        if (dbPageTitle.length > 0 && editorContent.length > 0 && updatedContent.length > 0) {
                                                                            {
                                                                                if (billingDetails.permission.canPublishNewsletter === 'no') {
                                                                                    setIsPublishedOpen(false);
                                                                                    setShowUpgradeModel(true);
                                                                                    return false;
                                                                                }
                                                                                handleChangeToSent(e, () => setIsLoading(true))
                                                                            }
                                                                        }
                                                                    }}>
                                                                        {isSchedule ? 'Schedule' : 'Send Now'}
                                                                    </li>
                                                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    {Draft === true ? (
                                                        <p className="publishStatus">
                                                            Status: <span className="draftStatus">Draft</span>
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) :
                                                ''}
                                        </div>
                                        <div className="newPageRightRow categoryFieldDrop subscriberDrop">
                                            <p className="newPageRightTitle iconDropWrap" onClick={toggleSendToDropdown}>
                                            <span>Send to:</span>
                                            <div className="dropdownIcon">
                                                <img src={iconDropDown} alt="dropdown" />
                                            </div>
                                            </p>
                                            <div className={`contentDrop ${sendToOpen ? 'expanded' : 'collapse'}`}>
                                            <div className="newPageRightRow" ref={subscriberRef}>
                                                <div className={`contentDrop`}>
                                                <div>
                                                    <div className="select planTypeSelect">
                                                    <div className="selectMain" onClick={(e) => { setSubscriberTypeOpen(!subscriberTypeOpen) }}>
                                                        <p>{subscriberSelect}</p>
                                                    </div>
                                                    {subscriberTypeOpen === true && (
                                                        <div className="selectListOption">
                                                        {
                                                            subscriberInterval?.map((item) => {
                                                            return (
                                                                <div
                                                                className="selectOption"
                                                                onClick={() => {
                                                                    setSendTo(item);
                                                                    setSubscriberSelect(item?.value);
                                                                    setSubscriberTypeOpen(false);
                                                                }}
                                                                >
                                                                <p>{item?.label}</p>
                                                                </div>
                                                            )
                                                            })
                                                        }
                                                        </div>
                                                    )}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="newPageRightRow dateFieldDrop">
                                            <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown1}>
                                                <span>Date & Time</span>
                                                <div className="dropdownIcon">
                                                    <img src={iconDropDown} alt="dropdown" />
                                                </div>
                                            </p>
                                            <div className={`contentDrop ${isOpen1 ? 'expanded' : 'collapse'}`}>
                                                <div className="checkboxView list-search schedule-checkbox">
                                                    <label>
                                                        <input type="checkbox" checked={isSchedule} onChange={handleScheduleCheckbox} />
                                                        <p>Schedule Publication</p>
                                                    </label>

                                                    {
                                                        isSchedule &&
                                                        <>
                                                            <div className="dateTimeCheckbox">
                                                                <p className="schedule-date-selector" ref={dateSelectorRef} onClick={handleDateSelectorClick} > {scheduleDate != null && scheduleDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} <img src={calendar} alt="Calendar" /></p>
                                                                <div className={`schedule-time-selector ${timeErrorMessage != '' ? 'inputError' : ''}`}>
                                                                    <input ref={hourRef} className={`schedule-hour ${scheduleHour == 1 && scheduleHour.length < 1 ? 'shortest-width' : ''} ${(scheduleHour == 0 || (scheduleHour > 1 && scheduleHour < 9)) && scheduleHour.length < 1 ? 'very-short-width' : ''} ${scheduleHour == 11 ? 'short-width' : ''} ${scheduleHour < 20 && scheduleHour > 11 ? 'normal-width' : ''}`} autocomplete="off" id="schedule-hour" maxlength="2" pattern="^(0[0-9]|1[0-9]|2[0-3])$" min={0} max={23} inputmode="numerical" placeholder="HH" type="text" value={scheduleHour} onChange={handleHourInput} onBlur={(e) => handleHourInputBlur(e)} data-pattern-validate />
                                                                    <span className="verticle-dots">:</span>
                                                                    <input ref={minuteRef} className={`schedule-minute ${scheduleMinute && scheduleMinute.length < 1 == 1 ? 'shortest-width' : ''} ${(scheduleMinute == 0 || (scheduleMinute > 1 && scheduleMinute < 9)) && scheduleMinute.length < 1 ? 'very-short-width' : ''} ${scheduleMinute == 11 ? 'short-width' : ''} ${scheduleMinute < 20 && scheduleMinute > 11 ? 'normal-width' : ''}`} autocomplete="off" id="schedule-minute" maxlength="2" pattern="[0-9]*" min={0} max={59} inputmode="numerical" placeholder="mm" type="text" value={scheduleMinute} onChange={handleMinuteInput} onBlur={(e) => handleMinuteInputBlur(e)} data-pattern-validate />
                                                                </div>
                                                            </div>
                                                            {
                                                                showCalendar && (
                                                                    <div ref={calendarRef} className="calendar-container" onClick={(e) => e.stopPropagation()} >
                                                                        <Calendar onChange={item => { setScheduleDate(item); setShowCalendar(false); datetimevalidationCheck('Date', item) }} date={scheduleDate} minDate={new Date(clientTimeFormate)} />
                                                                    </div>
                                                                )
                                                            }
                                                            {timeErrorMessage && <div className="error-message"><p className="error-text">{timeErrorMessage}</p></div>}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="newPageRightRow categoryFieldDrop">
                                            <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown3}>
                                                <span>Categories</span>
                                                <div className="dropdownIcon">
                                                    <img src={iconDropDown} alt="dropdown" />
                                                </div>
                                            </p>
                                            <div className={`contentDrop ${isOpen3 ? 'expanded' : 'collapse'}`}>
                                                <div className="adressInputBlock urlInput">
                                                    <img className="adressLink" src={search} alt="Search" />
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchValue}
                                                        className="inputFields"
                                                        onChange={(e) => setSearchValue(e.target.value)}
                                                        onKeyPress={handleKeyPress}
                                                        autocomplete="off"
                                                    />
                                                    <ul className="list-search categories-list">
                                                        {
                                                            filteredValues
                                                                .filter((category) => category.toLowerCase().includes(searchValue.toLowerCase()))
                                                                .map((category, index) => (
                                                                    <li key={index}>
                                                                        <label>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selectedValues.includes(category)}
                                                                                onChange={() => handleCheckboxChange(category)}
                                                                            />
                                                                            <p>{category}</p>
                                                                        </label>
                                                                    </li>
                                                                ))
                                                        }
                                                    </ul>

                                                    <button className="new-category" onClick={handleNewCategoryClick}>
                                                        <img src={plus} alt="Plus" /> New Category
                                                    </button>

                                                    {validateCategories === true ? (
                                                        <p className="validateError">The field is not filled</p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="newPageRightRow desktopFeatures">
                                            <p className="newPageRightTitle iconDropWrap" onClick={toggleDropdown2}>
                                                <span>
                                                    Featured image (optional)
                                                </span>
                                                <div className="dropdownIcon">
                                                    <img src={iconDropDown} alt="dropdown" />
                                                </div>
                                            </p>
                                            <div className={`contentDrop ${isOpen2 ? 'expanded' : 'collapse'}`}>
                                                <p className="newPageRightFullUrl">
                                                    <span>Display at the end of the publication...</span>
                                                </p>
                                                <div
                                                    className="newPageRightRow imageUploadBox"
                                                    onDragOver={handleDragOver}
                                                    onDragLeave={handleDragLeave}
                                                    onDrop={handleDrop}
                                                >
                                                    {saveFeatureImage ? (
                                                        <div
                                                            id="newpageImageUpload"
                                                            className={
                                                                isDragging
                                                                    ? "draging newpageImageUpload"
                                                                    : "newpageImageUpload"
                                                            }
                                                            onClick={handleClick}
                                                        >
                                                            {loadingFeatureImg === true && featuredImageModal === false ? (
                                                                <div className="featureImg-loader">
                                                                    <Loader />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div onClick={handleClick}>
                                                                        <img
                                                                            className="newpage_CoverImg"
                                                                            src={featureImage}
                                                                            onLoad={handleImageLoad}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            id="newpageImageUpload"
                                                            className={
                                                                isDragging
                                                                    ? "draging newpageImageUpload"
                                                                    : "newpageImageUpload"
                                                            }
                                                            onClick={() => {
                                                                // document.getElementById("feature-img").click()
                                                                setShowUploadMediaPopup(true)
                                                                setUploadMediaPopupFor('feature-image');
                                                                setUploadMediaPopupTab('upload');
                                                            }}
                                                        >
                                                            {(loadingFeatureImg === true && featuredImageModal === false) ? (
                                                                // {(loadingFeatureImg === true && replaceImgTimeLoading === false) ? (
                                                                <div className="featureImg-loader">
                                                                    <Loader />
                                                                </div>
                                                            ) : (
                                                                <div className="featureImgMiddleContent">
                                                                    <img
                                                                        className="fileUploadCover_Img"
                                                                        src={featured}
                                                                        alt="Featured Image"
                                                                    />
                                                                    <p className="newPageImageUploadText">
                                                                        <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media'); }}>Open Media Library</span>
                                                                        <br />
                                                                        JPG, PNG (max. 128MB)
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        id="feature-img"
                                                        onChange={(e) => {
                                                            handleFileUpload(e);
                                                        }}
                                                        accept="image/*"
                                                        hidden
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="buttonsMobile">

                                            {(published === false && Draft === false) ?
                                                <div className="newpageRightButtons">
                                                    <button className="primary-button button"
                                                        onClick={(e) => {
                                                            validateFields();
                                                            if (dbPageTitle.length > 0 && editorContent.length > 0 && updatedContent.length > 0) {
                                                                if (billingDetails.permission.canPublishNewsletter === 'no') {
                                                                    setIsPublishedOpen(false);
                                                                    setShowUpgradeModel(true);
                                                                    return false;
                                                                }
                                                                setIsLoading(true);
                                                                addData(true, "Send");
                                                                setOpenNotify(true);
                                                                setPublished(true);
                                                                setPublicationList([
                                                                    ...publicationList,
                                                                    { title: "Send" },
                                                                ]);
                                                                billingData();
                                                                clearTimeout(notificationTimeoutId)
                                                                notificationTimeoutId = setTimeout(() => {
                                                                    setOpenNotify(false)
                                                                }, 15000);
                                                            }
                                                        }
                                                        }
                                                    >
                                                        {isLoading ?
                                                            <div className="sendbutton-loader">
                                                                <div className="ring-loader"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                <img src={send} alt="Send" className="publish-arrow"/>
                                                                <p>Send</p>
                                                            </>}

                                                    </button>
                                                    <button
                                                        className="saveAsDraftButton secondary-button button"
                                                        onClick={(e) => {
                                                            draftValidateFields();
                                                            if (dbPageTitle.length > 0) {
                                                                setIsDraftLoading(true);
                                                                addData(true, "Draft");
                                                                // setDraft(true);
                                                            }
                                                        }} >
                                                        {isDraftLoading ?
                                                            <div className="draftButton-loader">
                                                                <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <>
                                                                Save as draft
                                                            </>}
                                                    </button>
                                                    {isPreviewLoading ? 
                                                        <div className="previewButtonLoader">
                                                        <div className="ring-loader cancel"></div>
                                                        </div>
                                                        :
                                                        <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                            onMouseEnter={() => {
                                                                if (isFormInvalid) {
                                                                setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                }
                                                            }}
                                                            onMouseLeave= {() => {
                                                                setPreviewErrorMessage("");
                                                            }}>
                                                            {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Preview
                                                        </button>
                                                    }
                                                </div>
                                                :
                                                ''}

                                            {published === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        <button className="primary-button button">Sent</button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }
                                                    </div>
                                                    {published === true ? (
                                                        <p className="publishStatus">
                                                            {
                                                                status == 'Schedule' ?
                                                                    <>Status: <span>{status}d for {saveScheduleDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} at {saveScheduleHour}:{saveScheduleMinute}</span></>
                                                                    :
                                                                    <>Status: <span>{status}</span></>
                                                            }
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {Draft === true ? (
                                                <div>
                                                    <div className="newpageRightButtons" ref={dotsRef}>
                                                        <button
                                                            className="primary-button button"
                                                            onClick={(e) => {
                                                                draftValidateFields();
                                                                if (dbPageTitle.length > 0) {
                                                                    handleDraft(e)
                                                                }
                                                            }}>
                                                            {isDraftLoading ?
                                                                <div className="updateDraftbutton-loader">
                                                                    <div className="ring-loader"></div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <p>Update Draft</p>
                                                                </>}
                                                        </button>
                                                        <button
                                                            className="newpageMoreButtons secondary-button button"
                                                            onClick={(e) => setIsPublishedOpen(!isPublishedOpen)}
                                                        >
                                                            <img src={dots} alt="Dots" />
                                                        </button>
                                                        {isPreviewLoading ? 
                                                            <div className="previewButtonLoader">
                                                            <div className="ring-loader cancel"></div>
                                                            </div>
                                                            :
                                                            <button className={`${(dbPageTitle.length > 0 && ((editorContent.length > 0) || (updatedContent.length > 0)) && selectedValues.length > 0) ? 'previewTooltipblock secondary-button button' : 'previewTooltipblock preview-disabled secondary-button'}`} onClick={() => { showPreview() }}
                                                                onMouseEnter={() => {
                                                                    if (isFormInvalid) {
                                                                    setPreviewErrorMessage("Please fill in all the required fields to preview");
                                                                    }
                                                                }}
                                                                onMouseLeave= {() => {
                                                                    setPreviewErrorMessage("");
                                                                }}>
                                                                {previewErrorMessage !== "" && <div className='text-tooltip'>{previewErrorMessage}</div>}
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.01677 10.5942C1.90328 10.4145 1.84654 10.3246 1.81477 10.186C1.79091 10.0819 1.79091 9.91775 1.81477 9.81366C1.84654 9.67507 1.90328 9.58522 2.01677 9.40552C2.95461 7.92054 5.74617 4.1665 10.0003 4.1665C14.2545 4.1665 17.0461 7.92054 17.9839 9.40552C18.0974 9.58522 18.1541 9.67507 18.1859 9.81366C18.2098 9.91775 18.2098 10.0819 18.1859 10.186C18.1541 10.3246 18.0974 10.4145 17.9839 10.5942C17.0461 12.0791 14.2545 15.8332 10.0003 15.8332C5.74617 15.8332 2.95461 12.0791 2.01677 10.5942Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    <path d="M10.0003 12.4998C11.381 12.4998 12.5003 11.3805 12.5003 9.99984C12.5003 8.61913 11.381 7.49984 10.0003 7.49984C8.61962 7.49984 7.50034 8.61913 7.50034 9.99984C7.50034 11.3805 8.61962 12.4998 10.0003 12.4998Z" stroke="#344054" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                                                </svg>
                                                                Preview
                                                            </button>
                                                        }
                                                        {isPublishedOpen ? (
                                                            <div className="publishedButtonsList">
                                                                <ul>
                                                                    <li className="publish-li" onClick={(e) => {
                                                                        validateFields();
                                                                        if (dbPageTitle.length > 0 && editorContent.length > 0 && updatedContent.length > 0) {
                                                                            {
                                                                                if (billingDetails.permission.canPublishNewsletter === 'no') {
                                                                                    setIsPublishedOpen(false);
                                                                                    setShowUpgradeModel(true);
                                                                                    return false;
                                                                                }
                                                                                handleChangeToSent(e, () => setIsLoading(true))
                                                                            }
                                                                        }
                                                                    }}>
                                                                        Send Now</li>
                                                                    <li className="publish-li" onClick={handelSingleDelete}>Delete</li>
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    {Draft === true ? (
                                                        <p className="publishStatus">
                                                            Status: <span className="draftStatus">Draft</span>
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) :
                                                ''}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* {
                    <Notification
                    title={"Successfully Draft page"}
                    text={
                        "Your changes have been saved and your profile is live. Your team can make edits."
                    }
                    setOpenNotify={setOpenNotifyDraft}
                    type={"success"}
                    openNotify={openNotifyDraft}
                    />
                } */}

                        {/* {
                    <Notification
                    title={"Successfully send page"}
                    text={
                        "Your changes have been saved and your profile is live. Your team can make edits."
                    }
                    setOpenNotify={setOpenNotifySend}
                    type={"success"}
                    openNotify={openNotifySend}
                    />
                } */}

                        {/* {openPublishPopup === true && status == 'Send' ? (
                    <PublishedNotify
                    openPublishPopup={openPublishPopup}
                    setOpenPublishPopup={setOpenPublishPopup}
                    />
                ) : (
                    ""
                )} */}

                        {(isPopupOpenDeleteAccount || showCategoryField) && <div className="popupModalOverlay"></div>}
                        <DeleteNewsletterModal isOpen={isPopupOpenDeleteAccount} setOpen={setIsPopupOpenDeleteAccount} onClose={closeAccountDeletedPopup} type="newsLetter" name="newsletters" item={data} redirect={`users/${id}/newsletters`} refrence={popupRef} fetchData={fetchData} />
                        <AddCategoryPage isOpen={showCategoryField} onClose={closeCategoryField} data={values} setCategory={setValues} setSelectedCategory={setSelectedValues} isCategoryPage="no" userId={id} setOpenNotify={setCategoriesNotify}/>

                        {showUploadMediaPopup &&
                            <UploadMediaPopup showUploadMediaPopup={showUploadMediaPopup} setShowUploadMediaPopup={setShowUploadMediaPopup} setSelectedProfileImage={setSelectedFeatureImage} selectMediaLoading={selectMediaLoading} uploadMediaPopupFor={uploadMediaPopupFor} uploadMediaPopupTab={uploadMediaPopupTab} />
                        }
                        {
                            featuredImageModal && (
                                <>
                                    <div className="imagCropModal">
                                        <div
                                            className="featuredImageModal featurboxModal"
                                            ref={modalRef}
                                        >
                                            <div className="modal-content">
                                                {uploadedImage === null ? (
                                                    <div
                                                        className="newPageRightRow"
                                                        onDragOver={handleDragOver}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}
                                                    >
                                                        {(saveFeatureImage && (saveFeatureImage === oldImage) || (saveFeatureImage !== featureImage)) && (saveFeatureImage ? (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div>
                                                                            <div className="crop-container">
                                                                                <ImageEditor
                                                                                    type="featureImg"
                                                                                    image={featureImage}
                                                                                    oldImage={oldImage}
                                                                                    setOldImage={setOldImage}
                                                                                    setFeatureImage={setFeatureImage}
                                                                                    setUploadedImage={setUploadedImage}
                                                                                    replaceImage={handleFileUpload}
                                                                                    zoom={zoom}
                                                                                    position={position}
                                                                                    setZoom={handleZoom}
                                                                                    setPosition={handlePosition}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                                onClick={() => {
                                                                    // document.getElementById("feature-img").click()
                                                                    setShowUploadMediaPopup(true)
                                                                    setUploadMediaPopupFor('feature-image');
                                                                    setUploadMediaPopupTab('upload');
                                                                }}
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="featureImgMiddleContent">
                                                                            <img
                                                                                className="fileUploadCover_Img"
                                                                                src={featured}
                                                                                alt="Featuredimage"
                                                                            />
                                                                            <p className="newPageImageUploadText">
                                                                                <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media'); }}>Open Media Library</span>
                                                                                <br />
                                                                                JPG, PNG (max. 128MB)
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))}

                                                        {(featureImage && (saveFeatureImage === featureImage)) && (featureImage ? (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div>
                                                                            <div className="crop-container">
                                                                                <ImageEditor
                                                                                    type="featureImg"
                                                                                    image={featureImage}
                                                                                    oldImage={oldImage}
                                                                                    setOldImage={setOldImage}
                                                                                    setFeatureImage={setFeatureImage}
                                                                                    setUploadedImage={setUploadedImage}
                                                                                    replaceImage={handleFileUpload}
                                                                                    zoom={zoom}
                                                                                    position={position}
                                                                                    setZoom={handleZoom}
                                                                                    setPosition={handlePosition}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                id="newpageImageUpload"
                                                                className={
                                                                    isDragging
                                                                        ? "draging newpageImageUpload"
                                                                        : "newpageImageUpload"
                                                                }
                                                                onClick={() => {
                                                                    // document.getElementById("feature-img").click()
                                                                    setShowUploadMediaPopup(true)
                                                                    setUploadMediaPopupFor('feature-image');
                                                                    setUploadMediaPopupTab('upload');
                                                                }}
                                                            >
                                                                {loadingFeatureImg === true ? (
                                                                    <div className="featureImg-loader">
                                                                        <Loader />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="featureImgMiddleContent">
                                                                            <img
                                                                                className="fileUploadCover_Img"
                                                                                src={featured}
                                                                                alt="Featuredimage"
                                                                            />
                                                                            <p className="newPageImageUploadText">
                                                                                <span>Upload</span> or <span onClick={(e) => { e.stopPropagation(); setShowUploadMediaPopup(true); setUploadMediaPopupFor('feature-image'); setUploadMediaPopupTab('media'); }}>Open Media Library</span>
                                                                                <br />
                                                                                JPG, PNG (max. 128MB)
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ))}
                                                        <input
                                                            type="file"
                                                            id="feature-img"
                                                            ref={fileInputRef}
                                                            onChange={handleFileUpload}
                                                            accept="image/*"
                                                            hidden
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="crop-container">
                                                            {featureImage && oldImage && (
                                                                <ImageEditor
                                                                    type="featureImg"
                                                                    image={featureImage}
                                                                    oldImage={oldImage}
                                                                    setFeatureImage={setFeatureImage}
                                                                    setUploadedImage={setUploadedImage}
                                                                    replaceImage={handleFileUpload}
                                                                    zoom={zoom}
                                                                    position={position}
                                                                    setZoom={handleZoom}
                                                                    setPosition={handlePosition}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="replaceImageRange">
                                                    <div className="replaceImgBtn">
                                                        <button
                                                            className="uploadImage secondary-button button"
                                                            type="button"
                                                            onClick={() => handleReplaceImg()}
                                                        >
                                                            <span>Replace Image</span>
                                                        </button>
                                                    </div>
                                                    <div className="imgRange">
                                                        <img className="coverImgLeft" src={coverImg} alt="coverimage" />
                                                        <input
                                                            id="zoom-range"
                                                            className="zoom-range"
                                                            type="range"
                                                            onMouseDown={handleMouseDown}
                                                            onMouseUp={handleMouseUp}
                                                            onTouchStart={handleTouchStart}
                                                            onTouchEnd={handleTouchEnd}
                                                            value={zoom}
                                                            min={1}
                                                            max={3}
                                                            step={0.1}
                                                            aspect={384 / 200}
                                                            aria-labelledby="Zoom"
                                                            style={{
                                                                background: `linear-gradient(to right, #344054 ${((zoom - 1) / 2) * 100
                                                                    }%, #B7BCC2 0%)`,
                                                            }}
                                                            onChange={(e) => {
                                                                handleZoom(e.target.value);
                                                            }
                                                            }
                                                        />
                                                        <img className="coverImgRight" src={coverImg} alt="coverimage" />
                                                    </div>
                                                </div>
                                                <div className="featuredImageModalbtn">
                                                    <button
                                                        className="cancel secondary-button button"
                                                        type="button"
                                                        onClick={() => handleCancel()}
                                                    // onClick={() => {
                                                    //   var divElement = document.getElementsByClassName("newpageImageUpload");
                                                    //   divElement.classList.remove("imageUploaded");
                                                    //   setFeaturedImageModal(false);
                                                    //   setZoom(oldZoom);
                                                    // }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="savePicture primary-button button"
                                                        type="button"
                                                        onClick={() => handleSavePicture()}
                                                    >
                                                        Save Picture
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {featuredImageModal && <div className="overlayModal"> </div>}
                                </>
                            )
                        }
                        { openPagePopup && (
                            <>
                                {/* <div className="popup-overlay"></div> */}
                                <PreviewSuccessPopUp setShowPreviewPopup={setOpenPagePopup} showPreviewPopup={openPagePopup} previewpage={"Newsletter"} tempData={popupData} userMetaData={userData}  title={"Your newsletter has been sent!"} description={"Your newsletter has been sent to your subscribers. You can check its analytics and open rate on the analytics page. Remember, keeping your subscribers engaged is key to building a strong connection."}/>
                            </>
                        )}
                        {showUpgradeModel &&
                            <>
                                <div className="popupModalOverlay"></div>
                                <div className='popupModal' ref={modelRef}>
                                    <h3 className='popupModalTitle'>User need to upgrade plan if need to publish more than 5 newsletter. </h3>
                                    <div className="popupModalBtn upgradPlanPopupBtn">
                                        <button className="cancel" type="button" onClick={() => { setShowUpgradeModel(false); }} >Okay</button>
                                    </div>
                                </div>
                            </>
                        }
                        { openNotify && 
                            <Notification title={`Your newsletter has been successfully sent!`} setOpenNotify={setOpenNotify} type={"success"} openNotify={openNotify} />
                        }
                        { openNotifyDraft && 
                            <Notification title={`Your newsletter has been saved as a draft!`} setOpenNotify={setOpenNotifyDraft} type={"success"} openNotify={openNotifyDraft} />
                        }
                        { scheduleNotify && 
                            <Notification title={`Your newsletter is scheduled to be sent at ${scheduleHour}:${scheduleMinute}.`} setOpenNotify={setScheduleNotify} type={"success"} openNotify={scheduleNotify} />
                        }
                        { categoriesNotify && 
                            <Notification title={`Category has been successfully added!`} setOpenNotify={setCategoriesNotify} type={"success"} openNotify={categoriesNotify} />
                        }
                    </section >
                    :
                    <Error />
            }
        </>
    );
}

export default NewNewsletter;
