import React, { useState } from "react";
import { Link } from "react-router-dom";


const PageTabs = ({ tabs, activePage, setActivePage, isContentChange, showConfirmationPopup }) => {

    return (
        <div className="profile-tabs">
            {tabs.map((tab, index) => (
                <Link
                    to={tab.href}
                    key={index}
                    className={`profile-tab-item ${activePage === tab.name ? "active" : ""
                        }`}
                    onClick={(e) => {
                        if( isContentChange === true ){
                            e.preventDefault();
                            showConfirmationPopup(tab.href);
                        } else {
                            setActivePage(tab.name); 
                        }
                    } }
                >
                    {tab.name}
                </Link>
            ))}
        </div>
    );
};

const PageNavigation = ({ tabs, setActivePage, isContentChange, showConfirmationPopup }) => {
    const [navMenuOpen, setNavMenuOpen] = useState(false);

    return (
        <div className="profile-navigation">
            <div className="profile-select">
                {(navMenuOpen === true) ?
                    <div className="profile-tab-select">
                        <div className="profile-tab-menu">
                            {tabs.map((tab, index) => (
                                <Link
                                    to={tab.href}
                                    key={index}
                                    className="profile-tab-link"
                                    onClick={(e) => { 
                                        if( isContentChange === true ){
                                            e.preventDefault();
                                            showConfirmationPopup(tab.href);
                                        } else {
                                            setActivePage(tab.name); 
                                            setNavMenuOpen(false); 
                                        }
                                    }}
                                >
                                    {tab.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                    : ''}
            </div>
        </div>
    );
};

export { PageTabs, PageNavigation };
